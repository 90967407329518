// Import Libraries
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import config from './config';

// Import CSS and the App
import App from './App';
import './index.css';
import './styles/App.css';
import './styles/fonts.css';
import './styles/tables.css';

// https://sentry.io/onboarding/cbb-analytics/get-started/
Sentry.init({
    dsn: config.dsn,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
        }),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false
        })
    ],

    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions

    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    attachStacktrace: true
});


// Import Store for Redux, (remove registerServicerworker I think...)
// import * as serviceWorker from './serviceWorker';

// wrap with react-query QueryClientProvider
const queryClient = new QueryClient();

const root = createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools />
        </QueryClientProvider>
    </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
