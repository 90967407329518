
// Import React Components
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Import Other Components
import HelmetComponent from '../../utils/HelmetUtils';
import imageUrls from '../../images/gcsImages';
import useScreenSize from '../../hooks/useScreenSize';


// Create The Component
function AboutUs() {
    const { isXs, isMd, isLg } = useScreenSize();

    // // first version from a few years ago
    // let unusedText1 = `CBB Analytics is a basketball and statistics company started by a former data scientist from the Golden State Warriors’ Basketball Research and Strategy team. With insights on how coaches from one the NBA’s most successful organizations use data to drive and improve decision making, we’ve built a platform to deliver these same insights to the college game.`
    // let unusedText2 = `CBB is not another video platform, and we do not feature game footage or videos on our website. Instead, our focus is on the numbers, and on analyzing the growing scale of boxscores and play-by-play data that has become available in recent years. We’re crunching this data at scale across the entire NCAA, including for Division's I, II and III, and for both men and women’s basketball.`
    // let unusedText3 = `In the NBA, teams are building comprehensive data teams of statisticians, data experts and software developers, with the hope that analyzing big data will lead to future successes. In the NCAA, this approach is not feasible for more than a handful of the top revenue-generating schools. With CBB Analytics, NCAA teams can have access to their own NBA-caliber data team`
    // let unusedText4 = `Feel free to get in touch with us at nick@cbbanalytics.com.`;

    // Latest Text
    let pText1 = `It was a pivotal time for a franchise that had become a dynasty over the last 5 years. With Steph Curry and Klay Thompson sidelined with injuries, and Kevin Durant leaving for the Nets, the Golden State Warriors faced a challenge no one predicted. But could data help them navigate through a new roster, during a season focused more on player development than on winning another championship?`;
    let pText2 = `I was hired by the Warriors as a Data Scientist to join a group that supported the coaching staff and front office. Our data team put together daily statistical scouting reports, and armed the team with the numbers they could use to make data-driven decisions. As a result, the team was better prepared to capitalize on trades and in the draft, they put their young players in better positions to succeed and develop, and the team is currently positioned for another several years of title runs.`;
    let pText3 = `The Warriors are just one challenge I faced as pro basketball teams turned more to analytics to help their organizations compete at the highest level. While NBA teams have embraced data, I discovered a huge gap in how men's and women's college basketball teams use that data. So, armed with my experience with the Warriors, and working for earlier sports tech startups, we got to work building / designing / creating tools for the college game.`;
    let pText4 = `What sets CBB Analytics apart is our combination of high level statistical analysis with a cleaner and easier-to-use visual presentation. We've created the leading software platform for college basketball statistics, research and data analysis: `;
    let pText5 = `Teams are turning to CBB Analytics to elevate their game, from scouting and game-planning, to decision-making and more. Everyday we learn and our commitment to you is that we will continue to invent new ways to arm you and your team with information that could be the difference between going to “The Dance” or watching it on TV.`;

    let bText1 = `Statistics that are more accurate, and are hard to find elsewhere`;
    let bText2 = `Charts and graphs that add an additional layer to your analysis`;
    let bText3 = `Numbers that will improve your game planning`;

    // and return
    return (
        <React.Fragment>
            <HelmetComponent page='aboutUs' />
            <Container style={{ maxWidth: '100%', marginBottom: 60 }}>

                {/* Header Section */}
                <Row className='about-us-hero-row'>
                    {!isMd &&
                        <div style={{ position: 'absolute', bottom: isLg ? '-150px' : '-200px', overflow: 'hidden', opacity: 0.15 }}>
                            <img alt='' style={{ width: '100%' }} src={imageUrls.hexBackground} />
                        </div>
                    }
                    <Col lg={6} xl={7} className='abous-us-hero-lhs' style={{ position: 'relative' }}>
                        {isMd &&
                            <div style={{ position: 'absolute', bottom: isXs ? '-5px' : '-100px', overflow: 'hidden', opacity: 0.15 }}>
                                <img alt='' style={{ width: '100%' }} src={imageUrls.hexBackground} />
                            </div>
                        }
                        <div style={{ width: 'calc(100% - 10vw)', margin: '5% 4vw 20px 6vw', lineHeight: 1.6 }}>
                            <h3>The Birth of CBB</h3>
                            <p style={{ marginTop: '3%', fontSize: 'calc(19px + 0.2vw)' }}>{pText1}</p>
                        </div>
                    </Col>
                    <Col lg={6} xl={5} className='abous-us-hero-rhs'>
                        <div style={{ width: '100%' }}>
                            <img alt='' style={{ width: '100%' }} src={imageUrls.stephKlay} />
                        </div>
                    </Col>
                </Row>

                <Row style={{ maxWidth: 1200, margin: '80px auto 0px' }}>
                    <Col xl={5} lg={6} md={6} sm={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 40 }}>
                        <img alt='cbb image' style={{ width: '100%', maxWidth: 325, borderRadius: 10, marginTop: 40 }} src={imageUrls.nickHeadshot} />
                        <Row style={{ marginTop: 20, alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ width: `calc(20% + 20px)`, marginRight: 15 }}>
                                <img alt='cbb image' style={{ width: '100%' }} src={imageUrls.nykLogo} />
                            </div>
                            <div style={{ width: `calc(15% + 20px)`, marginLeft: 15 }}>
                                <img alt='cbb image' style={{ width: '100%' }} src={imageUrls.gswLogo} />
                            </div>
                        </Row>
                    </Col>
                    <Col xl={7} lg={6} md={6} sm={12} style={{ maxWidth: 650, marginBottom: 40 }}>
                        <h3 style={{ fontWeight: 700, color: '#0066CC' }}>My Background</h3>
                        <p style={{ fontSize: 21, marginTop: 10 }}>{pText2}</p>
                        <p style={{ fontSize: 21, marginTop: 20 }}>{pText3}</p>
                    </Col>
                </Row>

                <Row style={{ borderBottom: '1px solid #707070', margin: '60px auto', maxWidth: 1250 }} />
                <Row style={{ maxWidth: 1200, margin: '80px auto 0px' }}>
                    <Col xl={5} lg={6} md={6} sm={12} style={{ marginBottom: 40 }}>
                        <img alt='cbb image' style={{ width: '90%', maxWidth: 500 }} src={imageUrls.computers1} />
                    </Col>
                    <Col xl={7} lg={6} md={6} sm={12} style={{ marginBottom: 40 }}>
                        <h3 style={{ fontWeight: 700, color: '#0066CC' }}>The CBB Edge</h3>
                        <p style={{ fontSize: 21, marginTop: 10 }}>{pText4}</p>
                        <ul style={{ fontSize: 20, marginTop: 20 }}>
                            <li>{bText1}</li>
                            <li>{bText2}</li>
                            <li>{bText3}</li>
                        </ul>
                        <p style={{ fontSize: 21, marginTop: 20 }}>{pText5}</p>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default AboutUs;
