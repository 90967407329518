
// Import React Stuff
import React, { useState, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTable, useSortBy, useFilters, usePagination, useBlockLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import GlobalContext from '../../../context/GlobalContext';

// Import CBB Stuff
import ColumnMultiSelect from '../../../components/selects/ColumnMultiSelect';
import InfoModal from '../../uiUxItems/InfoModal';
import TableColorsLegend from '../../uiUxItems/TableColorsLegend';
import DownloadDataButton from '../../uiUxItems/DownloadDataButton';
import BrandedFooter from './BrandedFooter';
import Pagination from '../utils/Pagination';
import TableHideButtons from '../../uiUxItems/TableHideButtons';
import { NoFilter } from '../utils/TableFilters';
import { createScrollRow, createScrollHeader, createExpandingRow, createExpandingHeader } from '../utils/utils';
import { getColumnAccessors } from '../../../utils/TableHelpers';


// Our Table Component
const CBBTable = ({
    scrollOrExpand = 'scroll', //   one of 'scroll', 'expand', this determines if <div /> or <table /> table
    className = '',
    wrapperStyle,
    columns,
    data,
    features = [],
    defaultSortObj = {},
    isSticky = true, //             for scroll table only
    nthRow = 1, //                  alternate nth-row coloring
    tableMaxWidth = '100%', //      for expanding table only
    initialPageSize = 10, //        for default rows in table
    infoModal = null, //            top-left cortner info modal
    ratingsType = '', //            i think ['pctiles', 'ranks'], not sure on plural suffix
    hideFilters = false, //         if true, override and hide all min/max filters
    printRowHeight = 20 //          height of row <td> for print
}) => {
    // Get User Data (check if paid user for download button)
    const { userTier } = useContext(GlobalContext);
    const [hideStuff, setHideStuff] = useState(false);

    // Table Features to Show: ['ratingsBar', 'pagination', 'columnSelect', 'dlButton', 'infoModal', 'hiderButton']
    const showDloadButton = features.includes('dlButton') && userTier.value >= 3; // D-I Teams Tier (inc Trial) only
    const showPagination = features.includes('pagination') && (initialPageSize < data.length);
    const showColumnSelect = features.includes('columnSelect');
    const showRatingsLegend = features.includes('ratingsBar');
    const showHideButton = features.includes('hiderButton');
    const isScroll = scrollOrExpand === 'scroll';

    // Set up our default Filter UI
    const defaultColumn = React.useMemo(() => ({ Filter: NoFilter }), []);

    // 3. Call useTable() with correct options
    const useTableOptions = {
        columns, data, defaultColumn, // filterTypes,
        initialState: {
            pageSize: initialPageSize,
            sortBy: defaultSortObj ? [defaultSortObj] : [],
            hiddenColumns: []
        }
    };
    const reactTable = useTable(
        useTableOptions,
        useFilters, // hook for filtering
        useSortBy, // hook for sort by
        usePagination, // hook for pagination..
        ...(isScroll ? [useBlockLayout] : []), // hook for table w/ divs for fixed width columns
        ...(isScroll ? [useSticky] : []) // hook for sticky columns
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        allColumns,
        visibleColumns,
        setHiddenColumns
    } = reactTable;


    // 5. Return the HTML Table
    // ==========================
    const location = useLocation(); // to help with row tooltips
    const params = useParams();
    const d0TeamId = data && data[0] && data[0].teamId ? data[0].teamId : null; // gotta dig deep into the data
    const d1TeamId = data && data[1] && data[1].teamId ? data[1].teamId : null; // to grab the teamIds here...
    const teamId = params && params.teamId ? params.teamId : (d0TeamId ? d0TeamId : (d1TeamId ? d1TeamId : null));
    // const teamColor = getHexColor1(teamInfosObj, teamId);
    const divisionId = data && data[0] ? data[0].divisionId : null; // for Division-${divisionId} in info-modal
    const correctRows = showPagination ? page : rows;
    const showInfoModal = infoModal !== 0 && infoModal !== null;
    const columnAccessors = getColumnAccessors({ columns });


    // Function to create button group wrapped either in a <div /> or a <caption />
    const createButtonGroup = (WrapperElement) => (
        <WrapperElement className='in-table-button' style={{ display: 'flex', opacity: hideStuff ? 0 : 1 }}>
            {showRatingsLegend &&
                <TableColorsLegend ratingsType={ratingsType} />
            }
            {showHideButton && // Button to hide buttons now on all tables!
                <TableHideButtons
                    teamId={teamId}
                    hideStuff={hideStuff}
                    setHideStuff={setHideStuff}
                />
            }
            {showInfoModal &&
                <InfoModal
                    type={infoModal}
                    teamId={teamId}
                    divisionId={divisionId}
                    style={{ marginBottom: 0 }}
                />
            }
            {showDloadButton &&
                <DownloadDataButton
                    data={data}
                    columnAccessors={columnAccessors}
                    teamId={teamId}
                    style={{ marginTop: -5, marginLeft: 3 }}
                />
            }
            {showColumnSelect &&
                <div style={{ marginTop: -1, display: hideStuff ? 'none' : null }}>
                    <ColumnMultiSelect
                        setHiddenColumns={setHiddenColumns}
                        optionsArray={allColumns}
                    />
                </div>
            }
        </WrapperElement>
    );
    const showButtonGroup = (showInfoModal || showDloadButton || showColumnSelect || showRatingsLegend);
    const wrapperElement = isScroll ? 'div' : 'caption';
    const buttonGroup = createButtonGroup(wrapperElement);


    // Save renders for <Scroll> and for <Expanding>
    const scrollTable =
        (<div className={`scroll-table-wrapper ${className}`} style={{ ...wrapperStyle }}>
            <div {...getTableProps()} className={`cbb-table ${isSticky ? 'sticky' : ''}`}>

                {/* Table Headers */}
                {createScrollHeader(headerGroups, allColumns, visibleColumns, hideFilters || hideStuff)}

                {/* Table Body */}
                <div className='table-body' {...getTableBodyProps()}>
                    {correctRows.map(row => {
                        prepareRow(row);
                        return createScrollRow({ row, nthRow, printRowHeight });
                    })}
                </div>

                {/* in-Table Info Icon + Download Button */}
                {showButtonGroup && buttonGroup}
            </div>

            {/* Show Pagination */}
            {showPagination && !hideStuff &&
                <Pagination
                    reactTable={reactTable}
                />
            }

            {/* Show BrandedFooter when button group is hidden */}
            {hideStuff && <BrandedFooter />}
        </div >);

    const expandingTable =
        (<div className={`expand-table-wrapper ${className}`} style={{ maxWidth: tableMaxWidth, ...wrapperStyle }}>
            <table {...getTableProps()} className={`cbb-table`}>

                {/* Table Headers */}
                {createExpandingHeader(headerGroups, allColumns, visibleColumns, hideFilters || hideStuff)}

                {/* Table Body */}
                <tbody className='table-body' {...getTableBodyProps()}>
                    {correctRows.map(row => {
                        prepareRow(row);
                        return createExpandingRow({ row, nthRow, printRowHeight });
                    })}
                </tbody>

                {/* in-Table Info Icon + Download Button */}
                {showButtonGroup && buttonGroup}

            </table>

            {/* Show Pagination */}
            {showPagination &&
                <Pagination
                    reactTable={reactTable}
                />
            }

            {/* Show BrandedFooter when button group is hidden */}
            {hideStuff && <BrandedFooter />}
        </div>);


    // And Return
    const renderTable = isScroll ? scrollTable : expandingTable;
    return (renderTable);
};

export default CBBTable;
