
// https://redketchup.io/image-resizer for image sizing

// Import React Components
import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import GlobalContext from '../../context/GlobalContext';

// CBB Components
import ControllerNavbar from '../../components/navbars/ControllerNavbar';
import imageUrls from '../../images/gcsImages';
import LandingPageCard from '../../components/uiUxItems/LandingPageCard';
import TextHeaders from '../../components/headers/TextHeaders';


function ToolsLanding() {
    // gotta grab for correct scoreboard path
    const { userData, userTier, defaultCompetition } = useContext(GlobalContext);
    let isLoggedIn = userData.user ? true : false;
    let competitionId = defaultCompetition.value;
    let divisionId = isLoggedIn ? userData.user.team.divisionId : 1;
    let latestDate = (new Date()).toISOString().slice(0, 10).replace(/-/g, '');

    // headers
    let freeTierHeader =
        (<Row style={{ margin: '50px 0 35px 0', justifyContent: 'center' }}>
            <TextHeaders headerStyles={{ fontSize: 42, textDecorationColor: 'darkred' }}>
                For Free Tier Users
            </TextHeaders></Row>);
    let userTierHeader =
        (<Row style={{ margin: '50px 0 35px 0', justifyContent: 'center' }}>
            <TextHeaders headerStyles={{ fontSize: 42, textDecorationColor: 'darkred' }}>
                For User Tier Subscribers
            </TextHeaders></Row>);
    let proTierHeader =
        (<Row style={{ margin: '50px 0 35px 0', justifyContent: 'center' }}>
            <TextHeaders headerStyles={{ fontSize: 42, textDecorationColor: 'darkred' }}>
                For Pro Tier Subscribers
            </TextHeaders></Row>);

    // and return
    return (
        <Container className='shadow-container'>
            <ControllerNavbar
                label='CBB Analytics Toolbox: '
                subLabel='Customizable Tools for Deep Dives and Actionable Analytics'
                view=''
                ptgc='tools'
                pad={false}
                showButtons={false}
            />
            <Container className='shadow-container' style={{ maxWidth: 1250 }}>

                {/* No & Free Tier Tools Pages */}
                {userTier.value === 0 && freeTierHeader}
                <Row style={{ padding: 0, margin: '60px -15px 0px -15px' }}>
                    <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }} style={{ marginBottom: 50, padding: '0px 30px' }}>
                        <LandingPageCard
                            header='Shot Charts App'
                            subHeader='Visualize and analyze shooting performance with customizable shot charts for teams and individual players.'
                            src={imageUrls.toolShotChartsHero}
                            path='/tools/shot-charts'
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }} style={{ marginBottom: 50, padding: '0px 30px' }}>
                        <LandingPageCard
                            header='NCAA Scoreboard'
                            subHeader={`Stay updated with real-time scores across the league using our interactive daily scoreboard, and gain insights from a chosen day's performance.`}
                            src={imageUrls.toolScoreboardHero}
                            path={`/tools/scoreboard/${competitionId}/d${divisionId}/${latestDate}`}
                        />
                    </Col>
                </Row>
                <Row style={{ padding: 0, margin: '0px -15px' }}>
                    <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }} style={{ marginBottom: 50, padding: '0px 30px' }}>
                        <LandingPageCard
                            header='Team Scatter App'
                            subHeader='Craft detailed scatter plots that depict team performance across various metrics, enabling comprehensive analysis of league-wide trends.'
                            src={imageUrls.toolTeamScatterHero}
                            path='/tools/team-scatter'
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }} style={{ marginBottom: 50, padding: '0px 30px' }}>
                        <LandingPageCard
                            header='Player Scatter App'
                            subHeader={`Assess and compare individual player stats with customizable scatter plots, shedding light on players' various strengths and weaknesses.`}
                            src={imageUrls.toolPlayerScatterHero}
                            path='/tools/player-scatter'
                        />
                    </Col>
                </Row>

                {/* User Tier Tools Pages */}
                {userTier.value <= 1 && userTierHeader}
                <Row style={{ padding: 0, margin: '0px -15px' }}>
                    <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }} style={{ marginBottom: 50, padding: '0px 30px' }}>
                        <LandingPageCard
                            header='Bar Charts App'
                            subHeader='Compare player and team statistics visually, using versatile and customizable bar charts tailored to your preferred metrics.'
                            src={imageUrls.toolBarChartsHero}
                            path='/tools/bar-charts'
                            disabled={userTier.value <= 1}
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }} style={{ marginBottom: 50, padding: '0px 30px' }}>
                        <LandingPageCard
                            header='Comparisons App'
                            subHeader='Conduct side-by-side comparisons of players and teams, facilitating comprehensive performance assessments of up to 4 players or teams simultaneously.'
                            src={imageUrls.toolComparisonsHero}
                            path='/tools/comparisons'
                            disabled={userTier.value <= 1}
                        />
                    </Col>
                </Row>
                <Row style={{ padding: 0, margin: '0px -15px' }}>
                    <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }} style={{ marginBottom: 50, padding: '0px 30px' }}>
                        <LandingPageCard
                            header='Game Deciding Shots'
                            subHeader='See which teams and players shoot the best on + have made the most game tying and go ahead shots.'
                            src={imageUrls.toolTyingGoAheadHero}
                            config={{ imgStyle: { padding: 0 } }}
                            path={`/tools/game-deciding-shots/${competitionId}/d${divisionId}`}
                            disabled={userTier.value <= 1}
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }} style={{ marginBottom: 50, padding: '0px 30px' }}>
                        <LandingPageCard
                            header='Streaks and Streak Statistics'
                            subHeader='Who has the longest uninterrupted scoring streaks? + stats on streaks'
                            src={imageUrls.toolStreaksHero}
                            config={{ imgStyle: { padding: 0 } }}
                            path={`/tools/scoring-streaks/${competitionId}/d${divisionId}`}
                            disabled={userTier.value <= 1}
                        />
                    </Col>
                </Row>

                {/* Pro Tier Tools Pages */}
                {userTier.value <= 2 && proTierHeader}
                <Row style={{ padding: 0, margin: '0px -15px' }}>
                    <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }} style={{ marginBottom: 50, padding: '0px 30px' }}>
                        <LandingPageCard
                            header='DIY Shot Charts App'
                            subHeader='Utilize practice data to create shot charts from scratch for your team, helping to monitor player progress and potentially impact game strategies.'
                            src={imageUrls.toolDiyShotChartsHero}
                            path='/tools/diy-shot-charts'
                            disabled={userTier.value <= 2}
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }} style={{ marginBottom: 50, padding: '0px 30px' }}>
                        <LandingPageCard
                            header='Regression Analysis App'
                            subHeader='Perform complex regression analyses on game and season stats to identify factors most influencing offensive and defensive ratings.'
                            src={imageUrls.toolRegressionHero}
                            path='/tools/model-fitting'
                            disabled={userTier.value <= 2}
                        />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}


export default ToolsLanding;
