
// the biggest challenge right now is keeping the subscription's in sync between parent and child component
//      parent component fetches subscription, saves in state, utilizes across MyAccount pages
//      auto-renew toggle here uses subscription, updates subscriptipn, need to pass updates back up to parent


import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import Switch from 'react-switch';
import Axios from 'axios';
import config from '../../../config';

const AutoRenewSubscriptionButton = ({
    subscription,
    setParentSubscription, // set subscription in parent's state
    isDisabled,
    style
}) => {
    const [isUpdating, setIsUpdating] = useState(false);
    const [isTrial, setIsTrial] = useState(false);
    const [isAutoRenew, setIsAutoRenew] = useState(false);
    const [endDate, setEndDate] = useState(null);

    // when component loads, use "subscription" prop to update "isTrial", "isAutoRenew", "endDate"
    const updateWithSubscription = async (newSubscription) => {
        if (newSubscription) {
            // set isTrial, isAutoRenew
            setIsTrial(newSubscription.status === 'trialing');
            setIsAutoRenew(newSubscription.cancel_at ? false : true);

            // set endDate
            const end = new Date(1970, 0, 1);
            const isSetToCancel = newSubscription.cancel_at;
            end.setSeconds(isSetToCancel ? newSubscription.cancel_at : newSubscription.current_period_end);
            setEndDate(end.toLocaleDateString());

            // Replacing this with code above, it seems wrong, t not used
            // if (newSubscription.cancel_at) {
            //     const t = new Date(1970, 0, 1);
            //     t.setSeconds(newSubscription.cancel_at);
            // }

            // const t2 = new Date(1970, 0, 1);
            // t2.setSeconds(newSubscription.current_period_end);
            // setEndDate(t2.toLocaleDateString());
        } else {
            console.log('WOAH DOG');
        }
    };

    useEffect(() => {
        updateWithSubscription(subscription);
    }, [subscription]);


    const handleStripeAutoPayToggle = async () => {
        try {
            // Disable toggle switch while subscription updates
            setIsUpdating(true);

            // Update subscription
            const authHeader = { headers: { 'x-auth-token': localStorage.getItem('auth-token') } };
            const response = await Axios.post(`${config.url.API_URL}/stripe/toggle-auto-pay`, { autoRenew: !isAutoRenew }, authHeader);
            // console.log('/toggle-auto-pay response & new subscription: ', { response, subscr: response.data.subscription });
            updateWithSubscription(response.data.subscription);
            setParentSubscription(response.data.subscription);
            // Undisable Button
            setIsUpdating(false);
        } catch (err) {
            console.log('err: ', err);
            setIsUpdating(false);
        }
    };

    return (
        <Row style={{ margin: 0, justifyContent: 'space-between', alignItems: 'center', ...style }}>
            <div style={{ lineHeight: 1.3 }}>
                <p style={{ fontSize: '0.9em', fontWeight: 700 }}>Auto-renew</p>
                <p style={{ fontSize: '0.8em' }}>
                    {subscription && isAutoRenew && isTrial && `Will auto renew on ${endDate} after trial`}
                    {subscription && !isAutoRenew && isTrial && `Will not renew on ${endDate} after trial`}
                    {subscription && isAutoRenew && !isTrial && `Will auto renew on ${endDate}`}
                    {subscription && !isAutoRenew && !isTrial && `Will not renew on ${endDate}`}
                </p>
            </div>

            {/* Only Show Toggle Switch if subscription is found */}
            {subscription &&
                <Switch
                    onChange={() => handleStripeAutoPayToggle()}
                    checked={isAutoRenew}
                    disabled={isDisabled || isUpdating}
                    onColor='#86d3ff'
                    onHandleColor='#2693e6'
                    handleDiameter={24}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                    activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                    height={18}
                    width={44}
                />
            }
        </Row>
    );
};

export default AutoRenewSubscriptionButton;
