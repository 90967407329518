
// Import React Components
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import GlobalContext from '../../context/GlobalContext';

import { useParams, Link } from 'react-router-dom';
import { Container, Row, Alert } from 'react-bootstrap';

// Create The Component
function VerifyEmail() {
    // useParams, useContext
    const params = useParams();
    const navigate = useNavigate();
    const { setShowLogin } = useContext(GlobalContext);
    const { verifyStatus } = params;

    const toSubscribeButton =
        (<span
            className='cbb-span'
            onClick={() => navigate('/my-account/subscribe')}
        >
            {`Account > Subscribe`}
        </span>);

    // Create Alert
    let alertVariant, alertText;
    switch (verifyStatus) {
        // for email verification after signing up
        case 'no-token': alertVariant = 'danger'; alertText = 'Bad verification token - token may have expired'; break;
        case 'no-user': alertVariant = 'danger'; alertText = 'No user is associated with the verification attempt - email info@cbbanalytics.com to resovle issue.'; break;
        case 'already-verified': alertVariant = 'warning'; alertText = 'This email address is already verified - log in to get started'; break;
        case 'cant-save-user': alertVariant = 'danger'; alertText = 'Could not verify email address - email info@cbbanalytics.com to resolve issue'; break;
        case 'success': alertVariant = 'success'; alertText = 'Successfully verified email address - '; break;
        // for free upgrade to 7-day user tier
        case 'user-tier-upgrade-success': alertVariant = 'success'; alertText = <p>Your account has been successfully upgraded to our User Tier for 7 days. Visit {toSubscribeButton} any time to subscribe to our User Tier.</p>; break;
        case 'user-tier-upgrade-already-used': alertVariant = 'danger'; alertText = <p>Our system indicates that you have already redeemed your 7-day trial of the User Tier. You can email me at nick@cbbanalytics.com if you believe this is incorrect. Visit {toSubscribeButton} to subscribe to our User Tier.</p>; break;
        case 'user-tier-upgrade-error': alertVariant = 'danger'; alertText = <p>Our system encountered an issue when upgrading your account to its free 7-day trial of the User Tier. Please email me at nick@cbbanalytics.com, I can issue your trial manually, and will look into the issue.</p>; break;
        default: console.log('Error: Bad verifyStatus param');
    }

    // And Return
    return (
        <Container style={{ maxWidth: '100%' }}>
            <Row style={{ margin: '10px auto', maxWidth: 550 }}>
                <Alert variant={alertVariant}>
                    {alertText}
                    {verifyStatus === 'success' &&
                        <span className='cbb-span' onClick={() => setShowLogin(true)}>
                            log in to get started
                        </span>
                    }
                </Alert>
            </Row>


            {verifyStatus === 'no-token' &&
                <div style={{ margin: 0, marginLeft: 10, fontSize: '1.05em', maxWidth: 825, lineHeight: 1.35 }}>
                    <span>
                        <Link to='/send-verification-email' style={{ color: '#0066CC', fontWeight: 700 }}>
                            Send another verification email.
                        </Link>
                    </span>
                    <span style={{ paddingLeft: 4 }}>In addition to your inbox, be sure to check your spam, social and promotional email folders for the verification email.</span>
                    <span style={{ paddingLeft: 4 }}>You can email info@cbbanalytics.com if problems with email verification persist.</span>
                </div>
            }
        </Container>
    );
}

export default VerifyEmail;
