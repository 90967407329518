import React from 'react';
import { numberSuffix } from './numberSuffix';
import { allCompetitionIds } from '../../../harddata/NcaaStructures';
import { convertSecsToClock } from '../../../utils/ReshapeData';


export function getTooltipHtml({ d, graphType, config }) {
    // console.log('getTooltipHtml params: ', { d, graphType, config });
    const isFromPbp = d && 'minsPbp' in d;
    const gender = allCompetitionIds.male.includes(d?.competitionId) ? 'MALE' : 'FEMALE';

    // initialize, set tooltip based on graphType
    let tooltipHtml;
    switch (graphType) {
        case 'teamScatter':
            tooltipHtml = (<>
                <h3>{d.teamMarket} {d.teamName}</h3>
                <p>{d.gp} Games Played. {d.mins.toFixed(0)} Minutes Played</p>
                {
                    config.xOption.unit === 'pct'
                        ? <p>{config.xOption.label3}: {(100 * d[config.xOption.key]).toFixed(1)}%</p>
                        : <p>{config.xOption.label3}: {(d[config.xOption.key]).toFixed(2)}</p>
                }
                {
                    config.yOption.unit === 'pct'
                        ? <p>{config.yOption.label3}: {(100 * d[config.yOption.key]).toFixed(1)}%</p>
                        : <p>{config.yOption.label3}: {(d[config.yOption.key]).toFixed(2)}</p>
                }
            </>);
            break;

        case 'boxPlot': tooltipHtml =
            (<>
                <div style={{ display: 'flex' }}>
                    <h5>{d.fullName} | {d.teamMarket}</h5>
                </div>
                <p>{config.stat.label}: {(d[config.stat.value] ? (100 * d[config.stat.value]).toFixed(1) : null)}% |&nbsp;
                    Percentile: {(d[config.stat.value] ? (100 * d[config.stat.value + 'Pctile']).toFixed(0) : 0)}% </p>
            </>);
            break;

        case 'onOffDumbbell': tooltipHtml =
            (<p>{config.teamMarket}'s {config.selectedStat.label3} was {config.onCourtLabel} in {config.onMinutes} minutes with {d.fullName} on-court, and {config.offCourtLabel}
                in {config.offMinutes} minutes with {d.fullName} off-court. Overall, {config.teamMarket}'s {config.selectedStat.label3} was {config.diffLabel}
                {config.diffSign === 'plus' ? 'higher' : 'lower'} with {d.fullName} on-court vs off-court.</p>);
            break;

        case 'playerScatter': tooltipHtml =
            (<>
                <h3>{d.fullName} || {d.teamMarket} {d.teamName}</h3>
                <p>{d.gp} Games Played. {isFromPbp ? d.minsPbp.toFixed(0) : d.mins.toFixed(0)} Minutes Played</p>
                {config.xOption.unit === 'pct'
                    ? <p>{config.xOption.label3}: {(100 * d[config.xOption.key])?.toFixed(1) || 'no value'}%</p>
                    : <p>{config.xOption.label3}: {(d[config.xOption.key])?.toFixed(2) || 'no value'}</p>
                }
                {config.yOption.unit === 'pct'
                    ? <p>{config.yOption.label3}: {(100 * d[config.yOption.key])?.toFixed(1) || 'no value'}%</p>
                    : <p>{config.yOption.label3}: {(d[config.yOption.key])?.toFixed(2) || 'no value'}</p>
                }
            </>);
            break;

        case 'shotChartHex': tooltipHtml =
            (<>
                <h3>FG% here is {(100 * d.fgPct7Hex).toFixed(1)}% ({(d.shotDist - 1).toFixed(0)} - {(d.shotDist + 1).toFixed(0)} ft from rim)</h3>
                <p>{config.tipName}'s FG% {!config.isOffense ? ' allowed' : ''}: {(100 * d.fgPct7Hex).toFixed(1)}% ({d.fgm7Hex} / {d.fga7Hex})</p>
                <p>Division{config.divisionId === null ? '' : `-${config.divisionId}`} FG% in this area: {(100 * d.lgFgPct7Hex).toFixed(1)}%</p>
                <p>{config.displayName}'s FG% {!config.isOffense ? ' allowed' : ''} here is {Math.abs(100 * d.netFgPct7Hex).toFixed(1)}% {d.netFgPct7Hex < 0 ? 'below' : 'above'} lg avg</p>
            </>);
            break;

        case 'shotChartZoneFgaFreq': tooltipHtml =
            (<>
                <h3>Shooting {config.ptgc === 'team-opp' ? 'Allowed ' : ''} Zone: {d.name}</h3>
                <p className='underline'>{d.distanceInfo}</p>
                <p>{config.displayName}'s FGA%{!config.isOffense ? ' allowed' : ''} is {(100 * d.fgaFreq).toFixed(1)}% in this zone</p>
                <p>Division-{1} FGA% is {(100 * d.lgFgaFreq).toFixed(1)}%</p>
                <p>{config.displayName} FGA%{!config.isOffense ? ' allowed' : ''} is {Math.abs(100 * d.fgaFreqDiff).toFixed(1)}% {d.fgaFreq < d.lgFgaFreq ? ' below ' : 'above'} lg avg</p>
            </>);
            break;

        case 'shotChartZoneFgPct': tooltipHtml =
            (isNaN(d.fgPct) ?
                (<>
                    <h3>Shooting Zone: {d.name}</h3>
                    <p className='underline'>{d.distanceInfo}</p>
                    <p>No shots taken here</p>
                </>) : (<>
                    <h3>Shooting {config.ptgc === 'team-opp' ? 'Allowed ' : ''} Zone: {d.name}</h3>
                    <p className='underline'>{d.distanceInfo}</p>
                    <p>{config.displayName}'s FG%{!config.isOffense ? ' allowed' : ''} is {(100 * d.fgPct).toFixed(1)}% ({d.fgm} / {d.fga})</p>
                    <p>Division-{config.divisionId || ''} FG% is {(100 * d.lgFgPct).toFixed(1)}%</p>
                    <p>{config.displayName}'s FG%{!config.isOffense ? ' allowed' : ''} is {Math.abs(100 * (d.fgPctDiff)).toFixed(1)}% {d.fgPct < d.lgFgPct ? ' below ' : 'above'} lg avg</p>
                </>));
            break;

        case 'shotChartZoneEfgPct': tooltipHtml =
            (isNaN(d.efgPct) ?
                (<>
                    <h3>Shooting Zone: {d.name}</h3>
                    <p className='underline'>{d.distanceInfo}</p>
                    <p>No shots taken here</p>
                </>) : (<>
                    <h3>Shooting {config.ptgc === 'team-opp' ? 'Allowed ' : ''} Zone: {d.name}</h3>
                    <p className='underline'>{d.distanceInfo}</p>
                    <p>{config.displayName}'s eFG%{!config.isOffense ? ' allowed' : ''} is {(100 * d.fgPct).toFixed(1)}% ({d.fgm} / {d.fga})</p>
                    <p>Overall Division-{config.divisionId || ''} eFG% is {(100 * d.lgEfgPct).toFixed(1)}%</p>
                    <p>{config.displayName}'s FG%{!config.isOffense ? ' allowed' : ''} is {Math.abs(100 * (d.fgPctDiff)).toFixed(1)}% {d.fgPct < d.lgFgPct ? ' below ' : 'above'} lg avg</p>
                </>));
            break;

        case 'playTimeBreakdowns': {
            switch (config.graphType) {
                case 'timeOnCourt': tooltipHtml =
                    (<>
                        <p style={{ fontSize: '1.2em', fontWeight: 700 }}>{d.minsIntoGame}{numberSuffix({ number: d.minsIntoGame })} minute into games</p>
                        <p style={{ fontSize: '1.05em' }}>
                            {d.fullName} was tagged on court in the {d.minsIntoGame}{numberSuffix({ number: d.minsIntoGame })} minute in {(100 * d.pctAtMin).toFixed(0)}% of {config.teamMarket}'s games this season ({d.countAtMin} of {d.teamGp} games)
                        </p>
                    </>);
                    break;
                case 'onCourtForShots': tooltipHtml =
                    (<>
                        <p style={{ fontSize: '1.2em', fontWeight: 700 }}>{d.minsIntoGame}{numberSuffix({ number: d.minsIntoGame })} minute into games</p>
                        <p style={{ fontSize: '1.05em' }}>
                            {d.fullName} was on court for {d.onCourtShots} of the team's {d.totalShots} shots taken ({(100 * d.onCourtShotPct).toFixed(0)}%) in the {d.minsIntoGame}{numberSuffix({ number: d.minsIntoGame })} minute of {config.teamMarket}'s games played
                        </p>
                    </>);
                    break;
                case 'shotsTaken': tooltipHtml =
                    (<>
                        <p style={{ fontSize: '1.2em', fontWeight: 700 }}>{d.minsIntoGame}{numberSuffix({ number: d.minsIntoGame })} minute into games</p>
                        <p style={{ fontSize: '1.05em' }}>
                            {d.fullName} took {d.playerShots} of the team's {d.totalShots} shots ({(100 * d.playerShotPct).toFixed(0)}%) in the {d.minsIntoGame}{numberSuffix({ number: d.minsIntoGame })} minute of {config.teamMarket}'s games played
                        </p>
                    </>);
                    break;
                default: tooltipHtml = <></>;
            }
            break;
        }

        case 'rollingGameLogs': {
            const thisDate = new Date(d.gameTime);
            const year = thisDate.getFullYear();
            const month = thisDate.getMonth() + 1;
            const day = thisDate.getDate();
            tooltipHtml = (<>
                <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #222222', marginBottom: '6px' }}>
                    <h3 style={{ fontSize: '1.2em', margin: '0 5px 0 0' }}>
                        {d.teamId === d.awayId && !d.isNeutral ? '@' : 'vs'}
                    </h3>
                    {config.allLogos[d.teamIdAgst]}
                    <h3 style={{ fontSize: '1.2em', margin: '0 0 0 5px' }}>
                        {`on ${month}/${day}/${year}`}

                    </h3>
                </div>
                {config.ptgc === 'player' &&
                    <p style={{ margin: 0, lineHeight: 1.4 }}>{`${d.fullName} played ${d.mins.toFixed(0)} minutes in this game.`}</p>
                }
                <p style={{ margin: 0, lineHeight: 1.4 }}>{`${config.yUnits(d[config.stat.key])} ${config.stat.label3} in this game.`}</p>
            </>);
            break;
        }

        case 'histogram': tooltipHtml =
            (<>
                <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #222222', marginBottom: '6px' }}>
                    <h3 style={{ fontSize: '1.2em', margin: '0 0 0 0px' }}>
                        {config.yUnits(d.x0)}{` ≤ `}{config.stat.label3.toLowerCase()}{` < `}{config.yUnits(d.x1)}
                    </h3>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {config.teamLogo}
                    <p style={{ margin: 0, lineHeight: 1.4 }}>
                        {(100 * (d.length / config.graphData.length)).toFixed(0)}% of games ({d.length}/{config.graphData.length} games) with {config.stat.label3.toLowerCase()} in this range.
                    </p>
                </div>
            </>);
            break;

        case 'aggTeamScatterCircles': tooltipHtml =
            (<div>
                <h3 style={{ fontSize: '1.2em', margin: 0 }}>
                    {d.teamMarket} {d.teamName}
                </h3>
                <p>{config.xOption.label3}: {(d[config.xOption.key])?.toFixed(2) || 'no value'}</p>
                <p>{config.yOption.label3}: {(d[config.yOption.key])?.toFixed(2) || 'no value'}</p>
            </div>);
            break;

        case 'gameTeamScatterCircles': tooltipHtml =
            (<div>
                <h3 style={{ fontSize: '1.2em', margin: 0 }}>
                    {d.teamMarket} {d.teamName}
                    <span style={{ fontSize: '0.9em', paddingLeft: 5 }}>{d.gameDate}</span>
                </h3>
                <p>{d.ptsScored > d.ptsAgst ? 'Win' : 'Loss'} ({d.ptsScored} - {d.ptsAgst}) {!d.isHome && !d.isNeutral ? '@' : 'vs'} {d.teamMarketAgst}</p>
                <p>{config.xOption.label3}: {(d[config.xOption.key])?.toFixed(2) || 'no value'}</p>
                <p>{config.yOption.label3}: {(d[config.yOption.key])?.toFixed(2) || 'no value'}</p>
            </div>);
            break;

        case 'lineupFlowV2': tooltipHtml =
            (<>
                <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #222222', marginBottom: '6px' }}>
                    <h3 style={{ fontSize: '1.2em', margin: '0 0 0 0px' }}>
                        Header of tooltip
                    </h3>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ margin: 0, lineHeight: 1.4 }} />
                </div>
            </>);
            break;

        case 'oneLineupByGame': tooltipHtml =
            (<>
                <div style={{ borderBottom: '1px solid #222222', marginBottom: '6px' }}>
                    <h3 style={{ fontSize: '1.15em', margin: '0 0 0 0px' }}>
                        {d.secs.toFixed(0)} Seconds On The Court
                    </h3>
                    <h3 style={{ fontSize: '1.15em', margin: '0 0 0 0px' }}>
                        from {convertSecsToClock({ secs: d.sStart, gender })} to {convertSecsToClock({ secs: d.sEnd, gender })}
                    </h3>
                </div>
                <div style={{ alignItems: 'center' }}>
                    <p style={{ margin: 0, lineHeight: 1.4 }}>
                        {d.ptsScored >= d.ptsAgst ? '+' : ''}{d.ptsScored - d.ptsAgst} point differential ({d.ptsScored} points scored, {d.ptsAgst} points allowed)
                    </p>
                    <p style={{ marginLeft: 5 }}> ○ {d.ptsScored > d.ptsAgst ? '+' : ''}{(40 * (d.ptsScored - d.ptsAgst) / (d.secs / 60)).toFixed(1)} point differential / 40 mins</p>
                    <p style={{ marginLeft: 5 }}> ○ {(40 * (d.ptsScored / (d.secs / 60))).toFixed(1)} points scored / 40 mins</p>
                    <p style={{ marginLeft: 5 }}> ○ {(40 * (d.ptsAgst / (d.secs / 60))).toFixed(1)} points against / 40 mins</p>
                </div>
            </>);
            break;
        case 'categoricalScatter': tooltipHtml =
        (<>
            <h3>{d?.tips?.tipTitle}</h3>
            {(<p>{(d?.value * 100)?.toFixed(0)}{d?.unit === 'pct' ? '%' : ' times'}:  {d?.tips?.teamTip || d?.tips?.mainTip}</p>)}

        </>);
            break;

        default: console.log('ERROR: wrong graphType value');
    }

    // returns HTML for tooltip for graph
    return tooltipHtml;
}
