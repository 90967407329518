
// https://redketchup.io/image-resizer for image sizing
// https://onlinepngtools.com/convert-jpg-to-png for adding transparency to pngs
// https://tinypng.com/ for minifying pngs

// Import React Components
import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import GlobalContext from '../../context/GlobalContext';

// CBB Components
import ControllerNavbar from '../../components/navbars/ControllerNavbar';
import LandingPageCard from '../../components/uiUxItems/LandingPageCard';
import TextHeaders from '../../components/headers/TextHeaders';
import imageUrls from '../../images/gcsImages';


function StatsLanding() {
    // gotta grab for correct scoreboard path
    const { userTier, userData, defaultCompetition } = useContext(GlobalContext);
    let isLoggedIn = userData.user ? true : false;

    let divisionId = isLoggedIn ? userData.user.team.divisionId : 1;
    let competitionId = defaultCompetition.value;
    let draftCompId = isLoggedIn ? (userData.user.team.gender === 'MALE' ? 33533 : 33535) : 33533; // update after hard-coding draft prospects for new season
    let tourneyCompId = isLoggedIn ? (userData.user.team.gender === 'MALE' ? 33533 : 33535) : 33533; // update when March Madness 68-Team Tourney starts
    let tournamentId = isLoggedIn ? (userData.user.team.gender === 'MALE' ? 1766 : 1765) : 1766; // update when March Madness 68-Team Tourney starts

    // headers
    let freeTierHeader =
        (<Row style={{ margin: '50px 0 35px 0', justifyContent: 'center' }}>
            <TextHeaders headerStyles={{ textAlign: 'center', fontSize: 42, textDecorationColor: 'darkred' }}>
                For Free Tier Users
            </TextHeaders></Row>);
    let userTierHeader =
        (<Row style={{ margin: '50px 0 35px 0', justifyContent: 'center' }}>
            <TextHeaders headerStyles={{ textAlign: 'center', fontSize: 42, textDecorationColor: 'darkred' }}>
                For User Tier Subscribers
            </TextHeaders></Row>);
    let proTierHeader =
        (<Row style={{ margin: '50px 0 35px 0', justifyContent: 'center' }}>
            <TextHeaders headerStyles={{ textAlign: 'center', fontSize: 42, textDecorationColor: 'darkred' }}>
                For Pro Tier Subscribers
            </TextHeaders></Row>);

    // and return
    return (
        <Container className='shadow-container'>
            <ControllerNavbar
                label='Diving into the Data: '
                subLabel='Exploring the Depths of College Basketball Statistics'
                view=''
                ptgc='tools'
                pad={false}
                showButtons={false}
            />
            <Container className='shadow-container' style={{ maxWidth: 1250 }}>

                {/* Free Tier Stats Pages */}
                {userTier.value === 0 && freeTierHeader}
                <Row style={{ padding: 0, margin: 0 }}>
                    <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }} style={{ marginBottom: 50, padding: '0px 30px' }}>
                        <LandingPageCard
                            header='Conference Stats'
                            subHeader='A rundown of the best performing conferences.'
                            path={`/stats/conference-home/${competitionId}`}
                            disabled={userTier.value === 0}
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }} style={{ marginBottom: 50, padding: '0px 30px' }}>
                        <LandingPageCard
                            header='Draft Prospects'
                            subHeader={`A rundown of the top 50 - 100 potential WNBA and NBA draft prospects each year, as listed by various top 100 lists.`}
                            src={imageUrls.statsDraftProspects}
                            path={`/stats/${draftCompId}/draft/player-box`}
                            config={{ imgStyle: { padding: 0 } }}
                            disabled={userTier.value === 0}
                        />
                    </Col>
                </Row>

                {/* Free Tier Stats Pages */}
                {userTier.value <= 1 && userTierHeader}
                <Row style={{ padding: 0, margin: 0 }}>
                    <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }} style={{ marginBottom: 50, padding: '0px 30px' }}>
                        <LandingPageCard
                            header='Division Leaderboards'
                            subHeader='The top performing players, teams, lineups and player on-off stats for each division. There are our full Division-I, II and III leaderboards.'
                            path={`/stats/${competitionId}/division/d${divisionId}/team-box`}
                            disabled={userTier.value <= 1}
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }} style={{ marginBottom: 50, padding: '0px 30px' }}>
                        <LandingPageCard
                            header='Tournament Stats'
                            subHeader='Tournament-specific Stats: Detailed statistics for individual tournaments.'
                            path={`/stats/${tourneyCompId}/tournaments/${tournamentId}/team-box`}
                            disabled={userTier.value <= 1}
                        />
                    </Col>

                </Row>

                {/* Pro Tier Stats Pages */}
                {userTier.value <= 2 && proTierHeader}
                <Row style={{ padding: 0, margin: 0 }}>
                    <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }} style={{ marginBottom: 50, padding: '0px 30px' }}>
                        <LandingPageCard
                            header='Transfer Portal'
                            subHeader='Active Transfers: Stats and details on players currently in the transfer portal.'
                            src={imageUrls.statsTransferPortal}
                            path='/stats/portal/overview'
                            config={{ imgStyle: { padding: 0 } }}
                            disabled={userTier.value <= 2}
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }} style={{ marginBottom: 50, padding: '0px 30px' }}>
                        <LandingPageCard
                            header='Transfer History'
                            subHeader={`An overview of teams' performance in the transfer portal over the years. Additionally, who were the best incoming & outgoing transfers.`}
                            src={imageUrls.statsTransferGraph}
                            path={`/stats/${competitionId}/transfers/${divisionId}/overview`}
                            disabled={userTier.value <= 2}
                        />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}


export default StatsLanding;
