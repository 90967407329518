
// Import React Components
import React, { useContext, useRef, useState } from 'react';
import Select from 'react-select';

// Import Data-Fetching Components
import GlobalContext from '../../context/GlobalContext';
import useCBBQuery from '../../hooks/useCBBQuery';

// Import Other CBB Analytics Components
import SelectsSaver from './SelectsSaver';
import CBBSelect from './CBBSelect';
import questionMark from '../../images/question-mark.png';
import { getPlayerNameAbb, isNil } from '../../utils/ReshapeData';
import { blackSelectStyles, filterSelectStyles, smallSelectStyles } from '../../utils/ReactSelectStyles';
import { topD1Conferences, topD2Conferences, topD3Conferences } from '../../harddata/NcaaStructures';
import { conferenceLogoUrl, teamLogoUrl } from '../../utils/GetLogos';


const optionsDict = {
    rollingGraphTrendLines: {
        1: { width: 300, options: [
            { value: 'teamAvg', label: 'Team Average', shortLabel: 'Team Avg' },
            { value: 'conferenceAvg', label: 'Conference Average', shortLabel: 'Conf Avg' },
            { value: 'divisionAvg', label: 'Division Average', shortLabel: 'Div Avg' }
        ] }
    },

    eventLogTypes: {
        1: { width: 300, options: [
            { value: 'login', label: 'Login w/ Email, PW', shortLabel: 'lgin' },
            { value: 'error', label: 'Page Error', shortLabel: 'err' },
            { value: 'pagechange', label: 'Page Change', shortLabel: 'chng' },
            { value: 'localstoragefetch', label: 'LocalStorage Fetch', shortLabel: 'ftch' }
        ] }
    },
    zoneShootingMetrics: {
        1: { width: 320, options: [
            { value: 'fgm', label: 'Field Goals Made', shortLabel: 'FGM' },
            { value: 'fga', label: 'Field Goal Attempts', shortLabel: 'FGA' },
            { value: 'fgaFreq', label: 'Field Goal Attempt Frequency', shortLabel: 'FGA%' },
            { value: 'fgPct', label: 'Field Goal Percentage', shortLabel: 'FG%' },
            { value: 'astdPct', label: 'Assisted Percentage', shortLabel: 'ASTD%' }
        ] },
        2: { width: 320, options: [
            { value: 'fgm', label: 'Field Goals Made', shortLabel: 'FGM' },
            { value: 'fga', label: 'Field Goal Attempts', shortLabel: 'FGA' },
            { value: 'fgaPg', label: 'Field Goal Attempts / Game', shortLabel: 'FGA/G' },
            { value: 'fgaP40', label: 'Field Goal Attempts / 40 Mins', shortLabel: 'FGA/40' },
            { value: 'fgaP100', label: 'Field Goal Attempts / 100 Poss', shortLabel: 'FGA/100' },
            { value: 'fgaFreq', label: 'Field Goal Attempt Frequency', shortLabel: 'FGA%' },
            { value: 'fgPct', label: 'Field Goal Percentage', shortLabel: 'FG%' },
            { value: 'astdPct', label: 'Assisted Percentage', shortLabel: 'ASTD%' }
        ] },
        3: { width: 350, options: [ // for shot charts
            { value: 'fgm', label: 'Field Goals Made', shortLabel: 'FGM' },
            { value: 'fga', label: 'Field Goal Attempts', shortLabel: 'FGA' },
            { value: 'fgaPg', label: 'Field Goal Attempts / Game', shortLabel: 'FGA/G' },
            { value: 'fgaFreq', label: 'Field Goal Attempt Frequency', shortLabel: 'FGA%' },
            { value: 'fgPct', label: 'Field Goal Percentage', shortLabel: 'FG%' }
        ] },
        4: { width: 350, options: [ // 5 options + per game
            { value: 'fgm', label: 'Field Goals Made', shortLabel: 'FGM' },
            { value: 'fga', label: 'Field Goal Attempts', shortLabel: 'FGA' },
            { value: 'fgaPg', label: 'Field Goal Attempts / Game', shortLabel: 'FGA/G' },
            { value: 'fgaFreq', label: 'Field Goal Attempt Frequency', shortLabel: 'FGA%' },
            { value: 'fgPct', label: 'Field Goal Percentage', shortLabel: 'FG%' },
            { value: 'astdPct', label: 'Assisted Percentage', shortLabel: 'ASTD%' }
        ] },
        5: { width: 350, options: [ // 5 options + per 40
            { value: 'fgm', label: 'Field Goals Made', shortLabel: 'FGM' },
            { value: 'fga', label: 'Field Goal Attempts', shortLabel: 'FGA' },
            { value: 'fgaP40', label: 'Field Goal Attempts / 40 Mins', shortLabel: 'FGA/40' },
            { value: 'fgaFreq', label: 'Field Goal Attempt Frequency', shortLabel: 'FGA%' },
            { value: 'fgPct', label: 'Field Goal Percentage', shortLabel: 'FG%' },
            { value: 'astdPct', label: 'Assisted Percentage', shortLabel: 'ASTD%' }
        ] },
        6: { width: 350, options: [ // 5 options + per 100
            { value: 'fgm', label: 'Field Goals Made', shortLabel: 'FGM' },
            { value: 'fga', label: 'Field Goal Attempts', shortLabel: 'FGA' },
            { value: 'fgaP100', label: 'Field Goal Attempts / 100 Poss', shortLabel: 'FGA/100' },
            { value: 'fgaFreq', label: 'Field Goal Attempt Frequency', shortLabel: 'FGA%' },
            { value: 'fgPct', label: 'Field Goal Percentage', shortLabel: 'FG%' },
            { value: 'astdPct', label: 'Assisted Percentage', shortLabel: 'ASTD%' }
        ] }
    },

    overviewTables: {
        1: { width: 450, options: [
            { value: 'coreStats', label: 'Core Stats', shortLabel: 'Core', isDefault: true },
            { value: 'miscScoring', label: 'Misc Scoring', shortLabel: 'Misc' },
            { value: 'boxScore', label: 'Box Score', shortLabel: 'Box' },
            { value: 'shooting', label: 'Shooting', shortLabel: 'Shoot' },
            { value: 'advOffense', label: 'Adv Offense', shortLabel: 'Adv O' },
            { value: 'advDefense', label: 'Adv Defense', shortLabel: 'Adv D' }
        ] }
    },

    onOffDiff: {
        1: { width: 320, options: [
            { value: 'diff', label: 'Difference with player on/off', shortLabel: 'On/Off Diff', isDefault: true },
            { value: 'on', label: 'Stats with player on-court', shortLabel: 'On Stats' },
            { value: 'off', label: 'Stats with player off-court', shortLabel: 'Off Stats' }
        ] }
    },
    transferPortalPlayerStatus: {
        1: { width: 326, options: [
            { value: 'toD0', label: 'Transferred to NAIA, NJCAA', shortLabel: 'To NAIA' },
            { value: 'toD1', label: 'Transferred to D-I', shortLabel: 'To D1' },
            { value: 'toD23', label: 'Transferred to D-II, III', shortLabel: 'To D2,3' },
            { value: 'active', label: 'Still Available In Portal', shortLabel: 'Active' }
        ] }
    },
    transferDirection: {
        1: { width: 250, options: [
            { value: 'transfersIn', label: 'Incoming Transfers', shortLabel: 'Transfers In' },
            { value: 'transfersOut', label: 'Outgoing Transfers', shortLabel: 'Transfers Out' }
        ] }
    },
    transferSeasonFor: {
        1: { width: 250, options: [
            { value: 'priorYear', label: 'Pre Transfer Prior Season Stats', shortLabel: 'Pre Transfer' },
            { value: 'nextYear', label: 'Post Transfer (This Year) Stats', shortLabel: 'Post Transfer' }
        ] }
    },

    shotContext: {
        1: { width: 300, options: [
            { value: 'hc', label: 'Half Court', shortLabel: 'Half Court' },
            { value: 'tr', label: 'Transition', shortLabel: 'Transition' },
            { value: 'pb', label: 'Putbacks', shortLabel: 'Putbacks' }
        ] }
    },

    scopes: {
        1: { width: 325, placeholder: 'Select Splits...', options: [
            { value: 'l5g', label: 'Last 5 Games', shortLabel: 'L5G' },
            { value: 'l10g', label: 'Last 10 Games', shortLabel: 'L10G' },
            { value: 'season', label: 'Full Season', shortLabel: 'Full', isDefault: true },
            { value: 'confReg', label: 'In-Conference (Reg)', shortLabel: 'ConfR' },
            { value: 'confAll', label: 'In-Conference (All)', shortLabel: 'ConfA' },
            { value: 'nonconfReg', label: 'Non-Conference (Reg)', shortLabel: 'NonC' },
            { value: 'post', label: 'Post Season', shortLabel: 'Post' },
            { value: 'home', label: 'Home Games', shortLabel: 'Home' },
            { value: 'away', label: 'Away Games', shortLabel: 'Away' },
            { value: 'neutral', label: 'Neutral Games', shortLabel: 'Neut' },
            { value: 'wins', label: 'Games Won', shortLabel: 'Wins' },
            { value: 'losses', label: 'Games Lost', shortLabel: 'Loss' },
            { value: 'clutch', label: 'Clutch Stats', shortLabel: 'CTH' },
            { value: 'h1', label: '1st Half', shortLabel: 'H1' },
            { value: 'h2', label: '2nd Half', shortLabel: 'H2' },
            { value: 'quad1', label: 'Quad 1 Games', shortLabel: 'Quad1' },
            { value: 'quad2', label: 'Quad 2 Games', shortLabel: 'Quad2' },
            { value: 'quad3', label: 'Quad 3 Games', shortLabel: 'Quad3' },
            { value: 'quad4', label: 'Quad 4 Games', shortLabel: 'Quad4' },
            { value: 'daterange', label: 'Date Range', shortLabel: 'Date' },
            { value: 'custom', label: 'Select Your Own Games', shortLabel: 'CS' },
            { value: 'game', label: 'Single Game', shortLabel: 'Game' },
            { value: 'conferenceAvg', label: 'Conference Average', shortLabel: 'cAvg' },
            { value: 'divisionAvg', label: 'Division Average', shortLabel: 'dAvg' }
        ] }
    },

    conferences: {
        1: { width: 425, placeholder: 'Select Conferences', selectsSaver: 'conferences', options: [], topOptions: {
            1: topD1Conferences,
            2: topD2Conferences,
            3: topD3Conferences
        } }
    },

    comparisonSections: {
        1: { width: 325, options: [
            { value: 'coreTables', label: 'Core Six Tables', shortLabel: 'Core Tables' },
            { value: 'shotChartHex', label: 'Shot Chart - Heat Map', shortLabel: 'Heat Map' },
            { value: 'shotChartZone', label: 'Shot Chart - By Zone', shortLabel: 'Zone Chart' },
            { value: 'sbzTable', label: 'Shooting By Zone Table', shortLabel: 'Zone Table' },
            { value: 'scatterPlots', label: 'Comparison Scatter Plots', shortLabel: 'Scatter' }
        ] }
    },

    streakBuckets: {
        1: { width: 325, options: [
            { value: '8-0', label: '8-0 Runs or Longer', shortLabel: '8-0 Runs' },
            { value: '10-0', label: '10-0 Runs or Longer', shortLabel: '10-0 Runs' },
            { value: '12-0', label: '12-0 Runs or Longer', shortLabel: '12-0 Runs' }
        ] }
    },

    games: { 1: { width: 450, placeholder: 'Select Games', selectsSaver: 'games', optionClass: 'game-' } },
    teams: { 1: { width: 425, placeholder: 'Select Teams', selectsSaver: 'teams', optionClass: 'team-' } },
    players: { 1: { width: 425, placeholder: 'Select Players', selectsSaver: 'players', optionClass: 'player-' } },
    playerSeasons: { 1: { width: 425, placeholder: 'Select Player-Seasons', selectsSaver: 'player-seasons' } },
    pageSections: { 1: { width: 425, placeholder: 'Select Page Sections', optionClass: 'section-' } }
};

function CBBMultiSelect({
    selectType,
    stylesType = 'default',
    array,
    setArray,
    optionGroup = 1,
    optionsArray = null,
    keeperOptions = [],
    extraOptions = ['top', 'all'],
    defaultObj = null,
    isDisabled = false,
    placeholder,
    ids = { competitionId: null, teamId: null, divisionId: null, conferenceId: null, tournamentIds: [] },
    wrapperStyle = {},
    maxOptions = null,
    showPairingRecs = false,
    gender = 'all'
}) {
    // useState, useContext for scopes/splits, show the extra select
    const [showPairingRecsSelect, setShowPairingRecsSelect] = useState(false);
    const { tournamentInfosObj } = selectType === 'scopes' ? useContext(GlobalContext) : { tournamentInfosObj: {} };
    const { competitionId, teamId, divisionId, conferenceId } = ids || {};
    // console.log('ids: ', { competitionId, teamId, divisionId, conferenceId, tournamentIds });

    // fetch data if needed
    let fetch;
    switch (selectType) {
        case 'conferences': fetch = { pass: false, ep: 'conferences', config: {} }; break;
        case 'teams': fetch = { pass: !(optionsArray === null || typeof optionsArray === 'undefined'), ep: 'teams', config: { ...(!isNil({ d: divisionId }) && { divisionId }), ...(!isNil({ d: conferenceId }) && { conferenceId }) } }; break;
        default: fetch = { pass: true, ep: '', config: {} };
    }
    const { data: fetchedOptions = [], isLoading, isError } = useCBBQuery({ ep: fetch.ep, cfg: { ...fetch.config, pass: fetch.pass } });
    if (isError) { console.log('Data Fetching Error!'); }
    // if (fetch.pass === false) { console.log('fetchedOptions: ', { ep: fetch.endpoint, isLoading, isError, cfg: fetch.config, fetchedOptions }); }


    // grab correct options, width, placeholder for selects (fetch vs optionsArray vs optionsDict for options)
    let optionGrouping = optionGroup || 1;
    let selectTypeOptions = optionsDict[selectType]?.[optionGrouping]?.options || [];
    let selectOptions = fetch.pass === false ? fetchedOptions : (optionsArray || selectTypeOptions || []);

    const option = optionsDict[selectType]?.[optionGrouping];
    const width = option?.width || 250;
    const optionPlaceholder = option?.placeholder || 'Select...';
    const optionClass = option?.optionClass || '';

    // set option select saver type, handling games for dedicated competitionId + teamId
    let optionSelectSaver = option?.selectsSaver || null;
    if (optionSelectSaver === 'games') {
        optionSelectSaver = `${optionSelectSaver}-${competitionId}-${teamId}`;
    }

    let validSelectTypes = Object.keys(optionsDict);
    if (!(validSelectTypes.includes(selectType))) {
        console.log(`Error: ${selectType} select type not in validSelectTypes. error probably incoming.`);
    }


    // customize (filter, unshift, sort, map) selectOptions for certain selectTypes
    let incTop = extraOptions.includes('top');
    let incAll = extraOptions.includes('all');
    switch (selectType) {
        // conferences: (if needed), filter for divisionId, conferenceId, as well as adding TOP5, add ALL as option
        case 'conferences':
            selectOptions = [...(selectOptions ?? [])]
                .filter(row => isNil({ d: ids.divisionId }) ? true : row.divisionId === Number(ids.divisionId))
                .filter(row => keeperOptions.length === 0 ? true : keeperOptions.includes(row.conferenceId))
                .sort((a, b) => a.conferenceLongName.localeCompare(b.conferenceLongName));
            if (incTop) { selectOptions.unshift({ conferenceId: 'TOP5', conferenceLongName: `Top D-${ids.divisionId} Conferences` }); }
            if (incAll) { selectOptions.unshift({ conferenceId: 'ALL', conferenceLongName: `All D-${ids.divisionId} Conferences` }); }
            break;

        // teams: (if needed), filter for gender, keeperTeams, divisionId, as well as adding ALL as option
        case 'teams':
            selectOptions = [...(selectOptions ?? [])]
                .filter(team => gender === 'all' ? true : (gender === 'MALE' ? team.gender === 'MALE' : team.gender === 'FEMALE'))
                .filter(team => keeperOptions.length === 0 ? true : keeperOptions.includes(team.teamId))
                .filter(team => isNil({ d: divisionId }) ? true : team.divisionId === divisionId)
                .sort((a, b) => a.teamMarket.localeCompare(b.teamMarket));
            if (incAll) { selectOptions.unshift({ teamId: 'ALL', teamMarket: 'All Teams' }); }
            break;

            // // games: filter for completed games, and sort by game date
            // case 'games':
            //     selectOptions = [...(selectOptions ?? [])]
            //         .filter(row => row.gameStatus === 'COMPLETE')
            //         .sort((a, b) => { return a.gameDate < b.gameDate ? 1 : -1; });
            //     if (incAll) { selectOptions.unshift({ gameId: 'ALL', gameDate: 'All Games' }); }
            //     break;

        // scopes: handle tournament scope in particular, and map to { value, label, shortLabel, tournamentId }
        case 'scopes': {
            selectOptions = [...(selectOptions ?? [])]
                .filter(d => keeperOptions.includes(d.value));

            let tournamentsArray = Object.values(tournamentInfosObj);
            let keeperTournaments = ids.tournamentIds ? ids.tournamentIds : [];
            let newOptions = tournamentsArray
                .filter(row => keeperTournaments.includes(row.tournamentId))
                .map(row => ({ value: `tourney${row.tournamentId}`, label: row.tournamentName, tournamentId: row.tournamentId, shortLabel: `T${row.tournamentId}` }));

            selectOptions = [...selectOptions, ...newOptions];
        } break;

        default: // selectOptions = selectOptions;
    }

    // grab correct styles
    let selectStyles, logoSize;
    switch (stylesType) {
        case 'default': selectStyles = blackSelectStyles(); logoSize = 26; break;
        case 'small': selectStyles = smallSelectStyles; logoSize = 20; break;
        case 'filter': selectStyles = filterSelectStyles; logoSize = 26; break;
        default: selectStyles = blackSelectStyles(); logoSize = 26;
    }

    // handle updating multi select
    const handleChange = (latestArray, { action, removedValue }) => {
        let newArray = latestArray;

        // handle no options, leading to set to pre-specified default options
        let defaultArray = selectOptions.filter(row => row.isDefault);
        defaultArray = defaultObj === null ? defaultArray : [defaultObj];
        if (!latestArray || latestArray.length === 0) { setArray(defaultArray); return; }

        // If action would increase above maxOptions, remove last option
        if (maxOptions !== null && latestArray !== null && latestArray.length > maxOptions) {
            newArray = [...newArray.slice(0, latestArray.length - 2), ...newArray.slice(-1)];
        }

        // If action would remove fixed option (e.g. forced scopes), keep the option & return
        if (removedValue && removedValue.isFixed) { return; }

        // teams: unique handling
        if (selectType === 'teams') {
            if (action === 'select-option' && latestArray[latestArray.length - 1].teamId === 'ALL') {
                setArray([]); return;
            }
        }

        // games: unique handling
        if (selectType === 'games') {
            if (action === 'select-option' && latestArray[latestArray.length - 1].gameId === 'ALL') {
                let allGames = selectOptions.filter(row => row.gameId !== 'ALL');
                setArray(allGames); return;
            }
        }

        // conferences: unique handling
        if (selectType === 'conferences') {
            let justSelected = latestArray[latestArray.length - 1].conferenceId;
            if (action === 'select-option' && justSelected === 'ALL') {
                // set empty array to show all conferences, and return
                setArray([]); return;
            } else if (action === 'select-option' && justSelected === 'TOP5') {
                // set to pre-fixed "Top Conferences" in the division, and return
                setArray(optionsDict.conferences[1].topOptions[ids.divisionId] || []); return;
            }
        }

        // players: unique handling
        if (['players', 'playerSeasons'].includes(selectType)) {
            let justSelected = latestArray[latestArray.length - 1].value;
            if (action === 'select-option' && justSelected === 'ALL') {
                // set empty array to show all conferences, and return
                setArray([]); return;
            }
        }

        // standard operating procedure for multi-select
        switch (action) {
            case 'select-option': setArray(newArray); return;
            case 'remove-value': setArray(newArray); return;
            case 'clear': setArray(defaultArray); return;
            default: return;
        }
    };

    // Use shortLabel for selected metrics
    const CustomMultiLabel = (obj) => {
        // obj: the "option" object passed

        // different labels for different selectTypes
        let labelComponent;
        switch (selectType) {
            // conferences: show the conference logo
            case 'conferences': {
                let conferenceLogo = conferenceLogoUrl(obj.data.conferenceId);
                labelComponent = (
                    <div style={{ width: logoSize, height: logoSize, marginLeft: 1 }}>
                        <img
                            alt='conf logo'
                            style={{ width: '95%' }}
                            onError={ev => { ev.target.src = questionMark; }}
                            src={conferenceLogo} />
                    </div>
                );
            } break;

            // teams: show the team logo
            case 'teams': {
                let teamLogo = teamLogoUrl(obj.data.teamId);
                labelComponent = (
                    <div style={{ width: logoSize, height: logoSize }}>
                        <img
                            alt='logo'
                            style={{ width: '85%', marginLeft: 1 }}
                            onError={ev => { ev.target.src = questionMark; }}
                            src={teamLogo}
                        />
                    </div>
                );
            } break;

            // games: show the game date "03-27"
            case 'games': {
                let gameDate = obj.data.gameDate;
                let dateLabel = `${gameDate.substr(5, 2)}-${gameDate.substr(8, 2)}`;
                labelComponent = (
                    <p style={{ fontSize: '0.8em' }}>{dateLabel}</p>
                );
            } break;

            // players: show the player abbreviation "A.Bosto"
            case 'players': {
                let shortName = getPlayerNameAbb({ fullName: obj.data.fullName });
                labelComponent = (
                    <span style={{ fontSize: '0.88em', paddingLeft: 3 }}>
                        {shortName}
                    </span>
                );
            } break;

            // playerSeasons: show abbreviation & season label "A.Bosto 21-22"
            case 'playerSeasons': { // seasonLabel here may be a problem, since we have removed all seasonLabels from DBT
                let shortLabel = `${getPlayerNameAbb({ fullName: obj.data.fullName })} ${obj.data.seasonLabel}`;
                labelComponent =
                    (<span style={{ fontSize: '0.9em', paddingLeft: 3 }}>
                        {shortLabel}
                    </span>);
            } break;

            // all other selectTypes, show the hardcoded shortLabel for the option.
            default:
                labelComponent = (
                    <span style={{ fontSize: '0.88em', paddingLeft: 3 }}>
                        {obj.data.shortLabel}
                    </span>
                );
        }

        return labelComponent;
    };

    // create getOptionValue, getOptionLabel functions
    let valueFunc = null, labelFunc = null;
    switch (selectType) {
        case 'conferences':
            valueFunc = d => d.conferenceId;
            labelFunc = d => `${d.conferenceLongName} ${d.conferenceShortName ? `(${d.conferenceShortName})` : ''}`;
            break;
        case 'players':
            valueFunc = d => d.playerId;
            labelFunc = d => d.fullName;
            break;
        case 'playerSeasons':
            valueFunc = d => `${d.playerId}-${d.competitionId}`;
            labelFunc = d => `${d.fullName} (${d.seasonLabel})`;
            break;
        case 'teams':
            valueFunc = d => d.teamId;
            labelFunc = d => `${d.teamMarket} ${d.teamName || ''}`;
            break;
        case 'games':
            valueFunc = d => d.gameId;
            labelFunc = d => {
                // if (d.gameDate === 'All Games') { return 'Select All Games at Once'; }
                let { gameDate, isNeutral, homeId, homeName, homeMarket, awayName, awayMarket } = d;
                let vsAtNeu = isNeutral ? 'neu' : (homeId === teamId ? 'vs' : '@');
                let oppTeam = homeId === teamId ? `${awayMarket} ${awayName}` : `${homeMarket} ${homeName}`;
                return `${gameDate} ${vsAtNeu} ${oppTeam}`;
            };
            break;
        default: valueFunc = d => d.value; labelFunc = d => d.label;
    }

    // create the select

    const className = selectType ? selectType.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() : 'nada-select-type';
    const cbbMultiSelect =
        (<Select
            isMulti
            className={`cbb-select cbb-${className}-multi-select`}
            classNames={{
                option: d => `cbb-option-${optionClass}${valueFunc(d.data)}`,
                multiValue: d => `cbb-multivalue-${optionClass}${valueFunc(d.data)}`
            }}
            styles={selectStyles}
            value={array}
            options={selectOptions}
            onChange={handleChange}
            placeholder={placeholder || optionPlaceholder || 'Select...'}
            isDisabled={isLoading || isDisabled}
            components={{ MultiValueLabel: CustomMultiLabel }}
            {...(typeof valueFunc === 'function' && { getOptionValue: valueFunc })}
            {...(typeof labelFunc === 'function' && { getOptionLabel: labelFunc })}
        />);

    // handle using pairing recs to update multi-select (for scopes/splits)
    const handlePairingRec = (e) => {
        let newScopes = selectOptions.filter(row => e.scopes.includes(row.value));
        setArray(newScopes);
        setShowPairingRecsSelect(false);
    };

    const pairingRecSelect =
        (<CBBSelect
            selectType='scopePairings'
            stylesType='small'
            value={null}
            setValue={handlePairingRec}
            placeholder='Quick Splits...'
            keeperOptions={selectOptions.map(row => row.value)}
        />);


    // ...and return!
    return (
        <div style={{ width, marginRight: 3, marginTop: 3, ...wrapperStyle, position: 'relative' }}>

            {/* Main Select Only */}
            {selectType !== 'games' && cbbMultiSelect}

            {/* Main Select + Extra: Select All button for games */}
            {selectType === 'games' &&
                <div style={{ display: 'flex' }}>
                    {/* Main Multi-Select */}
                    <div style={{ width: '100%' }}>
                        {cbbMultiSelect}
                    </div>
                    <div className='cbb-button' style={{ margin: '0px 0px 0px 3px', textAlign: 'center' }} onClick={() => setArray(selectOptions)}>
                        Select All
                    </div>
                </div>
            }

            {/* Extra: Selects Saver */}
            {optionSelectSaver &&
                <SelectsSaver
                    listType={optionSelectSaver}
                    selectedOptions={array}
                    setSelectedOptions={setArray}
                    buttonStyle={{ position: 'absolute', top: 0, left: 0 }}
                />
            }

            {/* Extra: Suggestions for MultiSelect */}
            {showPairingRecs && <>
                <div className='select-saver'>
                    <div
                        className='toggle-button'
                        style={{ position: 'absolute', top: 0, left: 0 }}
                        onClick={() => setShowPairingRecsSelect(!showPairingRecsSelect)}
                    />
                </div>
                {showPairingRecsSelect &&
                    <div style={{ position: 'absolute', top: -3 }}>
                        {pairingRecSelect}
                    </div>
                }
            </>}
        </div>
    );
}

export default CBBMultiSelect;
