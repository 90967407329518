
// ?? Cannot have imports in the same file as a module.export... Why is this?
// Instead of "module.export", using export default
import React from 'react';
import { dDict } from '../../../harddata/DataDictionary.js';
import { pppcQualifiersMap } from '../../../harddata/PppcQualifiers.js';
import { zoneIdxs } from '../../../harddata/ZoneSchemas';
import { heightAsString, secondsToClock } from '../../../utils/ReshapeData';
import { splitsObj } from '../../../harddata/splits';
import {
    sortRatedColumn,
    sortText,

    formatSeason,
    formatTextSpanLeft,
    formatOrdinaryColumn,
    formatValueRatingColumn,
    formatWorLColumn,
    formatStatColumn,
    formatPctileColumn,
    formatRankColumn,

    hyperlinkPlayerName,
    hyperlinkShortPlayerName,
    hyperlinkGameBox,
    hyperlinkTeamLogo,
    hyperlinkPlayerImage,
    hyperlinkTeamMarket,
    hyperlinkConferenceLongName,
    hyperlinkConferenceLogo,
    formatTextSpan
} from '../../../utils/TableHelpers';

// Think about splitting between (a) descriptive and (b) stat columns
import {
    TextSearchFilter,
    NumberRangeColumnFilter,
    NumberMinimumColumnFilter
} from './TableFilters';
import { allCompetitionIds } from '../../../harddata/NcaaStructures.js';


function createSbzZoneLabel(accessorKey) {
    let zoneMetric = accessorKey.includes('FgaFreq') ? 'FgaFreq'
        : (accessorKey.includes('FgPct') ? 'FgPct'
            : (accessorKey.includes('FgaP40') ? 'FgaP40'
                : (accessorKey.includes('FgaP100') ? 'FgaP100'
                    : (accessorKey.includes('FgaPg') ? 'FgaPg'
                        : (accessorKey.includes('Fga') ? 'Fga'
                            : (accessorKey.includes('Fgm') ? 'Fgm' : 'Hmmm'))))));

    let zoneName = JSON.parse(JSON.stringify(accessorKey)).replace(zoneMetric, '');
    let zoneLabel = dDict[zoneName] ? dDict[zoneName].label3 : 'BAD ZONE:';

    switch (zoneMetric) {
        case 'Fga': zoneLabel = zoneLabel + ' Total FGAs'; break;
        case 'Fgm': zoneLabel = zoneLabel + ' FG%'; break;
        case 'FgPct': zoneLabel = zoneLabel + ' FG%'; break;
        case 'FgaFreq': zoneLabel = zoneLabel + ' FGA%'; break;
        case 'FgaPg': zoneLabel = zoneLabel + ' FGA/G'; break;
        case 'FgaP40': zoneLabel = zoneLabel + ' FGA/40'; break;
        case 'FgaP100': zoneLabel = zoneLabel + ' FGA/100'; break;
        default: console.log('Error: Bad zoneMetric Value');
    }

    return zoneLabel;
}

// generateId :: Integer -> String
const dec2hex = (dec) => { return dec < 10 ? '0' + String(dec) : dec.toString(16); };
function generateId(len) {
    let arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return `randomId_${Array.from(arr, dec2hex).join('')}`;
}

const handlePinnedRow = ({ rowA, rowB, desc }) => {
    // pin locations: 1 (top), 2 (top, 2nd row), -1 (bottom), -2 (bottom, 2nd-last row)
    const isPinnedA = rowA.original.isPinned ? true : false;
    const pinLocationA = rowA.original.pinLocation;
    const isPinnedB = rowB.original.isPinned ? true : false;
    const pinLocationB = rowB.original.pinLocation;
    // console.log('pin stuff: ', { isPinnedA, pinLocationA, isPinnedB, pinLocationB });
    if (isPinnedA && !isPinnedB) { return desc ? pinLocationA : -pinLocationA; }
    if (!isPinnedA && isPinnedB) { return desc ? -pinLocationB : pinLocationB; }
    if (isPinnedA && isPinnedB) { return desc ? pinLocationA > pinLocationB : pinLocationA < pinLocationB; }
    return null;
};


// Create The Dictionary of Available Column Infos
let columnsDict = {
    // Meant for many ordinary, text-based columns
    textLabel: ({ acc, header, width, className, filter = false, clean = false, mapObj = null, tipTitle = null, tipDesc = null }) => {
        return {
            accessor: generateId(30),
            Header: header,
            width: width,
            minWidth: width,
            maxWidth: width,
            className: `text-left ${className ? className : ''}`,
            ...(filter === true && { Filter: TextSearchFilter }),
            ...(filter === true && { filter: 'fuzzyText' }),
            ...(tipTitle && { tipTitle: tipTitle }),
            ...(tipDesc && { tipDesc: tipDesc }),
            Cell: (row) => {
                const rowValue = row.row.original[acc];
                let rowText = (acc === 'netPts' & rowValue > 0) ? `+${rowValue}` : rowValue;
                if (clean === true) {
                    rowText = rowText.replace(/([A-Z])/g, ' $1');
                    rowText = rowText.charAt(0).toUpperCase() + rowText.slice(1);
                }

                if (mapObj !== null) {
                    rowText = mapObj[rowText];
                }

                return formatTextSpanLeft({ value: rowText });
            }
        };
    },

    // Hyperlinks for Player Full Names
    playerFullName: ({ idAcc, nameAcc, header, width, className, hideFilter = false }) => {
        return {
            // accessor: generateId(20),
            accessor: nameAcc,
            Header: header,
            width: width,
            minWidth: width,
            maxWidth: width,
            className: className && className,
            headerClass: 'text-left',
            filter: 'fuzzyText',
            ...(hideFilter !== true && { Filter: TextSearchFilter }),
            sortType: (rowA, rowB, desc) => {
                // handle pinned row
                const pinnedResult = handlePinnedRow({ rowA, rowB, desc });
                if (pinnedResult !== null) { return pinnedResult; }
                // else handle via normal sorting if no pinned row
                const valueA = rowA.original[nameAcc];
                const valueB = rowB.original[nameAcc];
                return sortText({ valueA, valueB });
            },
            disableColumnSelect: true,
            Cell: (row) => {
                let isPinned = row.row.original.isPinned;
                let playerId = row.row.original[idAcc];
                let playerName = row.row.original[nameAcc];
                let competitionId = row.row.original.competitionId ? row.row.original.competitionId : null;
                let style = isPinned ? { fontWeight: 700 } : {};
                return hyperlinkPlayerName({ playerId, playerName, competitionId, style });
            }
        };
    },

    // Hyperlinks for Player Abbreviated Names
    shortName: ({ idKey, nameKey, width = 55 }) => {
        return {
            Header: '',
            accessor: idKey,
            width: width || 55,
            minWidth: width,
            maxWidth: width + 10,
            className: 'short-name',
            Cell: (row) => {
                const playerId = row.row.original[idKey];
                const playerName = row.row.original[nameKey];
                return hyperlinkShortPlayerName({ playerId, playerName });
            }
        };
    },

    // Wowy Names are a string concatination of shortName()
    wowyNames: ({ width }) => {
        return {
            header: 'Players On/Off Court',
            accessor: generateId(30),
            width: width || 375,
            disableColumnSelect: true,
            Cell: (row) => {
                let { wowyOn, wowyOff } = row.row.original;
                // console.log('column: ', { width, wowyOn, wowyOff });
                let onSpans = wowyOn.map(player => hyperlinkShortPlayerName({ playerId: player.playerId, playerName: player.fullName }));
                let offSpans = wowyOff.map(player => hyperlinkShortPlayerName({ playerId: player.playerId, playerName: player.fullName }));
                // console.log('wowy On / Off: ', { wowyOn, wowyOff });
                return (<div style={{ display: 'flex', padding: '0 5px' }}>
                    <span style={{ paddingRight: 5, fontWeight: 700, fontSize: '1.1em' }}>{onSpans.length} On:</span>{onSpans}
                    <span style={{ padding: '0 5px 0 10px', fontWeight: 700, fontSize: '1.1em' }}>{offSpans.length} Off:</span>{offSpans}
                </div>);
            }
        };
    },

    // Hyperlinks
    dayOfWeek: {
        Header: 'Day',
        accessor: generateId(30),
        width: 35,
        Cell: row => {
            const gameDate = row.row.original.gameDate;
            const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
            const date = new Date(gameDate);
            return formatOrdinaryColumn({ value: days[date.getUTCDay()] });
        }
    },
    gameDate: {
        Header: 'Date',
        accessor: 'gameDate',
        width: 85,
        minWidth: 85,
        maxWidth: 85,
        sortType: (rowA, rowB, colId, desc) => {
            // console.log('rowA, rowB: ', rowA, rowB);
            if (rowA.original.isPinned) { return desc ? 1 : -1; }
            if (rowB.original.isPinned) { return desc ? -1 : 1; }
            return rowA.original.gameDate > rowB.original.gameDate ? 1 : -1;
        },
        Cell: row => {
            return row.row.original.isPinned
                ? <span style={{ marginLeft: 15, fontWeight: 700 }}>Per Game:</span>
                : formatOrdinaryColumn({ value: row.value });
        }
    },
    gameBox: {
        Header: '',
        accessor: generateId(30),
        width: 35,
        minWidth: 35,
        maxWidth: 35,
        Cell: row => {
            if (row.row.original.isPinned === true) { return ''; }
            return hyperlinkGameBox({ gameId: row.row.original.gameId });
        }
    },

    // hyperlink'd team logo in circle
    teamLogo: ({ idKey, className, width = 33 }) => {
        return {
            Header: '',
            accessor: generateId(30), // don't set acc since team logo can appear twice in same table? (cant have same accessor twice in table)
            width: width || 33,
            minWidth: width || 33,
            maxWidth: width || 33,
            className: className ? className : '',
            disableColumnSelect: true,
            Cell: row => {
                let teamId = row.row.original[idKey];
                let competitionId = row.row.original.competitionId ? row.row.original.competitionId : null;
                return hyperlinkTeamLogo({ teamId, competitionId });
            }
        };
    },

    // hyperlink'd player image in circle
    playerImage: ({ playerIdKey, hasImageKey = 'hasImage', className }) => {
        return {
            Header: '',
            accessor: generateId(30),
            width: 30,
            minWidth: 30,
            maxWidth: 30,
            className: className ? className : '',
            disableColumnSelect: true,
            Cell: row => {
                let playerId = row.row.original.playerId || row.row.original[playerIdKey];
                let teamId = row.row.original.teamId;
                let competitionId = row.row.original.competitionId;
                let hasImage = row.row.original[hasImageKey] || false;
                return hyperlinkPlayerImage({ playerId, teamId, competitionId, hasImage });
            }
        };
    },

    // hyperlink'd player image in circle
    conferenceLogo: ({ idKey }) => {
        return {
            Header: '',
            accessor: `conference-logo-${idKey}`, // ?? what is this
            width: 35,
            minWidth: 35,
            maxWidth: 35,
            headerClassName: 'conf-logo', // not 100% sure
            className: 'conf-logo', // if these are needed
            Cell: row => {
                let conferenceId = row.row.original[idKey];
                let competitionId = row.row.original.competitionId ? row.row.original.competitionId : null;
                return hyperlinkConferenceLogo({ conferenceId, competitionId });
            }
        };
    },

    // hyperlink'd team market name
    teamMarket: ({ acc, acc2, header, width, className, filter = false }) => {
        // optional fallback second accessor if first one has '' or null. using for verbal-commits team market vs genius team market
        return {
            Header: header || '',
            accessor: acc,
            width: width || 110,
            minWidth: width || 110,
            maxWidth: width + 20 || 130,
            headerClass: `text-left`,
            className: className || '',
            ...(filter === true && { Filter: TextSearchFilter }),
            ...(filter === true && { filter: 'fuzzyText' }),
            disableColumnSelect: true,
            Cell: row => {
                let teamMarket = row.value || row.row.original[acc2] || '';
                let { teamId, competitionId } = row.row.original;
                return hyperlinkTeamMarket({ teamId, teamMarket, competitionId });
            }
        };
    },

    // hyperlink'd conference long name
    conferenceLongName: ({ header, width, className, filter = false }) => {
        return {
            Header: header || 'Conference Name',
            accessor: generateId(30),
            width: width || 260,
            minWidth: width || 260,
            maxWidth: width + 30 || 280,
            className: className || '',
            headerClass: 'text-left',
            ...(filter === true && { Filter: TextSearchFilter }),
            ...(filter === true && { filter: 'fuzzyText' }),
            disableColumnSelect: true,
            Cell: row => {
                let { conferenceLongName, conferenceId, competitionId } = row.row.original;
                return hyperlinkConferenceLongName({ conferenceId, conferenceLongName, competitionId });
            }
        };
    },

    // hyperlink'd team market (diff accessor)
    marketAway: ({ className }) => {
        return {
            Header: 'Away Team',
            accessor: 'teamMarketAway',
            width: 110,
            filter: 'fuzzytext',
            className: className,
            Cell: row => {
                const teamId = row.row.original.teamIdAway;
                const teamMarket = row.row.original.teamMarketAway;
                return hyperlinkTeamMarket({ teamId, teamMarket });
            }
        };
    },

    // blank column!
    blankCol: ({ width = 10, className = '' }) => {
        return {
            accessor: generateId(30),
            Header: '',
            width: width,
            minWidth: width,
            maxWidth: width,
            className: `blank-col ${className}`,
            Cell: () => { return <div />; }
        };
    },
    pbpShotInfo: ({ width, header, infoType = 'long' }) => {
        return {
            accessor: generateId(30),
            Header: header || 'Shot Info',
            width: width,
            headerClass: 'text-left',
            Cell: row => {
                let { fullName, actionType, subType, success, assisterId, assisterName } = row.row.original;
                let actionString = '';

                if (infoType === 'short') {
                    let shotType = actionType === 'freethrow' ? 'FT' : actionType === '2pt' ? '2P' : actionType === '3pt' ? '3P' : actionType;
                    actionString = `${success ? 'Made' : 'Missed'} ${shotType}`;
                } else {
                    actionString = `${fullName} ${success ? 'made' : 'missed'} ${actionType}`;
                    if (subType) { actionString = `${actionString} (${subType})`; }
                    if (assisterId) { actionString = `${actionString}. Assisted by ${assisterName}.`; }
                }

                // and return!
                return formatTextSpanLeft({ value: actionString });
            }
        };
    },
    scope: () => {
        return {
            Header: 'Split',
            accessor: 'scope',
            width: 95,
            minWidth: 95,
            maxWidth: 95,
            headerClass: `text-left`,
            Cell: row => {
                const splitObject = splitsObj[row.value];
                const displayValue = splitObject.label2;
                return formatTextSpanLeft({ value: displayValue });
            }
        };
    },
    divisionId: () => {
        return {
            Header: 'D',
            accessor: 'divisionId',
            width: 20,
            minWidth: 20,
            maxWidth: 20,
            filter: 'fuzzyText',
            Filter: TextSearchFilter,
            tipTitle: 'Division',
            tipDesc: 'Division (1, 2, 3) of the player or team.',
            Cell: row => {
                return formatOrdinaryColumn({ value: row.value });
            }
        };
    },
    season: ({ acc, header = 'Year', width = 45 }) => {
        // could add size: sm, md, lg for '18-19', '2018-19', '2018-2019', could add gender. done elsewhere
        return {
            Header: header,
            accessor: acc, // point towards field with competitionId values,
            width: width,
            minWidth: width,
            maxWidth: width,
            sortType: (rowA, rowB, desc) => {
                const pinnedResult = handlePinnedRow({ rowA, rowB, desc });

                if (rowA?.original?.[acc] === 'career') { return 1; }
                if (rowB?.original?.[acc] === 'career') { return -1; }
                if (pinnedResult !== null) { return pinnedResult; }
                return null;
            },
            Cell: row => {
                let competitionId = row.value; // field at row[acc]
                let text = '';
                switch (competitionId) {
                    case 'career': text = 'Career'; break;
                    case 22850: text = '18-19'; break;
                    case 22853: text = '18-19'; break;
                    case 24996: text = '19-20'; break;
                    case 24997: text = '19-20'; break;
                    case 27693: text = '20-21'; break;
                    case 27694: text = '20-21'; break;
                    case 30629: text = '21-22'; break;
                    case 30630: text = '21-22'; break;
                    case 33533: text = '22-23'; break;
                    case 33535: text = '22-23'; break;
                    case 36046: text = '23-24'; break;
                    case 36045: text = '23-24'; break;
                    default: text = '';
                }

                // bold if pinned, and return
                let style = row?.row?.original?.isPinned ? { fontWeight: 700 } : {};
                return formatSeason({ seasonString: text, style });
            }
        };
    },

    winOrLoss: (ptsScoredKey, ptsAgstKey) => {
        return {
            Header: '',
            accessor: generateId(30),
            width: 20,
            minWidth: 20,
            maxWidth: 20,
            Cell: (row) => {
                if (row.row.original.isPinned === true) { return ''; }
                const teamPoints = row.row.original[ptsScoredKey];
                const oppPoints = row.row.original[ptsAgstKey];
                return formatWorLColumn({ teamPoints, oppPoints });
            }
        };
    },
    winLossRecord: (className) => {
        return {
            Header: 'W-L (Conf)',
            accessor: generateId(30),
            width: 88,
            minWidth: 88,
            maxWidth: 88,
            className: className ? className : '',
            Cell: row => {
                let { overallWins, overallLosses, confWins, confLosses } = row.row.original;
                let textLabel = `${overallWins}-${overallLosses} (${confWins}-${confLosses})`;
                textLabel = (typeof overallWins === 'undefined' || typeof overallLosses === 'undefined') ? '' : textLabel; // replaced undefined-undefined with empty str
                return formatTextSpanLeft({ value: textLabel });
            }
        };
    },
    onOffDiff: () => {
        return {
            Header: '',
            accessor: 'onOffDiff', // accessor doesnt matter here
            width: 32,
            minWidth: 32,
            maxWidth: 32,
            Cell: row => {
                const displayValue = row.value.charAt(0).toUpperCase() + row.value.slice(1);
                return formatOrdinaryColumn({ value: displayValue });
            }
        };
    },
    isHome: () => {
        return {
            Header: ' ',
            accessor: 'isHome',
            width: 24,
            minWidth: 24,
            maxWidth: 24,
            tipTitle: 'Game Location',
            tipDesc: 'Location of the game for the team, one of home (vs), away (@), or neutral (-)',
            Cell: row => {
                let { isNeutral, isPinned } = row.row.original;
                if (isPinned === true) { return ''; }
                let displayValue = isNeutral ? 'n' : (row.value === true ? 'vs' : '@');
                return formatOrdinaryColumn({ value: displayValue });
            }
        };
    },
    position: () => {
        return {
            Header: 'Pos',
            accessor: 'position',
            width: 27,
            minWidth: 27,
            maxWidth: 32,
            filter: 'fuzzyText',
            Filter: TextSearchFilter,
            tipTitle: 'Player Position',
            tipDesc: 'One of G (guard) or F (forward, center). Positions favor guards (60% of all players), then forwards (30%), with few centers (5%) and missing positions (5%). Due to the very low presense of players labeled as Centers, we have combined centers with forwards.',
            sortType: (rowA, rowB, desc) => {
                const pinnedResult = handlePinnedRow({ rowA, rowB, desc });
                return pinnedResult !== null ? pinnedResult : null;
            },
            Cell: row => formatOrdinaryColumn({ value: row.value })
        };
    },
    latestClass: () => {
        return {
            Header: 'Yr',
            accessor: 'latestClass',
            width: 30,
            minWidth: 30,
            maxWidth: 34,
            filter: 'fuzzyText',
            Filter: TextSearchFilter,
            tipTitle: 'Latest Class Year',
            tipDesc: 'One of Fr (freshman), So (sophomore), Jr (junior), Sr (seniors, 5th year). Seniors and 5th-year players are unfortunately both labeled as "Sr" in the raw data from the NCAA.',
            Cell: row => {
                let displayValue = '';
                switch (row.value) {
                    case 'Freshman': displayValue = 'Fr'; break;
                    case 'Sophomore': displayValue = 'So'; break;
                    case 'Junior': displayValue = 'Jr'; break;
                    case 'Senior': displayValue = 'Sr'; break;
                    default: displayValue = '';
                }
                return formatOrdinaryColumn({ value: displayValue });
            }
        };
    },
    jerseyNum: () => {
        return {
            Header: 'J#',
            accessor: 'jerseyNum',
            width: 30,
            minWidth: 30,
            maxWidth: 35,
            Cell: row => {
                return row.value !== null && typeof row.value !== 'undefined'
                    ? formatOrdinaryColumn({ value: `#${row.value}` })
                    : '';
            }
        };
    },
    classYr: () => {
        const classYearMap = { sr: 'Senior', jr: 'Junior', so: 'Sophomore', fr: 'Freshman' };
        return {
            Header: 'Yr',
            accessor: 'classYr',
            width: 27,
            minWidth: 27,
            maxWidth: 32,
            Filter: TextSearchFilter,
            filter: (rows, id, filterValue) => {
                // typing in 's', 'sr', 'se', 'senio', 'Sr', 'sEnI', etc. all return true for Senior, Sr

                // lowercase of whatever it typed in search/filter box
                const lowerFilterValue = filterValue.toLowerCase();

                // If the filter value exactly matches one of the abbreviations, use the full term for filtering
                const fullTerm = classYearMap[lowerFilterValue] ? classYearMap[lowerFilterValue].toLowerCase() : lowerFilterValue.toLowerCase();

                // and return keeper rows
                return rows.filter(row => {
                    const rowValue = row.values[id]?.toLowerCase() || ' ';
                    return rowValue.startsWith(fullTerm);
                });
            },
            tipTitle: 'Class Year',
            tipDesc: `One of Freshman (Fr), Sophomore (So), Junior (Jr) or Senior (Sr). 5th Year Players and Grad Transfers are marked as Seniors in the raw data. No "redshirt" designation for players in the raw data. Please report any incorrectly listed player class years.`,
            // headerClass: `text-left`,
            sortType: (rowA, rowB, desc) => {
                const pinnedResult = handlePinnedRow({ rowA, rowB, desc });
                return pinnedResult !== null ? pinnedResult : null;
            },
            Cell: row => {
                const classYr = row?.value || '';
                const displayValue = classYr === 'Senior' ? 'Sr' : classYr === 'Junior' ? 'Jr' : classYr === 'Sophomore' ? 'So' : classYr === 'Freshman' ? 'Fr' : '';
                // const displayValue = row.value === 'Senior' ? 'Sr' : row.value === 'Junior' ? 'Jr' : row.value === 'Sophomore' ? 'So' : row.value === 'Freshman' ? 'Fr' : '';
                return formatOrdinaryColumn({ value: displayValue });
            }
            // Cell: row => formatOrdinaryColumn({ value: heightAsString({ height: row.value }) })
        };
    },
    height: () => {
        return {
            Header: 'Ht',
            accessor: 'height',
            width: 31,
            minWidth: 31,
            maxWidth: 36,
            Filter: NumberMinimumColumnFilter,
            filter: 'between',
            tipTitle: 'Player Height',
            tipDesc: `Use inches for in-table minimum height filter. Note that height is not listed for all players.`,
            sortType: (rowA, rowB, desc) => {
                const pinnedResult = handlePinnedRow({ rowA, rowB, desc });
                return pinnedResult !== null ? pinnedResult : null;
            },
            Cell: row => formatOrdinaryColumn({ value: heightAsString({ height: row.value }) })
        };
    },
    pppcQualifiers: (possOrChnc) => {
        let thisClass = possOrChnc === 'poss' ? 'ppp-qualifiers' : 'ppc-qualifiers';
        let pppcQualifiersDict = pppcQualifiersMap(possOrChnc);
        return {
            Header: 'Qualifier',
            accessor: 'qualifier',
            className: `row-tooltip ${thisClass} text-left`,
            width: 93,
            disableColumnSelect: true,
            sortType: (rowA, rowB) => {
                let valueA = pppcQualifiersDict[rowA.original.qualifier].order;
                let valueB = pppcQualifiersDict[rowB.original.qualifier].order;
                return sortText({ valueA, valueB });
            },
            Cell: row => {
                let { qualifier } = row.row.original;
                let qualifierType = pppcQualifiersDict[qualifier].key;
                return formatTextSpanLeft({ value: qualifierType });
            }
        };
    },
    portalDate: ({ acc, header, width }) => {
        return {
            Header: header || '',
            accessor: acc,
            width: width || 100,
            minWidth: width || 100,
            maxWidth: width + 20 || 120,
            sortType: (rowA, rowB) => { // table shouldnt need zoneIdx, that should be HERE
                let aSort = rowA?.original?.[acc] || null;
                let bSort = rowB?.original?.[acc] || null;
                return new Date(aSort) - new Date(bSort);
            },
            Cell: row => {
                // .split(' ')?.[0] assumes format of "2024-02-28 06:52:54 UTC", where it is YYYY-MM-DD SPACE anythingelse
                const dateString = row.value?.split(' ')?.[0] ?? ' -- ';
                return formatOrdinaryColumn({ value: dateString });
            }
        };
    },

    // For Tweets Table
    handle: () => {
        return {
            Header: 'Handle',
            accessor: 'screen_name',
            width: 125,
            minWidth: 125,
            maxWidth: 140,
            Cell: row => formatTextSpanLeft({ value: `@${row.value}` })
        };
    },

    // for secsIntoGame (1793) => clock (H2 10:07) conversions
    secondsToClock: ({ acc = 'secsIn', header = 'Clock', clockType = 'secsLeft', width, tipTitle, tipDesc }) => {
        return {
            Header: header || 'Clock',
            accessor: generateId(30),
            width: width || 85,
            minWidth: width || 85,
            maxWidth: width + 15 || 100,
            headerClass: 'text-left',
            ...(tipTitle && { tipTitle: tipTitle }),
            ...(tipDesc && { tipDesc: tipDesc }),
            Cell: row => {
                const secsIn = row.row.original[acc] || 0;
                const competitionId = row.row.original.competitionId; // requires competitionId field
                const gender = allCompetitionIds.male.includes(competitionId) ? 'MALE' : 'FEMALE';
                const value = secondsToClock({ secsIn, gender, clockType });
                return formatTextSpanLeft({ value: value });
            }
        };
    },

    didTeamWin: ({ teamAcc = 'teamScore', oppoAcc = 'teamScoreAgst', header, width, className }) => {
        return {
            Header: header || 'Clock',
            accessor: generateId(30),
            className: className || '',
            width: width || 40,
            minWidth: width || 40,
            maxWidth: width + 15 || 55,
            headerClass: 'text-left',
            Cell: row => {
                const teamScore = row.row.original[teamAcc] || null;
                const oppoScore = row.row.original[oppoAcc] || null;
                const outputText = teamScore === null || oppoScore === null ? 'Nada' : teamScore > oppoScore ? 'Win' : 'Loss';
                return formatTextSpanLeft({ value: outputText });
            }
        };
    },

    toOt: ({ header = 'OT', width, className }) => {
        return {
            Header: header || 'OT',
            accessor: 'toOt',
            className: className || '',
            width: width || 30,
            minWidth: width || 30,
            maxWidth: width + 10 || 40,
            headerClass: 'text-left',
            Cell: row => {
                const toOt = row.row?.original?.toOt || null;
                const outputText = toOt ? 'Yes' : 'No';
                return formatTextSpanLeft({ value: outputText });
            }
        };
    },

    shotSituation: ({ acc = 'shotSituation', header = 'Situation', className, width, filter = false }) => {
        return {
            Header: header || 'Clock',
            accessor: generateId(30),
            className: className || '',
            width: width || 100,
            minWidth: width || 100,
            maxWidth: width + 15 || 115,
            headerClass: 'text-left',
            // ...(filter === true && { Filter: TextSearchFilter }),
            // ...(filter === true && { filter: 'fuzzyText' }),
            Cell: row => {
                let outputText = '';
                const shotSituation = row.row.original[acc] || 'whocares';
                switch (shotSituation) {
                    case 'goahead1': outputText = 'Go Ahead FT'; break;
                    case 'goahead2': outputText = 'Go Ahead 2P'; break;
                    case 'goahead3': outputText = 'Go Ahead 3P'; break;
                    case 'tying1': outputText = 'Game Tying FT'; break;
                    case 'tying2': outputText = 'Game Tying 2P'; break;
                    case 'tying3': outputText = 'Game Tying 3P'; break;
                    default: outputText = '';
                }

                return formatTextSpanLeft({ value: outputText });
            }
        };
    },

    pbpSuccess: ({ acc = 'success', header = 'Result', width = 75 }) => {
        return {
            Header: header || 'Clock',
            accessor: generateId(30),
            width: width || 100,
            minWidth: width || 100,
            maxWidth: width + 15 || 115,
            Cell: row => {
                let outputText = '';
                const shotSituation = row.row.original[acc] || 'whocares';
                switch (shotSituation) {
                    case 'goahead1': outputText = 'Go Ahead FT'; break;
                    case 'goahead2': outputText = 'Go Ahead 2P'; break;
                    case 'goahead3': outputText = 'Go Ahead 3P'; break;
                    case 'tying1': outputText = 'Game Tying FT'; break;
                    case 'tying2': outputText = 'Game Tying 2P'; break;
                    case 'tying3': outputText = 'Game Tying 3P'; break;
                    default: outputText = '';
                }

                return formatTextSpanLeft({ value: outputText });
            }
        };
    },

    shotDist: ({ acc = 'shotDist', header = 'Dist', width = 48 }) => {
        return {
            Header: header,
            accessor: generateId(30),
            width: width,
            minWidth: width,
            maxWidth: width + 10,
            headerClass: 'text-left',
            Cell: row => {
                let outputText = row.row.original[acc];
                outputText = `${Math.round(outputText * 10) / 10} ft`;
                outputText = row.row.original.actionType === 'freethrow' ? '--' : outputText;
                return formatTextSpanLeft({ value: outputText });
            }
        };
    }
};


// D) Add Columns For Long Tables
// ==============================
columnsDict.metricValue = ({ acc, header, width, tipTitle, tipDesc, className, filter = null, randomAcc = false }) => {
    const keyInfo = dDict[acc];
    const tooltipTitle = keyInfo?.tips?.tipTitle || tipTitle;
    const tooltipDescription = keyInfo?.tips?.mainTip || tipDesc;
    const metricUnit = keyInfo?.unit || 'pct';

    return {
        accessor: randomAcc ? generateId(40) : acc,
        Header: keyInfo?.label1 || header || 'YOOO',
        minWidth: width || 40,
        maxWidth: width + 10 || 50,
        ...(className && { className: className }), // className: 'statValue',
        ...(tooltipTitle && { tipTitle: tooltipTitle }),
        ...(tooltipDescription && { tipDesc: tooltipDescription }),
        ...(filter === 'min' && { Filter: NumberMinimumColumnFilter }),
        ...(filter === 'range' && { Filter: NumberRangeColumnFilter }),
        ...(filter && { filter: 'between' }),
        sortType: (rowA, rowB, colId, desc) => { return sortRatedColumn({ rowA, rowB, colId: acc, desc }); },
        Cell: row => {
            let thisValue = row.row.original[acc];
            let thisUnit = row.row.original?.unit || metricUnit;
            return formatStatColumn({ stat: thisValue, unit: thisUnit });
        }
    };
};


columnsDict.statLabel = ({ header, className }) => ({
    Header: header, // || 'Statistcs',
    className: `text-left ${className && className}`,
    accessor: 'key',
    minWidth: 110,
    maxWidth: 130,
    Cell: row => {
        let textLabel = dDict[row.value] ? dDict[row.value].label3 : 'Oh boy...';
        return formatTextSpanLeft({ value: textLabel });
    }
});
columnsDict.statLabel1 = {
    Header: '',
    accessor: 'key',
    width: 50,
    Cell: row => {
        let statHasInfo = dDict[row.value] ? true : false;
        let textLabel = statHasInfo ? dDict[row.value].label1 : createSbzZoneLabel(row.value);
        return formatTextSpanLeft({ value: textLabel });
    }
};
columnsDict.statLabel2 = ({ header = '', width, className, spanClass = 'left' }) => {
    return {
        Header: header,
        accessor: 'key',
        className: `row-tooltip statLabel2 right-grey ${className}`,
        minWidth: width || 110, // 100,
        maxWidth: width + 20 || 130,
        Cell: row => {
            let statHasInfo = dDict[row.value] ? true : false;
            let isAgstStat = row.value && row.value.slice(-4) === 'Agst' ? true : false;
            let agstHasInfo = isAgstStat && dDict[row.value.slice(0, -4)] ? true : false;
            let textLabel = '';
            if (!statHasInfo && isAgstStat && agstHasInfo) {
                textLabel = `${dDict[row.value.slice(0, -4)].label3} Agst`;
            } else {
                textLabel = statHasInfo ? dDict[row.value].label3 : createSbzZoneLabel(row.value);
            }

            return formatTextSpan({ value: textLabel, className: spanClass });
            // return formatOrdinaryColumn({ value: textLabel })
            // return formatTextSpanLeft({ value: textLabel });
        }
    };
};
columnsDict.zoneName = ({ acc = 'zoneName', width, header, sticky = false, className, filter = false, plural = true }) => {
    return {
        Header: header ? header : 'Court Region',
        className: `text-left ${className && className}`, // margin-left: 18px; for child div to pad for new in-table (?) button
        ...(sticky === true && { sticky: 'left' }),
        accessor: acc,
        width: width ? width : 135,
        // ...(filter === true && { Filter: TextSearchFilter }),
        // ...(filter === true && { filter: 'fuzzyText' }),
        sortType: (rowA, rowB) => { // table shouldnt need zoneIdx, that should be HERE
            let valueA = rowA && rowA.values && rowA.values.zoneName ? zoneIdxs[rowA.values.zoneName] : 1;
            let valueB = rowB && rowB.values && rowB.values.zoneName ? zoneIdxs[rowB.values.zoneName] : 1;
            return sortText({ valueA, valueB });
        },
        Cell: row => {
            // set zone name
            let zoneName = dDict[row.value] ? dDict[row.value].label3 : 'Zone...';

            // remove plural (s) from end
            if (plural === false && zoneName.endsWith('s')) { zoneName = zoneName.slice(0, -1); }

            // handle if Free Throw
            if (row.row.original.actionType === 'freethrow') { zoneName = 'Free Throw Line'; }

            // and return
            return formatTextSpanLeft({ value: zoneName });
        }
    };
};
columnsDict.statValue = (acc, header = 'Value') => {
    return {
        accessor: generateId(40),
        Header: header,
        minWidth: 42,
        maxWidth: 49,
        className: 'statValue',
        Cell: row => {
            // console.log('row: ', { acc, row });
            let thisValue = row.row.original[acc];
            let thisStatKey = row.row.original.key;
            let thisUnit = row.row.original.unit ? row.row.original.unit :
                (dDict[thisStatKey] ? dDict[thisStatKey].unit : 'pct');

            return formatStatColumn({ stat: thisValue, unit: thisUnit });
        }
    };
};
columnsDict.statRating = (acc, header = '%ile') => {
    return {
        // accessor: acc,
        accessor: generateId(40),
        Header: header,
        minWidth: 27,
        maxWidth: 34,
        className: 'statRating',
        Cell: row => {
            let ratingText = row.row.original[acc];
            return formatPctileColumn({ pctile: ratingText });
        }
    };
};
columnsDict.statRank = ({ acc, header = 'Rank', width, maxRank = null }) => {
    return {
        accessor: acc,
        Header: header,
        minWidth: width || 27,
        maxWidth: width + 5 || 32,
        className: 'statRating',
        Cell: row => {
            let thisMaxRank = maxRank || row.row.original.maxRank;
            let rankType = row.row.original.rankType || 'division';
            return formatRankColumn({ rank: row.value, maxRank: thisMaxRank, rankType });
        }
    };
};

columnsDict.textField = (text, width) => {
    return {
        Header: '',
        accessor: generateId(30),
        width: width,
        minWidth: width || 15,
        maxWidth: width + 15 || 30,
        Cell: () => formatOrdinaryColumn({ value: text })
    };
};

columnsDict.teamRecord = (winAcc, lossAcc, header, width = 38) => {
    return {
        Header: header || '',
        accessor: winAcc, // generateId(30)
        width: width,
        minWidth: width,
        maxWidth: width + 5,
        disableColumnSelect: true,
        sortType: (rowA, rowB) => rowA.original[winAcc] > rowB.original[winAcc] ? 1 : -1,
        Cell: (row) => { // keep 0, but replace null & undefied
            let winText = row.row.original[winAcc] === 0 ? 0 : (row.row.original[winAcc] || '');
            let lossText = row.row.original[lossAcc] === 0 ? 0 : (row.row.original[lossAcc] || '');
            return formatOrdinaryColumn({ value: `${winText}-${lossText}` });
        }
    };
};


columnsDict.pValue = ({ width }) => {
    return {
        Header: 'Pr(>|t|)',
        accessor: 'pValue',
        width: width,
        maxWidth: width + 10,
        tipTitle: dDict.pValue.tips.tipTitle,
        tipDesc: dDict.pValue.tips.mainTip,
        Cell: row => {
            let displayText = '';
            if (row.value < 0.001) { displayText = `<0.001 ***`;
            } else if (row.value < 0.01) { displayText = `${row.value.toFixed(3)} **`;
            } else if (row.value < 0.05) { displayText = `${row.value.toFixed(3)} *`;
            } else if (row.value < 0.1) { displayText = `${row.value.toFixed(3)} .`;
            } else { displayText = row.value.toFixed(3); }
            return (
                <span className='text-span left'>
                    {displayText}
                </span>
            );
        }
    };
};


// For Player Game Stats, columns for the fgm/fga ratios
columnsDict.fgmFga = ({ fgmKey, fgaKey, header, width, showPct = false, className }) => {
    return {
        Header: header || 'FGM/A',
        accessor: generateId(30),
        width: width || 55,
        className: className || '',
        Cell: row => {
            let rowObject = row.row.original;
            let num = rowObject[fgmKey];
            let denom = rowObject[fgaKey];

            return (rowObject.isPinned && rowObject.pinType === 'allPcts')
                ? <span className='text-span'>{`${Math.round((num / denom) * 1000) / 10}%`}</span>
                : <span className='text-span'>{num}/{denom}</span>;
        }
    };
};
columnsDict.lineupFlowPeriod = {
    Header: 'P',
    accessor: generateId(30),
    width: 25,
    Cell: row => {
        let { pStart, pEnd } = row.row.original;
        let displayText = pStart === pEnd ? pStart : `${pStart},${pEnd}`;
        return formatOrdinaryColumn({ value: displayText });
    }
};
columnsDict.period = ({ header = 'P', width = 24 }) => {
    return {
        Header: header,
        accessor: 'period',
        className: `text-left`,
        minWidth: width,
        maxWidth: width + 10,
        sortType: (rowA, rowB) => { // table shouldnt need zoneIdx, that should be HERE
            const { period: aPeriod, periodNumber: aPeriodNumber } = rowA.original;
            const { period: bPeriod, periodNumber: bPeriodNumber } = rowB.original;
            const valueA = `${aPeriod}${aPeriodNumber}`;
            const valueB = `${bPeriod}${bPeriodNumber}`;
            return sortText({ valueA, valueB });
        },
        Cell: row => {
            let { period, periodNumber } = row.row.original;
            let displayText = period.toUpperCase();
            if (displayText === 'OT') { displayText = `${displayText}${periodNumber}`; }
            return formatTextSpanLeft({ value: displayText });
        }
    };
};


// New Functions That Are Hopefully Going To Make All Code Above This Obsolete
columnsDict.valueAndRating = (accessorKey, config = {}) => {
    // console.log('valueAndRating params: ', { accessorKey, config });
    // Extract from config (this is better than individual params passed)
    let keyToUse = accessorKey;
    let ptgc = config && typeof config.ptgc !== 'undefined' ? config.ptgc : 'team';
    let isOffense = config && typeof config.isOffense !== 'undefined' ? config.isOffense : true;
    let newHeader = config && typeof config.header !== 'undefined' ? config.header : null;
    let { ratingsType = 'pctiles' } = config; // one of ['pctiles', 'ranks']
    // console.log('ratingsType: ', ratingsType);
    let isPgP40P100 = accessorKey.slice(-2) === 'Pg' || accessorKey.slice(-3) === 'P40' || accessorKey.slice(-4) === 'P100';
    let columnWidth = config.width ? config.width : 80;
    let extra5 = config.className && config.className.includes('pad-left-5') ? 5 : 0;
    let extra10 = config.className && config.className.includes('pad-left-10') ? 10 : 0;
    let extra15 = config.className && config.className.includes('pad-left-15') ? 15 : 0;
    columnWidth = columnWidth + extra5 + extra10 + extra15;

    // Set Column Header Tooltip
    let tooltipKey = config.baseKey ? config.baseKey : keyToUse;
    let statInfo = dDict[tooltipKey];
    let tipExists = statInfo && statInfo.tips;
    let ptgcTooltipKey = ptgc === 'team' ? 'teamTip' : 'playerTip';
    let tipTitle = tipExists ? statInfo.tips.tipTitle : 'Stat Tooltip Header';
    let columnUnit = config && config.unit ? config.unit : (tipExists ? statInfo.unit : 'pct');

    // Column Header Text (in <></> to handle subscript for adjusted stats)
    let columnHeader = newHeader ? newHeader : (tipExists ? statInfo.label1 : 'HMMM');
    columnHeader = config && config.logoTeamId ? hyperlinkTeamLogo({ teamId: config.logoTeamId }) : columnHeader; // add logo into header if "logoTeamId" exists
    if (columnHeader.includes('**')) { // we use ** in data dictionary to flag for superscripts
        const parts = columnHeader.split('**');
        columnHeader = <>{parts[0]}<sup>{parts[1]}</sup></>;
    }

    // Set tooltip description
    let tipDesc = tipExists ? (statInfo.tips.mainTip ? statInfo.tips.mainTip : statInfo.tips[ptgcTooltipKey]) : 'Stat Tooltip Body'; // grab in 2 places, or use fallback
    tipDesc = tipDesc || ''; // handle null w/ empty string
    tipDesc = !isPgP40P100 ? tipDesc : tipDesc.endsWith('.') ? tipDesc.slice(0, -1) + ` by the ${ptgc}.` : tipDesc; // add by the team / by the player at the end of pg/p40/p100 stats.
    tipDesc = isOffense ? tipDesc : (!tipDesc ? '' : tipDesc.replace('opponent', 'zzzed').replace('team', 'opponent').replace('zzzed', 'team'));
    tipTitle = isOffense ? tipTitle : `Opponent's ${tipTitle}`;

    // And Return
    let ratingKey = ratingsType === 'pctiles' ? `${keyToUse}Pctile` : `${keyToUse}Rank`;
    return {
        className: config.className ? config.className : '',
        accessor: keyToUse,
        Header: columnHeader,
        width: columnWidth,
        minWidth: columnWidth,
        maxWidth: columnWidth + 20,
        ...(config.hideFilter !== true && { Filter: NumberRangeColumnFilter }),
        ...(config.hideFilter !== true && { filter: 'between' }),
        ...(config.hideTip !== true && { tipTitle: tipTitle }),
        ...(config.hideTip !== true && { tipDesc: tipDesc }),
        sortType: (rowA, rowB, colId, desc) => { return sortRatedColumn({ rowA, rowB, colId, desc }); },
        disableColumnSelect: config.disableHide ? true : false,
        Cell: row => {
            let rowObject = row.row.original;
            let showPlus = ['netRtg', 'netppp'].includes(accessorKey) || (rowObject.onOffDiff === 'diff') ? true : false;
            let rating = rowObject[ratingKey];
            let maxRank = rowObject?.maxRank || null;
            let statObj = { value: row.value, rating, ratingsType, maxRank };

            // getting correct unit (pinned rows makes this tricky)
            let pinnedUnit = columnUnit;
            pinnedUnit = rowObject.pinType === 'allPcts' ? 'pct0' : pinnedUnit; // allPcts === entire row is 0-decimal percentages
            pinnedUnit = rowObject.pinType === 'totals' && ['int', 'num'].includes(columnUnit) ? 'int' : columnUnit;
            pinnedUnit = rowObject.pinType === 'intToNum' && columnUnit === 'int' ? 'num' : pinnedUnit;
            let finalUnit = rowObject.isPinned ? pinnedUnit : columnUnit;
            // console.log('valueAndRating pinInfo: ', { rowObject, keyToUse, pinType: rowObject.pinType, columnUnit, finalUnit });

            // and return
            return formatValueRatingColumn({ statObj, unit: finalUnit, showPlus });
        }
    };
};

columnsDict.valueOnly = (accessorKey, config = {}) => {
    // console.log('valueOnly: ', { accessorKey, config });
    // Extract variables safely from 'config' object
    let ptgc = config.ptgc ? config.ptgc : 'team';
    let baseWidth = config.width ? config.width : 35;
    let extra10 = config.className && config.className.includes('pad-left-10') ? 10 : 0;
    let extra15 = config.className && config.className.includes('pad-left-15') ? 15 : 0;
    let width = baseWidth + extra10 + extra15;

    // Setup tooltip
    let baseKey = config.baseKey ? config.baseKey : accessorKey;
    let statHasInfo = dDict[baseKey] ? true : false;
    let statInfo = statHasInfo ? dDict[baseKey] : {};
    let tipTitle = statHasInfo ? statInfo.tips.tipTitle : null;
    // console.log('statHasInfo: ', { accessorKey, statHasInfo, statInfo, ddictobj: dDict[baseKey] });
    let columnUnit = config?.unit || statInfo?.unit || 'pct';
    let tipDescription = config.tipDesc ? config.tipDesc : (statHasInfo ? (statInfo.tips.mainTip ? statInfo.tips.mainTip : statInfo.tips[`${ptgc}Tip`]) : null);

    // Column Header Text (in <></> to handle subscript for adjusted stats)
    let columnHeader = config.header ? config.header : (statHasInfo ? statInfo.label1 : 'HEYO');
    if (columnHeader.includes('**')) { // we use ** in data dictionary to flag for superscripts
        const parts = columnHeader.split('**');
        columnHeader = <>{parts[0]}<sup>{parts[1]}</sup></>;
    }

    // And Return
    return {
        accessor: accessorKey,
        Header: columnHeader,
        className: config.className && config.className,
        width: width,
        minWidth: width,
        maxWidth: width + 10,
        ...(config.hideFilter !== true && { Filter: NumberMinimumColumnFilter }),
        ...(config.hideFilter !== true && { filter: 'between' }),
        ...(statHasInfo && { tipTitle: tipTitle }),
        ...(statHasInfo && { tipDesc: tipDescription }),
        disableColumnSelect: config.disableHide ? true : false,
        sortType: (rowA, rowB, colId, desc) => { return sortRatedColumn({ rowA, rowB, colId, desc }); },
        Cell: row => {
            let rowObject = row.row.original;

            // getting correct unit (pinned rows makes this tricky)
            let pinnedUnit = columnUnit;
            pinnedUnit = rowObject.pinType === 'allPcts' ? 'pct0' : pinnedUnit; //                          allPcts === entire row is 0-decimal percentages
            pinnedUnit = rowObject.pinType === 'totals' && columnUnit === 'num' ? 'int' : pinnedUnit; //    for "totals", remove the decimal place for "nums"
            pinnedUnit = rowObject.pinType === 'intToNum' && columnUnit === 'int' ? 'num' : pinnedUnit; //  for "intToNum", do the opposite, add a decimal place
            let finalUnit = rowObject.isPinned ? pinnedUnit : columnUnit;

            // console.log('valueOnly pinInfo: ', { rowObject, accessorKey, pinType: rowObject.pinType, pinnedUnit, columnUnit, finalUnit });

            let displayValue = row.value === null ? 0 : row.value;
            let showPlus = accessorKey === 'plusMinus';

            // and return
            return formatStatColumn({ stat: displayValue, unit: finalUnit, showPlus });
        }
    };
};
export default columnsDict;
