
// These functions all assume 1 game, not multiple games
// To Dos:
// (1) Improve FullName Join. Currently if a player (a) plays in game, but (b) doesn't get tagged in event, then we don't have fullName from enhPbp. Need names list separately.
// (2) Player PBP Metrics for AssistByZone, and for FG vs. FT Rebounding
// (3) Handle Inf values
// (4) PGS %iles for FG%, fg2%, fg3%, FT% should depend on 2+ FGA, 1+ 2PA/3PA/FTA, not on 10+ mins
// (5) Let's get NBA 3P metrics to the ?USA Today? writer Brian somthing.
// (6) RBZ, should we have more subtypes when computing rbz counts
// (7) there's an issue in possession results where and1Make / and1Miss is the wrong result sometimes (don't recall exactly when..., popped up in 1 of the article analyses...)

// eslint-disable-next-line no-unused-vars
import { tidy, summarize, sum, groupBy, first, rename, mutate, mutateWithSummary, n, nDistinct, leftJoin, select, filter, max, min, cumsum, lag, lead, rowNumber, arrange, distinct, desc } from '@tidyjs/tidy';
import { hexGrid } from '../harddata/HexGrid';
import { allCompetitionIds } from '../harddata/NcaaStructures';
// rename({ oldName: 'newName' })

// Identify Zones From ActionType + ShotDist + X + Y
const computeZones6 = (d) => {
    if (d.actionType === '2pt' && d.shotDist <= 4.5) { return 'atr2';
    } else if (d.actionType === '2pt' && Math.abs(25 - d.x) <= 6 && d.y >= 28) { return 'paint2';
    } else if (d.actionType === '2pt') { return 'mid2';
    } else if (d.actionType === '3pt' && d.shotDist >= 30) { return 'heave3';
    } else if (d.actionType === '3pt' && d.y >= 33) { return 'c3';
    } else if (d.actionType === '3pt' && d.shotDist < 30) { return 'atb3';
    } else { return 'err'; }
};
const computeZones13 = (d) => {
    // 2s
    if (d.actionType === '2pt' && d.shotDist <= 4.5) { return 'atr2';
    } else if (d.actionType === '2pt' && Math.abs(25 - d.x) <= 6 && d.y >= 28) { return 'paint2';
    } else if (d.actionType === '2pt' && d.x < 19 && d.y >= 33) { return 'lb2';
    } else if (d.actionType === '2pt' && d.x > 31 && d.y >= 33) { return 'rb2';
    } else if (d.actionType === '2pt' && d.x <= 25 && d.y < 33) { return 'le2';
    } else if (d.actionType === '2pt' && d.x > 25 && d.y < 33) { return 're2';
    // 3s
    } else if (d.actionType === '3pt' && d.shotDist >= 30) { return 'heave3';
    } else if (d.actionType === '3pt' && d.x <= 25 && d.y >= 33) { return 'lc3';
    } else if (d.actionType === '3pt' && d.x > 25 && d.y >= 33) { return 'rc3';
    } else if (d.actionType === '3pt' && d.x <= 15 && d.y < 33) { return 'lw3';
    } else if (d.actionType === '3pt' && d.x > 35) { return 'rw3';
    } else if (d.actionType === '3pt' && d.x > 15 && d.x <= 35) { return 'tok3';
    } else { return 'err'; }
};
const computeZones17 = (d) => {
    // 2s
    if (d.actionType === '2pt' && d.shotDist <= 4.5) { return 'atr2';
    } else if (d.actionType === '2pt' && d.x >= 19 && d.x <= 25 && d.y >= 35 && d.y < 42.75) { return 'slp2';
    } else if (d.actionType === '2pt' && d.x > 25 && d.x <= 31 && d.y >= 35 && d.y < 42.75) { return 'srp2';
    } else if (d.actionType === '2pt' && d.x >= 19 && d.x <= 25 && d.y >= 28 && d.y < 35) { return 'flp2';
    } else if (d.actionType === '2pt' && d.x > 25 && d.x <= 31 && d.y >= 28 && d.y < 35) { return 'frp2';
    } else if (d.actionType === '2pt' && d.x < 19 && d.y >= 33) { return 'lb2';
    } else if (d.actionType === '2pt' && d.x > 31 && d.y >= 33) { return 'rb2';
    } else if (d.actionType === '2pt' && d.x <= 25 && d.y < 33) { return 'le2';
    } else if (d.actionType === '2pt' && d.x > 25 && d.y < 33) { return 're2';
    } else if (d.actionType === '2pt' && d.y >= 42.75) { return 'behindHoop';
    // 3s
    } else if (d.actionType === '3pt' && d.shotDist >= 30) { return 'heave3';
    } else if (d.actionType === '3pt' && d.x <= 25 && d.y >= 33) { return 'lc3';
    } else if (d.actionType === '3pt' && d.x > 25 && d.y >= 33) { return 'rc3';
    } else if (d.actionType === '3pt' && d.x <= 15 && d.y < 33) { return 'lw3';
    } else if (d.actionType === '3pt' && d.x > 35) { return 'rw3';
    } else if (d.actionType === '3pt') { return 'tok3';
    } else { return 'err'; }
};
const computeDists7 = (d) => {
    // 2s
    if (d.actionType === '2pt' && d.shotDist <= 4.5) { return 'atr2';
    } else if (d.actionType === '2pt' && d.shotDist <= 10) { return 'sht2';
    } else if (d.actionType === '2pt' && d.shotDist <= 15) { return 'med2';
    } else if (d.actionType === '2pt') { return 'lng2';
    // 3s
    } else if (d.actionType === '3pt' && d.shotDist >= 30) { return 'heave3';
    } else if (d.actionType === '3pt' && d.shotDist <= 25) { return 'sht3';
    } else if (d.actionType === '3pt') { return 'lng3';
    } else { return 'err'; }
};

const getPbpTeam1Team2 = (rawPbp) => {
    // rawPbp has playersTeam1, playersTeam2, but no indication of a teamId1, teamId2. This function gets that.
    // identical to DBT's gs__cte_pbp_team_mapping.sql

    // Handle missing data + hardcopy
    if (typeof rawPbp === 'undefined' || rawPbp === null) { return []; }
    if (rawPbp.length === 0) { return []; }
    let enhPbp = JSON.parse(JSON.stringify(rawPbp));

    // Grab Needed Columns Only
    enhPbp = tidy(enhPbp,
        select(['matchId', 'teamId', 'personId', 'playersTeam1', 'playersTeam2']),
        rename({ matchId: 'gameId' }),
        rename({ personId: 'playerId' }),
        mutate({ playerId: d => d.playerId ? d.playerId.toString() : d.playerId }),
        filter(d => d.playersTeam1 !== null && d.playersTeam2 !== null),
        filter(d => d.teamId !== 0 && d.playerId !== 0)
    );

    // Split playersTeam1, playersTeam2 into individual players (handle differently for streaming API and rest API)
    enhPbp = tidy(enhPbp,
        mutate({ t1p1: d => d.playersTeam1.split(';')[0] }),
        mutate({ t1p2: d => d.playersTeam1.split(';')[1] }),
        mutate({ t1p3: d => d.playersTeam1.split(';')[2] }),
        mutate({ t1p4: d => d.playersTeam1.split(';')[3] }),
        mutate({ t1p5: d => d.playersTeam1.split(';')[4] }),
        mutate({ t1p6: d => d.playersTeam1.split(';')[5] }),
        mutate({ t2p1: d => d.playersTeam2.split(';')[0] }),
        mutate({ t2p2: d => d.playersTeam2.split(';')[1] }),
        mutate({ t2p3: d => d.playersTeam2.split(';')[2] }),
        mutate({ t2p4: d => d.playersTeam2.split(';')[3] }),
        mutate({ t2p5: d => d.playersTeam2.split(';')[4] }),
        mutate({ t2p6: d => d.playersTeam2.split(';')[5] })
    );

    // Check if playerId belongs to playersTeam1 or playersTeam2
    enhPbp = tidy(enhPbp,
        mutate({ isOnTeam1: d => [d.t1p1, d.t1p2, d.t1p3, d.t1p4, d.t1p5, d.t1p6].includes(d.playerId) }),
        mutate({ isOnTeam2: d => [d.t2p1, d.t2p2, d.t2p3, d.t2p4, d.t2p5, d.t2p6].includes(d.playerId) }),
        select(['-t1p1', '-t1p2', '-t1p3', '-t1p4', '-t1p5', '-t1p6', '-t2p1', '-t2p2', '-t2p3', '-t2p4', '-t2p5', '-t2p6'])
    );

    // Group by to begin determining which team is which
    let groupedByTeam = tidy(enhPbp,
        groupBy(['gameId', 'teamId'], [
            summarize({
                onTeam1Count: sum('isOnTeam1'),
                onTeam2Count: sum('isOnTeam2')
            })
        ])
    );

    // Check if onTeam1Count > onTeam2Count for each team-game
    groupedByTeam = tidy(groupedByTeam,
        mutate({ isIssue: d => d.onTeam1Count !== 0 && d.onTeam2Count !== 0 }),
        mutate({ teamId1: d => d.onTeam1Count > d.onTeam2Count ? d.teamId : null }),
        mutate({ teamId2: d => d.onTeam2Count > d.onTeam1Count ? d.teamId : null })
    );

    // GroupBy to go from 2 rows (1 per team) to 1 row (1 for game)
    let groupedByGame = tidy(groupedByTeam,
        groupBy('gameId', [
            summarize({
                teamId1: max('teamId1'),
                teamId2: max('teamId2'),
                t1ct: min('onTeam1Count'),
                t2ct: min('onTeam2Count')
            })
        ])
    );

    // And Return as Object
    // console.log('groupedBy Outputs: ', { groupedByGame, groupedByTeam });
    groupedByGame = groupedByGame[0];
    return groupedByGame;
};
const sortLineupId = (obj, homeAway) => {
    let homeArr = [
        { val: obj.hId1, rk: obj.h1PosRk },
        { val: obj.hId2, rk: obj.h2PosRk },
        { val: obj.hId3, rk: obj.h3PosRk },
        { val: obj.hId4, rk: obj.h4PosRk },
        { val: obj.hId5, rk: obj.h5PosRk }
    ];
    let awayArr = [
        { val: obj.aId1, rk: obj.a1PosRk },
        { val: obj.aId2, rk: obj.a2PosRk },
        { val: obj.aId3, rk: obj.a3PosRk },
        { val: obj.aId4, rk: obj.a4PosRk },
        { val: obj.aId5, rk: obj.a5PosRk }
    ];

    let arr = homeAway === 'home' ? homeArr : awayArr;
    let sortedArr = arr.sort((a, b) => { if (a.rk === b.rk) { return a.val > b.val ? 1 : -1; } return a.rk > b.rk ? 1 : -1; });
    let lineupId = sortedArr.map(row => row.val).join('-');
    return lineupId;
};
const pbpReorderActions = (rawPbp) => {
    // Keep what's needed
    let pbpReordered = JSON.parse(JSON.stringify(rawPbp));
    pbpReordered = tidy(pbpReordered, select(['matchId', 'actionNumber', 'previousAction']), rename({ matchId: 'gameId' }));

    // Reorder as needed
    pbpReordered = tidy(pbpReordered,
        // pbp__fixed_part1, add actionNumber2 & previousActionOrder
        mutate({ actionNumber2: d => d.previousAction !== 0 ? d.previousAction + 1 : d.actionNumber }),
        // below === row_number() over (partition by gameId, previousAction order by actionNumber asc) !! GREAT !!
        arrange('actionNumber'),
        groupBy(['gameId', 'previousAction'], [
            mutateWithSummary({ previousActionOrder: rowNumber({ startAt: 1 }) })
        ]),
        // pbp__fixed_part2, add actionNumber3
        arrange(['actionNumber2', 'previousActionOrder', desc('actionNumber')]),
        groupBy(['gameId'], [
            mutateWithSummary({ actionNumber3: rowNumber({ startAt: 1 }) })
        ]),
        // pbp__fixed_part3, save old and new actionNumber
        select(['gameId', 'actionNumber', 'actionNumber3']),
        rename({ actionNumber: 'actionNumberGs' }),
        rename({ actionNumber3: 'actionNumberCbb' }),
        arrange('actionNumberGs')
    );

    // And Return
    return pbpReordered;
};
const getPbpLineupIds = (rawPbp = [], gameInfo = {}, heightRanks = []) => {
    // console.log('getPbpLineupIds params: ', { rawPbp, gameInfo, heightRanks });
    //      use teamId1, teamId2 from getPbpTeam1Team2() to (a) convert PBP into home/away, and (b) create homeLineupId, awayLineupId, hIds, aIds
    //      identical to DBT's gs__cte_pbp_lineups_and_pids.sql

    // Handle missing data + hardcopy
    let debug = false;
    if (typeof rawPbp === 'undefined' || rawPbp === null) { return []; }
    if (rawPbp.length === 0) { return []; }
    let enhPbp = JSON.parse(JSON.stringify(rawPbp));

    // Keeper Cols, Rename, Fix Types, Drop Dead Rows
    if (debug === true) { console.log('rawPbp: ', rawPbp); }
    enhPbp = tidy(enhPbp,
        select(['matchId', 'actionType', 'actionNumber', 'score1', 'score2', 'playersTeam1', 'playersTeam2']),
        rename({ matchId: 'gameId' }),
        mutate({ score1: d => parseInt(d.score1, 10) }),
        mutate({ score2: d => parseInt(d.score2, 10) }),
        filter(d => !['clock', 'substitution'].includes(d.actionType))
    );

    // Improve actionNumber ordering
    if (debug === true) { console.log('enhPbp 0: ', enhPbp); }
    let actionsReordered = pbpReorderActions(rawPbp);
    let actionsReorderedB = tidy(actionsReordered, rename({ actionNumberGs: 'actionNumberGsB', actionNumberCbb: 'actionNumberCbbB' })); // rename before join
    enhPbp = tidy(enhPbp,
        leftJoin(actionsReorderedB, { by: { gameId: 'gameId', actionNumberGsB: 'actionNumber' } }),
        select(['-actionNumber', '-actionNumberGsB']),
        rename({ actionNumberCbbB: 'actionNumber' })
    );

    // Join teamId1, teamId2 from getPbpTeam1Team2(), and add homeId, awayId from gameInfo, create teamIdAgst
    if (debug === true) { console.log('enhPbp 1: ', enhPbp); }
    let teamIdMap = getPbpTeam1Team2(rawPbp);
    if (debug === true) { console.log('teamIdMap: ', teamIdMap); }
    enhPbp = tidy(enhPbp,
        mutate({ teamId1: teamIdMap.teamId1 }),
        mutate({ teamId2: teamIdMap.teamId2 }),
        mutate({ homeId: gameInfo.homeId }),
        mutate({ awayId: gameInfo.awayId })
    );

    // Convert Into Home/Away (score1, score2, playersTeam1, playersTeam2)
    if (debug === true) { console.log('enhPbp 2: ', enhPbp); }
    enhPbp = tidy(enhPbp,
        mutate({ homeOnCt: d => d.homeId === d.teamId1 ? d.playersTeam1 : (d.homeId === d.teamId2 ? d.playersTeam2 : '999999') }),
        mutate({ awayOnCt: d => d.awayId === d.teamId1 ? d.playersTeam1 : (d.awayId === d.teamId2 ? d.playersTeam2 : '999999') }),
        mutate({ homeScore: d => d.homeId === d.teamId1 ? d.score1 : (d.homeId === d.teamId2 ? d.score2 : 999999) }),
        mutate({ awayScore: d => d.awayId === d.teamId1 ? d.score1 : (d.awayId === d.teamId2 ? d.score2 : 999999) })
    );

    // Count # Players onCourt for Home & Away Team, and split onCts into hId 1-5, aId 1-5
    if (debug === true) { console.log('enhPbp 3: ', enhPbp); }
    enhPbp = tidy(enhPbp,
        mutate({ homeOnCtCount: d => (d.homeOnCt.match(/;/g) || []).length - 1 }), // g for global search, rather than first only
        mutate({ awayOnCtCount: d => (d.awayOnCt.match(/;/g) || []).length - 1 }),
        mutate({ hId1: d => parseInt(d.homeOnCt.split(';')[0], 10) }),
        mutate({ hId2: d => parseInt(d.homeOnCt.split(';')[1], 10) }),
        mutate({ hId3: d => parseInt(d.homeOnCt.split(';')[2], 10) }),
        mutate({ hId4: d => parseInt(d.homeOnCt.split(';')[3], 10) }),
        mutate({ hId5: d => parseInt(d.homeOnCt.split(';')[4], 10) }),
        mutate({ aId1: d => parseInt(d.awayOnCt.split(';')[0], 10) }),
        mutate({ aId2: d => parseInt(d.awayOnCt.split(';')[1], 10) }),
        mutate({ aId3: d => parseInt(d.awayOnCt.split(';')[2], 10) }),
        mutate({ aId4: d => parseInt(d.awayOnCt.split(';')[3], 10) }),
        mutate({ aId5: d => parseInt(d.awayOnCt.split(';')[4], 10) })
    );

    // Replace Negative playerIds with 999999
    if (debug === true) { console.log('enhPbp 4: ', enhPbp); }
    enhPbp = tidy(enhPbp,
        mutate({ hId1: d => d.hId1 < 0 ? 999999 : d.hId1 }),
        mutate({ hId2: d => d.hId2 < 0 ? 999999 : d.hId2 }),
        mutate({ hId3: d => d.hId3 < 0 ? 999999 : d.hId3 }),
        mutate({ hId4: d => d.hId4 < 0 ? 999999 : d.hId4 }),
        mutate({ hId5: d => d.hId5 < 0 ? 999999 : d.hId5 }),
        mutate({ aId1: d => d.aId1 < 0 ? 999999 : d.aId1 }),
        mutate({ aId2: d => d.aId2 < 0 ? 999999 : d.aId2 }),
        mutate({ aId3: d => d.aId3 < 0 ? 999999 : d.aId3 }),
        mutate({ aId4: d => d.aId4 < 0 ? 999999 : d.aId4 }),
        mutate({ aId5: d => d.aId5 < 0 ? 999999 : d.aId5 })
    );

    // Position + Height for each Player (10x PosRk fields) == lineupId Order
    if (debug === true) { console.log('enhPbp 5: ', enhPbp); }
    const skinnyHeightRanks = tidy(heightRanks, select(['playerId', 'teamId', 'heightRank']));
    enhPbp = tidy(enhPbp,
        leftJoin(skinnyHeightRanks, { by: { playerId: 'hId1', teamId: 'homeId' } }),
        rename({ heightRank: 'h1PosRk' }),
        leftJoin(skinnyHeightRanks, { by: { playerId: 'hId2', teamId: 'homeId' } }),
        rename({ heightRank: 'h2PosRk' }),
        leftJoin(skinnyHeightRanks, { by: { playerId: 'hId3', teamId: 'homeId' } }),
        rename({ heightRank: 'h3PosRk' }),
        leftJoin(skinnyHeightRanks, { by: { playerId: 'hId4', teamId: 'homeId' } }),
        rename({ heightRank: 'h4PosRk' }),
        leftJoin(skinnyHeightRanks, { by: { playerId: 'hId5', teamId: 'homeId' } }),
        rename({ heightRank: 'h5PosRk' }),
        leftJoin(skinnyHeightRanks, { by: { playerId: 'aId1', teamId: 'awayId' } }),
        rename({ heightRank: 'a1PosRk' }),
        leftJoin(skinnyHeightRanks, { by: { playerId: 'aId2', teamId: 'awayId' } }),
        rename({ heightRank: 'a2PosRk' }),
        leftJoin(skinnyHeightRanks, { by: { playerId: 'aId3', teamId: 'awayId' } }),
        rename({ heightRank: 'a3PosRk' }),
        leftJoin(skinnyHeightRanks, { by: { playerId: 'aId4', teamId: 'awayId' } }),
        rename({ heightRank: 'a4PosRk' }),
        leftJoin(skinnyHeightRanks, { by: { playerId: 'aId5', teamId: 'awayId' } }),
        rename({ heightRank: 'a5PosRk' }),
        select(['-playerId', '-teamId'])
    );

    // Use PosRks to create lineupIds
    if (debug === true) { console.log('enhPbp 6: ', enhPbp); }
    enhPbp = tidy(enhPbp,
        mutate({ homeLineupId: d => sortLineupId(d, 'home') }),
        mutate({ awayLineupId: d => sortLineupId(d, 'away') }),
        select(['-h1PosRk', '-h2PosRk', '-h3PosRk', '-h4PosRk', '-h5PosRk', '-a1PosRk', '-a2PosRk', '-a3PosRk', '-a4PosRk', '-a5PosRk'])
    );

    // Set new hId 1-5, aId 1-5 based on sorted values
    if (debug === true) { console.log('enhPbp 7: ', enhPbp); }
    enhPbp = tidy(enhPbp,
        mutate({ hId1: d => parseInt(d.homeLineupId.split('-')[0], 10) }),
        mutate({ hId2: d => parseInt(d.homeLineupId.split('-')[1], 10) }),
        mutate({ hId3: d => parseInt(d.homeLineupId.split('-')[2], 10) }),
        mutate({ hId4: d => parseInt(d.homeLineupId.split('-')[3], 10) }),
        mutate({ hId5: d => parseInt(d.homeLineupId.split('-')[4], 10) }),
        mutate({ aId1: d => parseInt(d.awayLineupId.split('-')[0], 10) }),
        mutate({ aId2: d => parseInt(d.awayLineupId.split('-')[1], 10) }),
        mutate({ aId3: d => parseInt(d.awayLineupId.split('-')[2], 10) }),
        mutate({ aId4: d => parseInt(d.awayLineupId.split('-')[3], 10) }),
        mutate({ aId5: d => parseInt(d.awayLineupId.split('-')[4], 10) })
    );

    // And Return
    if (debug === true) { console.log('enhPbp FINAL: ', enhPbp); }
    return enhPbp;
};

export function enhRawPbp(rawPbp, gameInfo, heightRanks) {
    // console.log('enhRawPbp props: ', { rawPbp, gameInfo, heightRanks });
    //      rawPbp: the raw pbp data from Genius Sports API
    //      gameInfo: object with game info from gs__game_info
    //      heightRanks: height ranks for both teams

    // Handle missing data + hardcopy
    if (!rawPbp || !gameInfo || !heightRanks) { return []; }
    if (rawPbp.length === 0) { return []; }


    let enhPbp = JSON.parse(JSON.stringify(rawPbp));

    // ======== START enhPbp0 ========
    // Initial Clean Names Renaming, Mapping of Columns ({ oldKey: 'newKey' })
    enhPbp = tidy(enhPbp,
        rename({ matchId: 'gameId' }),
        rename({ personId: 'playerId' }),
        rename({ period: 'periodNumber' }),
        rename({ shirtNumber: 'jerseyNum' }),
        mutate({ fullName: d => d.fullName ? d.fullName : (d.familyName ? `${d.firstName} ${d.familyName}` : '') }),
        mutate({ success: d => d.success === 1 ? true : false }),
        select([
            'fullName', 'jerseyNum', 'actionNumber', 'previousAction', 'gameId', 'periodNumber', 'periodType', 'teamId', 'playerId', 'score1', 'score2', 'playersTeam1', 'playersTeam2',
            'clock', 'x', 'y', 'actionType', 'subType', 'qualifiers', 'success', 'area',
            'officialId', 'orderNumber', 'teamNumber', 'homeId', 'awayId', 'edited', 'timeActual' // on streamingApi data only
        ])
    );


    // Fix ~80 rows where "qualifiers", "subType" are erronous values
    enhPbp = tidy(enhPbp,
        // "team" qualifier is incorrect in some places, adding and removing here as needed
        mutate({ qualifiers: d => {
            if (d.actionType === 'rebound' && ['defensivedeadball', 'offensivedeadball'].includes(d.subType)) { return '';
            } else if (d.actionType === 'rebound' && d.playerId !== 0) { return '';
            } else if (d.actionType === 'rebound' && d.qualifiers === 'deadball') { return '';
            } else if (d.actionType === 'rebound' && d.playerId === 0 && !['defensivedeadball', 'offensivedeadball'].includes(d.subType)) { return 'team';
            } else { return d.qualifiers; }
        } }),
        // there is no "deadball" qualifier for rebounds. should be in the subType
        mutate({ subType: d => {
            if (d.actionType === 'rebound' && d.qualifiers === 'deadball' && d.subType === 'offensive') { return 'offensivedeadball';
            } else if (d.actionType === 'rebound' && d.qualifiers === 'deadball' && d.subType === 'defensive') { return 'defensivedeadball';
            } else { return d.subType; }
        } })
    );

    // Remove Useless Rows
    let skinnyPbp = tidy(enhPbp, select(['gameId', 'actionNumber', 'actionType', 'subType']), rename({ actionNumber: 'actionNumberSk', actionType: 'actionTypeSk', subType: 'subTypeSk' }));
    enhPbp = tidy(enhPbp,
        leftJoin(skinnyPbp, { by: { gameId: 'gameId', actionNumberSk: 'previousAction' } }),
        filter(d => !['clock', 'substitution'].includes(d.actionType)), // useless actions, trims data size by 33%
        filter(d => d.clock.length === 8), // removes one (singular, damn one) buggy row where clock is wrong length
        filter(d => !(d.actionType === 'rebound' && d.subType === 'offensivedeadball' && d.actionTypeSk === 'freethrow' && ['1of2', '1of3', '2of3'].includes(d.subTypeSk))), // remove deadball ORBs between FTs
        select(['-actionNumberSk', '-actionTypeSk', '-subTypeSk'])
    );

    // console.log('enhPbp0 a: ', enhPbp);

    // Remove Exact Duplicates (except for FGAs, FTAs)
    enhPbp = tidy(enhPbp,
        arrange('actionNumber'),
        groupBy(['gameId', 'clock', 'periodNumber', 'periodType', 'teamId', 'playerId', 'actionType', 'subType', 'qualifiers', 'previousAction', 'success', 'area', 'playersTeam1', 'playersTeam2', 'score1', 'score2'], [
            mutateWithSummary({ uniqueRowKey: rowNumber({ startAt: 1 }) })
        ]),
        filter(d => d.uniqueRowKey === 1 || ['2pt', '3pt', 'freethrow'].includes(d.actionType)),
        select(['-uniqueRowKey'])
    );

    // Add 4 important fields: updated, competitionId, period, periodIdx
    // let skinnyGameInfo = tidy([gameInfo], select(['gameId', 'competitionId', 'updated'])); // need as an array
    enhPbp = tidy(enhPbp,
        mutate({ competitionId: gameInfo.competitionId }), // row not needed, just confirming that competitionId was leftJoin'd
        mutate({ updated: gameInfo.updated }), // row not needed, just confirming that updated was leftJoin'd
        mutate({ periodIdx: d => {
            if (d.periodType === 'REGULAR') { return d.periodNumber;
            } else if (d.periodType === 'OVERTIME' && allCompetitionIds.female.includes(d.competitionId)) { return 4 + d.periodNumber;
            } else if (d.periodType === 'OVERTIME' && allCompetitionIds.male.includes(d.competitionId)) { return 2 + d.periodNumber;
            } else { return 0; } // this should never appear
        } }),
        mutate({ period: d => {
            if (d.periodType === 'OVERTIME') { return 'ot';
            } else if (d.periodType === 'REGULAR' && d.periodNumber === 0) { return 'game';
            } else if (allCompetitionIds.female.includes(d.competitionId)) { return `q${d.periodNumber}`;
            } else if (allCompetitionIds.male.includes(d.competitionId)) { return `h${d.periodNumber}`;
            } else { return 'ERROR'; } // this should never appear
        } })
    );

    // SKIP: Manual Fix Negative Clock

    // Add preliminary secsIntoGame HERE for major reordering in enhPbp1
    enhPbp = tidy(enhPbp,
        mutate({ clockHours: d => parseInt(d.clock.substr(0, 2), 10) }),
        mutate({ clockMins: d => parseInt(d.clock.substr(3, 5), 10) }),
        mutate({ clockSecs: d => parseInt(d.clock.substr(6, 8), 10) }),
        mutate({ numberOfPeriods: d => allCompetitionIds.male.includes(d.competitionId) ? 2 : 4 }),
        mutate({ periodLength: d => allCompetitionIds.male.includes(d.competitionId) ? 20 : 10 }),
        mutate({ extraTimeLength: 5 }),
        mutate({ secsIntoGame: d => d.periodType === 'REGULAR' // otherwise 'OVERTIME'
            ? (60 * d.periodLength * (d.periodNumber - 1) + 60 * (d.periodLength - d.clockHours) - d.clockMins - (d.clockSecs / 100))
            : (60 * d.periodLength * d.numberOfPeriods + 60 * d.extraTimeLength * (d.periodNumber - 1) + 60 * (d.extraTimeLength - d.clockHours) - d.clockMins - (d.clockSecs / 100))
        }),
        mutate({ minsIntoGame: d => d.secsIntoGame === 0 ? 1 : Math.round((d.secsIntoGame + 29) / 60) }),
        arrange(['secsIntoGame', 'actionNumber']), // reorder before lag & lead
        mutateWithSummary({ prevSecsIntoGame: lag('secsIntoGame') }),
        mutateWithSummary({ prevActionType: lag('actionType') }),
        mutateWithSummary({ nextSecsIntoGame: lead('secsIntoGame') }),
        mutateWithSummary({ nextActionType: lead('actionType') }),
        mutate({ actionDuration: d => d.secsIntoGame === 0 ? 0 : d.secsIntoGame - d.prevSecsIntoGame })
    );

    // SKIP: Fix Missing "Side" for actionTypes ('2pt', '3pt'), 'LEFT' is missing in places

    // SKIP: Remove games with problematic PBP data (simply remove rather than wrestle w/ debugging small # of old games)


    // ======== START enhPbp1 ========
    // reorder for previousAction, place action immediately behind its previousAction
    // let prevActionUpdate = tidy(enhPbp,
    //     mutate({ actionNumber2: d => d.previousAction !== 0 ? d.previousAction + 1 : d.actionNumber }),
    //     arrange(['actionNumber']),
    //     groupBy(['gameId', 'previousAction'], [
    //         mutateWithSummary({ previousActionOrder: rowNumber({ startAt: 1 }) })
    //     ]),
    //     arrange(['actionNumber2', 'previousActionOrder', desc('actionNumber')]),
    //     groupBy(['gameId'], [
    //         mutateWithSummary({ actionNumberCbb: rowNumber({ startAt: 1 }) })
    //     ])
    //     // select([-])
    // );

    // let prevActionUpdate1 = tidy(prevActionUpdate, select(['gameId', 'actionNumber', 'actionNumberCbb']), rename({ actionNumberCbb: 'actionNumberCbb1' }));
    // let prevActionUpdate2 = tidy(prevActionUpdate, select(['gameId', 'actionNumber', 'actionNumberCbb']), rename({ actionNumberCbb: 'actionNumberCbb2' }));
    // enhPbp = tidy(enhPbp,
    //     mutate({ anGs: d => d.actionNumber }),
    //     mutate({ paGs: d => d.previousAction }),
    //     leftJoin(prevActionUpdate1, { by: { gameId: 'gameId', actionNumber: 'actionNumber' } }), // to update actionNumber
    //     leftJoin(prevActionUpdate2, { by: { gameId: 'gameId', actionNumber: 'previousAction' } }), // to update prevAction
    //     mutate({ actionNumber: d => d.actionNumberCbb1 }),
    //     mutate({ previousAction: d => d.actionNumberCbb2 !== null ? d.actionNumberCbb2 : 0 }));

    // // TMP
    // // enhPbp = tidy(enhPbp, arrange(['periodIdx', 'secsIntoGame', 'actionNumber']));

    // // Huge reordering for general problematic clock & actionNumbers being out of order (attempt to localized sort actionNumbers)
    // enhPbp = tidy(enhPbp,
    //     // 2A
    //     arrange(['periodIdx', 'secsIntoGame', 'actionNumber']),
    //     groupBy(['gameId'], [
    //         mutateWithSummary({ o1: rowNumber({ startAt: 1 }) })
    //     ]),
    //     arrange(['periodIdx', 'actionNumber']),
    //     groupBy(['gameId'], [
    //         mutateWithSummary({ o2: rowNumber({ startAt: 1 }) })
    //     ]),
    //     // 2B
    //     mutate({ isBigDiff: d => d.o2 - d.o1 >= 25 ? 1 : (d.o2 - d.o1 <= -25 ? -1 : 0) }),
    //     arrange(['periodIdx', 'secsIntoGame', 'actionNumber']),
    //     groupBy(['gameId'], [
    //         mutateWithSummary({ bigDiffSum: cumsum('isBigDiff') })
    //     ]),
    //     // 2C
    //     mutate({ o3: d => d.o2 + d.bigDiffSum }),
    //     mutate({ isOff: d => d.o2 + d.bigDiffSum === d.o1 ? 0 : 1 }),
    //     arrange(['periodIdx', 'secsIntoGame', 'actionNumber']),
    //     groupBy(['gameId'], [
    //         mutateWithSummary({ lagIsOff: lag('isOff', { n: 1 }) })
    //     ]),
    //     // 2D
    //     mutate({ startErr: d => d.lagIsOff === 0 && d.isOff === 1 ? 1 : 0 }),
    //     mutate({ endErr: d => d.lagIsOff === 1 && d.isOff === 0 ? 1 : 0 }),
    //     // 2E
    //     arrange(['periodIdx', 'secsIntoGame', 'actionNumber']),
    //     groupBy(['gameId'], [
    //         mutateWithSummary({ lagStart1: lag('startErr', { n: 1 }) }),
    //         mutateWithSummary({ lagStart2: lag('startErr', { n: 2 }) }),
    //         mutateWithSummary({ lagStart3: lag('startErr', { n: 3 }) }),
    //         mutateWithSummary({ lagStart4: lag('startErr', { n: 4 }) }),
    //         mutateWithSummary({ lagStart5: lag('startErr', { n: 5 }) })
    //     ]),
    //     // 2F
    //     mutate({ startErr: d => d.startErr === 1 && (d.lagStart1 === 1 || d.lagStart2 === 1 || d.lagStart3 === 1 || d.lagStart4 === 1 || d.lagStart5 === 1) ? 0 : d.startErr }),
    //     // 2G
    //     arrange(['periodIdx', 'secsIntoGame', 'actionNumber']),
    //     groupBy(['gameId'], [
    //         mutateWithSummary({ lagStart1: lag('startErr', { n: 1 }) }),
    //         mutateWithSummary({ lagStart2: lag('startErr', { n: 2 }) }),
    //         mutateWithSummary({ lagStart3: lag('startErr', { n: 3 }) }),
    //         mutateWithSummary({ lagStart4: lag('startErr', { n: 4 }) }),
    //         mutateWithSummary({ lagStart5: lag('startErr', { n: 5 }) }),
    //         mutateWithSummary({ leadStart1: lead('startErr', { n: 1 }) }),
    //         mutateWithSummary({ leadStart2: lead('startErr', { n: 2 }) }),
    //         mutateWithSummary({ leadStart3: lead('startErr', { n: 3 }) }),
    //         mutateWithSummary({ leadStart4: lead('startErr', { n: 4 }) })
    //     ]),
    //     // 2H
    //     mutate({ endErr: d => {
    //         if (d.endErr === 1 && d.lagStart1 === 1 && (d.startErr === 1 || d.leadStart1 === 1 || d.leadStart2 === 1 || d.leadStart3 === 1 || d.leadStart4 === 1)) { return 0;
    //         } else if (d.endErr === 1 && d.lagStart2 === 1 && (d.lagStart1 === 1 || d.startErr === 1 || d.leadStart1 === 1 || d.leadStart2 === 1 || d.leadStart3 === 1)) { return 0;
    //         } else if (d.endErr === 1 && d.lagStart3 === 1 && (d.lagStart2 === 1 || d.lagStart1 === 1 || d.startErr === 1 || d.leadStart1 === 1 || d.leadStart2 === 1)) { return 0;
    //         } else if (d.endErr === 1 && d.lagStart4 === 1 && (d.lagStart3 === 1 || d.lagStart2 === 1 || d.lagStart1 === 1 || d.startErr === 1 || d.leadStart1 === 1)) { return 0;
    //         } else if (d.endErr === 1 && d.lagStart5 === 1 && (d.lagStart4 === 1 || d.lagStart3 === 1 || d.lagStart2 === 1 || d.lagStart1 === 1 || d.startErr === 1)) { return 0;
    //         } else { return d.endErr; }
    //     } }),
    //     // 2I
    //     arrange(['periodIdx', 'secsIntoGame', 'actionNumber']),
    //     groupBy(['gameId'], [
    //         mutateWithSummary({ reorderGroup: cumsum('startErr') }),
    //         mutateWithSummary({ reorderGroup2: cumsum('endErr') })
    //     ]),
    //     mutate({ groupsCounter: d => d.reorderGroup + d.reorderGroup2 }),
    //     mutate({ reorderGroup: d => d.isOff === 1 ? d.reorderGroup : null }),
    //     select(['-reorderGroup2']),
    //     // HARD PART, WINDOW FUNCTION to update reorderGRoup...
    //     // arrange([desc('periodIdx'), desc('secsIntoGame'), desc('actionNumber')])
    //     arrange(['periodIdx', 'secsIntoGame', 'actionNumber'])
    // );

    // console.log('enhPbp HERE: ', enhPbp);

    // 2J
    // let reorderGroupInfo = tidy(enhPbp,
    //     filter(d => d.reorderGroup !== null),
    //     groupBy(['gameId', 'reorderGroup'], [
    //         mutateWithSummary({ numRows: n() }),
    //         mutateWithSummary({ tmpMaxSecs: max('secsIntoGame') }),
    //         mutateWithSummary({ tmpMinSecs: min('secsIntoGame') }),
    //         mutateWithSummary({ tmpMaxAction: max('actionNumber') }),
    //         mutateWithSummary({ tmpMinAction: min('actionNumber') })
    //     ]),
    //     mutate({ spanOfSecs: d => Math.round(d.tmpMaxSecs - d.tmpMinSecs, 1) }),
    //     mutate({ spanOfActions: d => d.tmpMaxActions - d.tmpMinActions }),
    //     select(['gameId', 'reorderGroup', 'numRows', 'spanOfSecs', 'spanOfActions']));

    // enhPbp = tidy(enhPbp,
    //     // 2K
    //     leftJoin(reorderGroupInfo, { by: { gameId: 'gameId', reorderGroup: 'reorderGroup' } }),
    //     mutate({ isValidGroup: d => d.numrows < 8 && d.spanOfSecs < 30 && d.spanOfActions < 8 ? true : false }),
    //     // 2L
    //     arrange(['groupsCounter', 'o1']),
    //     groupBy(['gameId'], [
    //         mutateWithSummary({ r1: rowNumber({ startAt: 0 }) })
    //     ]),
    //     arrange(['groupsCounter', 'o3']),
    //     groupBy(['gameId'], [
    //         mutateWithSummary({ r2: rowNumber({ startAt: 0 }) })
    //     ]),
    //     mutate({ rowNum: d => !d.isValidGroup ? d.r1 : (d.reorderGroup === 0 ? d.r1 : d.r2) })
    // );

    // // 3A
    // let skinnyStuff = tidy(enhPbp, select(['gameId', 'rowNum', 'actionNumber', 'previousAction']));
    // enhPbp = tidy(enhPbp,
    //     leftJoin(skinnyStuff, { by: { gameId: 'gameId', actionNumber: 'previousAction' } }),
    //     mutate({ priorRow: d => d.rowNum }));

    // // 4A
    // let newClockAndSecs = tidy(enhPbp,
    //     arrange(['groupsCounter', 'secsIntoGame', 'actionNumber']),
    //     groupBy(['gameId'], [
    //         mutateWithSummary({ clockNum: rowNumber({ startAt: 0 }) })
    //     ]),
    //     rename({ clock: 'clockNew' }),
    //     rename({ secsIntoGame: 'secsIntoGameNew' }),
    //     select(['gameId', 'clockNew', 'secsIntoGameNew', 'groupsCounter', 'clockNum']));

    // enhPbp = tidy(enhPbp,
    //     // 4B
    //     leftJoin(newClockAndSecs, { by: { gameId: 'gameId', clockNum: 'rowNum' } }),
    //     mutate({ clockOG: d => d.clock }),
    //     mutate({ secsIntoGameOG: d => d.secsIntoGame }),
    //     mutate({ clock: d => d.isValidGroup ? d.clockNew : d.clockOG }),
    //     mutate({ secsIntoGame: d => d.isValidGroup ? d.secsIntoGameNew : d.secsIntoGame }),
    //     // 4C
    //     arrange(['rowNum']),
    //     groupBy(['gameId'], [
    //         mutateWithSummary({ prevSecs: lag('secsIntoGame') }),
    //         mutateWithSummary({ prevClock: lag('clock') }),
    //         mutateWithSummary({ prevActionType: lag('actionType') })
    //     ]),
    //     // 4D
    //     mutate({ secsIntoGame: d => {
    //         if (d.actionType === 'freethrow') { return d.prevSecs;
    //         } else if (d.actionType === 'assist' && ['2pt', '3pt'].includes(d.prevActionType)) { return d.prevSecs;
    //         } else if (d.actionType === 'foulon' && d.prevActionType === 'foul') { return d.prevSecs;
    //         } else if (d.actionType === 'steal' && d.prevActionType === 'turnover') { return d.prevSecs;
    //         } else if (['period', 'game'].includes(d.actionType) && d.subType === 'end') {
    //             if (d.secsIntoGame <= 600) { return 600;
    //             } else if (d.secsIntoGame <= 1200) { return 1200;
    //             } else if (d.secsIntoGame <= 1800) { return 1800;
    //             } else if (d.secsIntoGame <= 2400) { return 2400;
    //             } else if (d.secsIntoGame <= 2700) { return 2700;
    //             } else if (d.secsIntoGame <= 3000) { return 3000;
    //             } else if (d.secsIntoGame <= 3300) { return 3300;
    //             } else if (d.secsIntoGame <= 3600) { return 3600;
    //             } else if (d.secsIntoGame <= 3900) { return 3900;
    //             } else { return d.secsIntoGame; }
    //         } else { return d.secsIntoGame; }
    //     } }),
    //     mutate({ clock: d => {
    //         if (d.actionType === 'freethrow') { return d.prevClock;
    //         } else if (d.actionType === 'assist' && ['2pt', '3pt'].includes(d.prevActionType)) { return d.prevClock;
    //         } else if (d.actionType === 'foulon' && d.prevActionType === 'foul') { return d.prevClock;
    //         } else if (d.actionType === 'steal' && d.prevActionType === 'turnover') { return d.prevClock;
    //         } else if (['period', 'game'].includes(d.actionType) && d.subType === 'end') { return '00:00:00';
    //         } else { return d.clock; }
    //     } }),
    //     rename({ rowNum: 'actionNumber' }),
    //     rename({ priorRow: 'previousAction' }));


    // // ======== START enhPbp2 ========
    // // 5A
    // let allEndPeriods = tidy(enhPbp,
    //     filter(d => d.actionType === 'period' && d.subType === 'end'),
    //     rename({ actionNumber: 'periodEndAction' }),
    //     select(['gameId', 'periodIdx', 'periodEndAction']));

    // let allMaxPeriodActions = tidy(enhPbp,
    //     filter(d => !(d.actionType === 'game' && d.subType === 'end')),
    //     groupBy(['gameId', 'periodIdx'], [
    //         mutateWithSummary({ maxAction: max('actionNumber') })
    //     ]),
    //     select(['gameId', 'periodIdx', 'maxAction']));

    // // 5B
    // enhPbp = tidy(enhPbp,
    //     leftJoin(allEndPeriods, { by: { gameId: 'gameId', periodIdx: 'periodIdx' } }),
    //     leftJoin(allMaxPeriodActions, { by: { gameId: 'gameId', periodIdx: 'periodIdx' } }),
    //     mutate({ maxAction: d => d.maxAction }),
    //     mutate({ periodEndAction: d => d.periodEndAction }),
    //     mutate({ actionNumberPrev: d => d.actionNumber }),
    //     mutate({ actionNumber: d => {
    //         if (d.periodEndAction === null || d.maxAction === null) { return d.actionNumber;
    //         } else if (d.actionType === 'period' && d.subType === 'end') { return d.maxAction;
    //         } else if (d.actionType === 'game' && d.subType === 'end') { return d.maxAction + 1;
    //         } else if (d.actionNumber > d.periodEndAction) { return d.actionNumber - 1;
    //         } else { return d.actionNumber; }
    //     } }),
    //     mutate({ newPrevAction: d => d.actionNumber })
    // );

    // let latestSkinny = tidy(enhPbp, select(['gameId', 'periodIdx', 'newPrevAction', 'actionNumberPrev']));
    // enhPbp = tidy(enhPbp,
    //     leftJoin(latestSkinny, { by: { gameId: 'gameId', periodIdx: 'periodIdx', actionNumberPrev: 'actionNumber' } }),
    //     mutate({ previousAction: d => d.newPrevAction }));


    // // 6
    // enhPbp = tidy(enhPbp,
    //     mutate({ actionNumberPrev: d => d.actionNumber }),
    //     arrange(['periodIdx', 'actionNumber']),
    //     groupBy(['gameId'], [
    //         mutateWithSummary({ actionNumber: rowNumber({ startAt: 0 }) })
    //     ]),
    //     mutate({ newPrevAction: d => d.actionNumber }));

    // let anotherPbpSkinny = tidy(enhPbp, select(['gameId', 'periodIdx', 'newPrevAction', 'actionNumberPrev']));
    // enhPbp = tidy(enhPbp,
    //     leftJoin(anotherPbpSkinny, { by: { gameId: 'gameId', periodIdx: 'periodIdx', actionNumberPrev: 'previousAction' } }),
    //     mutate({ previousAction: d => d.newPrevAction }));


    // ======== START enhPbp3 ========

    // Improve actionNumber ordering
    let actionsReordered = pbpReorderActions(rawPbp);
    let actionsReorderedB = tidy(actionsReordered, rename({ actionNumberGs: 'actionNumberGsB', actionNumberCbb: 'actionNumberCbbB' })); // rename before join
    let actionsReorderedC = tidy(actionsReordered, rename({ actionNumberGs: 'actionNumberGsC', actionNumberCbb: 'actionNumberCbbC' })); // rename before join
    enhPbp = tidy(enhPbp,
        leftJoin(actionsReorderedB, { by: { gameId: 'gameId', actionNumberGsB: 'actionNumber' } }),
        leftJoin(actionsReorderedC, { by: { gameId: 'gameId', actionNumberGsC: 'previousAction' } }),
        select(['-actionNumber', '-previousAction']),
        rename({ actionNumberCbbB: 'actionNumber' }),
        mutate({ previousAction: d => d.actionNumberCbbC ? d.actionNumberCbbC : 0 }),
        select(['-actionNumberGsB', '-actionNumberGsC', '-actionNumberCbbC']),
        arrange(['secsIntoGame', 'actionNumber']) // reorder after fixing action numbers
    );

    // Join homeId, awayId, homeLineupId, awayLineupId, hIds, aIds
    let lineupsIdsArr = getPbpLineupIds(rawPbp, gameInfo, heightRanks);
    lineupsIdsArr = tidy(lineupsIdsArr, select(['actionNumber', 'awayId', 'awayScore', 'awayLineupId', 'aId1', 'aId2', 'aId3', 'aId4', 'aId5', 'homeId', 'homeScore', 'homeLineupId', 'hId1', 'hId2', 'hId3', 'hId4', 'hId5']));
    enhPbp = tidy(enhPbp,
        leftJoin(lineupsIdsArr, { by: { actionNumber: 'actionNumber' } }),
        mutate({ teamIdAgst: d => d.teamId === 0 ? 0 : (d.teamId === d.homeId ? d.awayId : (d.teamId === d.awayId ? d.homeId : 999999)) })
    );


    // Set Constants
    let hexXRadius = 1.2125;
    let hexYRadius = 1.4;
    // let hexSideLen = 1.4;

    // Grab Assists (pno == playerId in stream data)
    let astsPbp = tidy(
        enhPbp,
        filter(d => d.actionType === 'assist' && d.previousAction !== 0),
        select(['playerId', 'previousAction']),
        rename({ playerId: 'assisterId', previousAction: 'shotAction' })
    );

    // // Shots Only
    // enhPbp = enhPbp.filter(row => ['2pt', '3pt'].includes(row.actionType));

    // Left Join Assists
    enhPbp = tidy(enhPbp,
        leftJoin(astsPbp, { by: { shotAction: 'actionNumber' } }),
        mutate({ assisterId: d => d.assisterId ? d.assisterId : null }));

    // Convert Coordinates, Compute Shot distance
    enhPbp = tidy(enhPbp,
        mutate({ xRaw: d => d.x, yRaw: d => d.y }),
        mutate({
            xVal: d => d.x * (94 / 100) < 47 ? d.y * (50 / 100) : (50 - d.y * (50 / 100)),
            yVal: d => d.x * (94 / 100) < 47 ? 94 - d.x * (94 / 100) - 47 : d.x * (94 / 100) - 47
        }),
        mutate({
            x: d => d.xVal,
            y: d => d.yVal,
            shotDist: d => Math.sqrt(Math.pow(d.xVal - 25, 2) + Math.pow(d.yVal - (47 - 5.25), 2))
        })
    );

    // Line Equations for Determining Zones
    enhPbp = tidy(enhPbp,
        mutate({
            rimLeftM: -1 / Math.sqrt(3),
            rimRightM: 1 / Math.sqrt(3),
            rimLeftB: 41.75 - (25 * (-1 / Math.sqrt(3))),
            rimRightB: 41.75 - (25 * (1 / Math.sqrt(3))),
            mainLeftM: 42.25 / 25,
            mainRightM: 42.25 / -25,
            mainLeftB: 0,
            mainRightB: 42.25 - (25 * (42.25 / -25))
        })
    );

    // Add Main 4 Sets of Zones
    enhPbp = tidy(
        enhPbp,
        mutate({ zones6: d => computeZones6(d) }),
        mutate({ zones13: d => computeZones13(d) }),
        mutate({ zones17: d => computeZones17(d) }),
        mutate({ dists7: d => computeDists7(d) })
    );


    // F3) add hexX, hexY for heatmap shot charts
    enhPbp = enhPbp.map(rowA => {
        let filteredGrid = hexGrid.filter(rowB => {
            let c1 = Math.abs(rowA.x - rowB.hexX) <= hexXRadius;
            let c2 = Math.abs(rowA.y - rowB.hexY) <= hexYRadius;
            let c3 = Math.abs(rowA.y - rowB.hexY) <= hexYRadius - (Math.abs(rowA.x - rowB.hexX) * (0.7 / 1.2125));
            return c1 && c2 && c3;
        });

        if (filteredGrid.length === 0) { return rowA; }
        return {
            ...rowA,
            hexX: filteredGrid[0].hexX,
            hexY: filteredGrid[0].hexY
        };
    });

    // didHomeChange, didAwayChange, homePts and awayPts
    enhPbp = tidy(enhPbp,
        mutateWithSummary({ homeLineupIdPrev: lag('homeLineupId') }),
        mutateWithSummary({ awayLineupIdPrev: lag('awayLineupId') }),
        mutate({ didHomeChange: d => d.homeLineupIdPrev !== d.homeLineupId }),
        mutate({ didAwayChange: d => d.awayLineupIdPrev !== d.awayLineupId }),
        mutateWithSummary({ homeScorePrev: lag('homeScore') }),
        mutateWithSummary({ awayScorePrev: lag('awayScore') }),
        mutate({ homePts: d => d.homeScore - d.homeScorePrev }),
        mutate({ awayPts: d => d.awayScore - d.awayScorePrev }),
        select(['-homeLineupIdPrev', '-awayLineupIdPrev', '-homeScorePrev', '-awayScorePrev'])
    );

    // L) Add Possession & Chance Features (chncNum, possNum, possTeamId, defTeamId, possStartType, chncStartType)
    let pbpFilteredForPossChnc = tidy(enhPbp,
        // L1) for poss & chnc features, we want to filter away certain actionTypes
        filter(d => !['clock', 'substitution'].includes(d.actionType)),
        filter(d => !['game', 'assist', 'steal', 'foulon', 'block'].includes(d.actionType)),
        filter(d => !(d.actionType === 'rebound' && d.subType === 'offensivedeadball' && !['2pt', '3pt'].includes(d.prevActionType))),
        filter(d => !(d.actionType === 'timeout' && d.prevActionType === 'timeout')),
        filter(d => !(d.actionType === 'timeout' && d.prevActionType === 'foulon' && d.nextActionType === 'freethrow')),
        filter(d => !(d.actionType === 'timeout' && d.prevActionType === 'freethrow' && d.nextActionType === 'freethrow')),
        // L2) grab features for previous action, given filtering of actions in L1
        select(['-prevSecsIntoGame', '-prevActionType']),
        arrange(['secsIntoGame', 'actionNumber']),
        mutateWithSummary({ pprevSecsIntoGame: lag('secsIntoGame') }), // make sure ordering is correct for lag
        mutateWithSummary({ pprevActionType: lag('actionType') }), // make sure ordering is correct for lag
        mutateWithSummary({ pprevSubType: lag('subType') }), // make sure ordering is correct for lag
        mutateWithSummary({ pprevSuccess: lag('success') }), // make sure ordering is correct for lag
        mutateWithSummary({ pprevTeamId: lag('teamId') }), // make sure ordering is correct for lag
        mutateWithSummary({ pprevTeamIdAgst: lag('teamIdAgst') }), // make sure ordering is correct for lag
        mutate({ notFt1: d => !d.qualifiers.includes('1freethrow') }), // does this === "qualifiers not like concat('%', '1freethrow', '%')" ???
        // L3) remove teamId for offensive and defensive deadball rebounds occurring immediately after made FTs (these mess with team possession identification)
        mutate({ teamId: d => (d.secsIntoGame === d.pprevSecsIntoGame && d.actionType === 'rebound' && d.subType.includes('deadball') && d.pprevActionType === 'freethrow') ? null : d.teamId }),
        mutate({ teamIdAgst: d => (d.secsIntoGame === d.pprevSecsIntoGame && d.actionType === 'rebound' && d.subType.includes('deadball') && d.pprevActionType === 'freethrow') ? null : d.teamIdAgst }),
        // L4) Identify which team has possession
        mutate({ possTeamId: d => {
            if (['2pt', '3pt', 'turnover', 'freethrow', 'rebound'].includes(d.actionType)) { return d.teamId;
            } else if (['2pt', '3pt'].includes(d.pprevActionType) && d.pprevSuccess && d.notFt1) { return d.pprevTeamIdAgst;
            } else if (d.actionType === 'jumpball') { return null;
            } else if (d.actionType === 'foul' && d.subType === 'personal') { return d.teamIdAgst;
            } else if (d.actionType === 'foul' && d.subType === 'offensive') { return d.teamId;
            } else { return null; }
        } }),
        mutate({ defTeamId: d => {
            if (['2pt', '3pt', 'turnover', 'freethrow', 'rebound'].includes(d.actionType)) { return d.teamIdAgst;
            } else if (['2pt', '3pt'].includes(d.pprevActionType) && d.pprevSuccess && d.notFt1) { return d.pprevTeamId;
            } else if (d.actionType === 'jumpball') { return null;
            } else if (d.actionType === 'foul' && d.subType === 'personal') { return d.teamId;
            } else if (d.actionType === 'foul' && d.subType === 'offensive') { return d.teamIdAgst;
            } else { return null; }
        } }),
        // L5) with start type for possession & chances
        mutate({ possStartType: d => {
            if (d.actionType === 'foul' && ['coachTechnical', 'adminTechnical', 'benchTechnical'].includes(d.subType) && [0, 600, 1200, 1800, 2400, 2700, 3000].includes(d.secsIntoGame)) { return 'prePeriodTech';
            } else if (d.actionType === 'foul' && d.actionNumber === 1) { return 'prePeriodTech';
            } else if (d.actionType === 'freethrow' && d.secsIntoGame === 0 && d.actionNumber === 1) { return 'prePeriodTech';
            } else if (['game', 'period'].includes(d.actionType) && d.subType === 'start') { return 'periodStart';
            } else if (d.actionType === 'rebound' && d.subType === 'defensive' && ['2pt', '3pt'].includes(d.pprevActionType)) { return 'drbFg';
            } else if (d.actionType === 'rebound' && d.subType === 'defensive' && d.pprevActionType === 'freethrow') { return 'drbFt';
            } else if (d.actionType === 'rebound' && d.subType === 'defensivedeadball') { return 'drbDead';
            } else if (['2pt', '3pt'].includes(d.pprevActionType) && d.pprevSuccess && d.notFt1) { return 'oppFgm';
            } else if (d.pprevActionType === 'freethrow' && ['1of1', '2of2', '3of3'].includes(d.pprevSubType)) { return 'oppFtm';
            } else if (d.pprevActionType === 'turnover' && ['ballhandling', 'badpass', 'lostball'].includes(d.pprevSubType)) { return 'oppTovLive';
            } else if (d.pprevActionType === 'turnover' && !['ballhandling', 'badpass', 'lostball'].includes(d.pprevSubType)) { return 'oppTovDead';
            } else { return null; }
        } }),
        mutate({ chncStartType: d => {
            if (d.actionType === 'foul' && ['coachTechnical', 'adminTechnical', 'benchTechnical'].includes(d.subType) && [0, 600, 1200, 1800, 2400, 2700, 3000].includes(d.secsIntoGame)) { return 'prePeriodTech';
            } else if (d.actionType === 'foul' && d.actionNumber === 1) { return 'prePeriodTech';
            } else if (d.actionType === 'freethrow' && d.secsIntoGame === 0 && d.actionNumber === 1) { return 'prePeriodTech';
            } else if (d.actionType === 'period' && d.subType === 'start') { return 'periodStart';
            } else if (d.actionType === 'timeout') { return 'timeout';
            } else if (d.actionType === 'rebound' && d.subType === 'defensive' && ['2pt', '3pt'].includes(d.pprevActionType)) { return 'drbFg';
            } else if (d.actionType === 'rebound' && d.subType === 'defensive' && d.pprevActionType === 'freethrow') { return 'drbFt';
            } else if (d.actionType === 'rebound' && d.subType === 'offensive' && ['2pt', '3pt'].includes(d.pprevActionType)) { return 'orbFg';
            } else if (d.actionType === 'rebound' && d.subType === 'offensive' && d.pprevActionType === 'freethrow') { return 'orbFt';
            } else if (d.actionType === 'rebound' && d.subType === 'defensivedeadball') { return 'drbDead';
            } else if (d.actionType === 'rebound' && d.subType === 'offensivedeadball' &&
                !(d.pprevActionType === 'freethrow' && parseInt(d.pprevSubType.substr(0, 1), 10) < parseInt(d.pprevSubType.slice(-1), 10)) &&
                !(d.pprevActionType === 'freethrow' && d.pprevSuccess)) { return 'orbDead';
            } else if (['2pt', '3pt'].includes(d.pprevActionType) && d.pprevSuccess && d.notFt1) { return 'oppFgm';
            } else if (d.pprevActionType === 'freethrow' && ['1of1', '2of2', '3of3'].includes(d.pprevSubType) && d.pprevSuccess) { return 'oppFtm';
            } else if (d.pprevActionType === 'turnover' && ['ballhandling', 'badpass', 'lostball'].includes(d.pprevSubType)) { return 'oppTovLive';
            } else if (d.pprevActionType === 'turnover' && !['ballhandling', 'badpass', 'lostball'].includes(d.pprevSubType)) { return 'oppTovDead';
            } else if (d.pprevActionType === 'jumpball' && ['heldball', 'lodgedball'].includes(d.pprevSubType) &&
                !(d.actionType === 'rebound' && d.subType === 'offensive') &&
                !(d.actionType === 'turnover' && d.teamId !== d.pprevTeamId)) { return 'jumpBall';
            } else { return null; }
        } }),
        // Keep Whats Needed to Simplify Left Join
        select(['gameId', 'actionNumber', 'possTeamId', 'defTeamId', 'possStartType', 'chncStartType', 'pprevSecsIntoGame', 'pprevActionType', 'pprevSubType', 'pprevSuccess', 'pprevTeamId', 'pprevTeamIdAgst'])
    );

    // Join the monstrocity above to enhPbp
    enhPbp = tidy(enhPbp,
        leftJoin(pbpFilteredForPossChnc, { by: ['gameId', 'actionNumber'] }),
        // L8) and finally, count possessions and chances
        mutate({ tmpPossBoolean: d => d.possStartType ? 1 : 0 }),
        mutate({ tmpChncBoolean: d => d.chncStartType ? 1 : 0 }),
        mutateWithSummary({ possNum: cumsum('tmpPossBoolean') }), // seems too easy to be true, what about unbounded preceding?
        mutateWithSummary({ chncNum: cumsum('tmpChncBoolean') }) // seems too easy to be true, what about unbounded preceding?
    );


    // (G) Shot Clock Approximation
    // G1) To get shot clock, we need best estimate of possStart, possEnd, chncStart, chncEnd
    let groupedByPoss = tidy(enhPbp,
        groupBy(['gameId', 'possNum'], [
            summarize({
                secsLeftGameInStart: min('secsLeftGame'),
                secsInEnd: max('secsIntoGame'),
                secsInStart: min('secsIntoGame'),
                startType: max('possStartType')
            })
        ]),
        // lead(secsInStart, 1) over (partition by gameId, order by possNum)...
        arrange(['possNum']), groupBy(['gameId'], [
            mutateWithSummary({ nextSecsInStart: lead('secsInStart', { n: 1 }) }),
            mutateWithSummary({ nextStartType: lead('startType', { n: 1 }) })
        ]),
        // fix poss secs end
        mutate({
            possEnd: d => {
                if (d.nextStartType === 'drbFg') { return d.nextSecsInStart - 2; // if next possession starts by drbFg, this possession ended 2s before that
                } else if (d.nextStartType === 'drbFt') { return d.nextSecsInStart - 1;
                } else if (d.nextStartType === 'periodStart') { return d.nextSecsInStart; // start period when jumpball is won (~2 secs into period), not at exact period start
                } else { return d.secsInEnd; }
            }
        }),
        select(['-nextSecsInStart', '-nextStartType', '-secsInEnd']),
        // fix poss secs start
        arrange(['possNum']), groupBy(['gameId'], [
            mutateWithSummary({ priorPossSecsInEnd: lag('possEnd', { n: 1 }) })
        ]),
        mutate({
            possStart: d => {
                if (d.priorPossSecsInEnd === null) { return d.secsInStart; // for first poss of game, dont use prior poss secsEnd because there is none
                } else if (d.startType === 'oppFgm' && d.secsLeftGameInStart > 60) { return d.priorPossSecsInEnd + 3; // start type following made basket (not in last 60 secs) is ~3 seconds after the made basket is recorded (estimated time to inbound)
                } else { return d.priorPossSecsInEnd; }
            }
        }), // keep what we need: for each possesion, seconds into game at start & end of possession
        select(['gameId', 'possNum', 'possStart', 'possEnd']));

    let groupedByChnc = tidy(enhPbp,
        groupBy(['gameId', 'chncNum'], [
            summarize({
                secsLeftGameInStart: min('secsLeftGame'),
                secsInEnd: max('secsIntoGame'),
                secsInStart: min('secsIntoGame'),
                startType: max('chncStartType')
            })
        ]),
        arrange(['chncNum']), groupBy(['gameId'], [
            mutateWithSummary({ nextSecsInStart: lead('secsInStart', { n: 1 }) }),
            mutateWithSummary({ nextStartType: lead('startType', { n: 1 }) })
        ]),
        // fix chance secs end
        mutate({
            chncEnd: d => {
                if (d.nextStartType === 'drbFg') { return d.nextSecsInStart - 2; // if next chance starts by drbFg, this chance ended 2s before that
                } else if (d.nextStartType === 'drbFt') { return d.nextSecsInStart - 1;
                } else if (d.nextStartType === 'periodStart') { return d.nextSecsInStart; // start chance when jumpball is won (~2 secs into period), not at exact period start
                } else { return d.secsInEnd; }
            }
        }),
        select(['-nextSecsInStart', '-nextStartType', '-secsInEnd']),
        // fix chance secs start
        arrange(['chncNum']), groupBy(['gameId'], [
            mutateWithSummary({ priorChncSecsInEnd: lag('chncEnd', { n: 1 }) })
        ]),
        mutate({
            chncStart: d => {
                if (d.priorChncSecsInEnd === null) { return d.secsInStart; // for first chance of game, dont use prior chance secsEnd because there is none
                } else if (d.startType === 'oppFgm' && d.secsLeftGameInStart > 60) { return d.priorChncSecsInEnd + 3; // start type following made basket (not in last 60 secs) is ~3 seconds after the made basket is recorded (estimated time to inbound)
                } else { return d.priorChncSecsInEnd; }
            }
        }), // keep what we need: for each possesion, seconds into game at start & end of possession
        select(['gameId', 'possNum', 'chncStart', 'chncEnd'])); // this has, for each chance, seconds into game at start & end of possession (secsInStart, secsInEnd)


    // (G2) use poss starts and ends to derive shot clock
    // join poss & chance starts & ends
    enhPbp = tidy(enhPbp,
        leftJoin(groupedByPoss, { by: ['gameId', 'possNum'] }),
        leftJoin(groupedByChnc, { by: ['gameId', 'chncNum'] }),
        mutate({ shotClock: d => {
            if (d.actionType === 'rebound' && ['defensive', 'defensivedeadball'].includes(d.subType)) { return 30; // defensive rebounds reset shot clock to 30 seconds
            } else if (d.actionType === 'rebound' && ['offensive', 'offensivedeadball'].includes(d.subType)) { return Math.min(30, 31 - (d.secsIntoGame - d.possStart)); // orb in video takes place ~1 secs after pbp "clock"
            } else if (d.actionType === 'foul' && d.qualifiers.includes('shooting')) { return Math.min(30, 31 - (d.secsIntoGame - d.possStart)); // shooting fouls in video also ~1 sec earlier than what "clock" shows
            } else if (['2pt', '3pt', 'turnover', 'steal', 'foul', 'foulon'].includes(d.actionType)) { return Math.min(30, 31 - (d.secsIntoGame - d.possStart)); // shots/steals/tov in video take place ~1 sec earlier than what "clock" shows (well now this is wrong following ORBs...)
            } else { return 30 - d.secsIntoGame - d.possStart; }
        } }),
        mutate({ resetTo20: d => {
            if (d.actionType === 'foul' && d.subType === 'personal' && !d.qualifiers.includes('shooting') && !d.qualifiers.includes('oneandone') && !d.qualifiers.includes('freethrow') && Math.min(30, 31 - (d.secsIntoGame - d.possStart)) < 20) { return 1; // 1: TRUE, non-shooting defensive fouls with shot clock < 20 reset to 20
            } else if (d.actionType === 'rebound' && ['offensive', 'offensivedeadball'].includes(d.subType)) { return 1; // any offensive rebounds resets to 20
            } else { return null; }
        } })

        // PICK UP FROM HERE
        // mutate({ resetFrom: d => {
        //     if (d.actionType === 'foul' && d.subType === 'personal' && !d.qualifiers.includes('shooting') && !d.qualifiers.includes('oneandone') && !d.qualifiers.includes('freethrow') && Math.min(30, 31 - (d.secsIntoGame - d.possStart)) < 20) { return 1 // 1: TRUE, non-shooting defensive fouls with shot clock < 20 reset to 20
        //     } else if (d.actionType === 'rebound' && ['offensive', 'offensivedeadball'].includes(d.subType)) { return 1; // any offensive rebounds resets to 20
        //     } else { return null; }
        // } })
    );

    //     ,case
    //       when a.actionType = 'foul' and a.subType = 'personal' and not regexp_contains(a.qualifiers, 'shooting|oneandone|freethrow') and least(30, 31 - (a.secsIntoGame - b.secsInStart)) < 20 then 1 -- non-shooting defensive fouls with shot clock < 20 reset to 20
    //       when a.actionType = 'rebound' and a.subType in ('offensive', 'offensivedeadball') then 1 -- any offensive rebounds resets to 20
    //       else null
    //     end as resetTo20
    //     ,case
    //       when a.actionType = 'foul' and a.subType = 'personal' and not regexp_contains(a.qualifiers, 'shooting|oneandone|freethrow') and least(30, 31 - (a.secsIntoGame - b.secsInStart)) < 20 then a.secsIntoGame
    //       when a.actionType = 'rebound' and a.subType in ('offensive', 'offensivedeadball') then a.secsIntoGame -- any offensive rebound resets to 20
    //       else null
    //     end as resetFrom
    //     ,b.secsInStart as possStart
    //     ,b.secsInEnd as possEnd
    //     ,c.secsInStart as chncStart
    //     ,c.secsInEnd as chncEnd
    //   from with_start_types_stretched as a
    //   left join with_poss_secs_start_fixed as b on a.gameId = b.gameId and a.possNum = b.possNum
    //   left join with_chnc_secs_start_fixed as c on a.gameId = c.gameId and a.chncNum = c.chncNum
    // ),
    // -- adjust foulon, FT1, FT2 for ~1 sec like we are doing in the SFL row above...

    // -- M2) Handle shot clock reset to 20s for ORB, Foul
    //   -- not handling backcourt fouls reset to 30 secs, because "side" is missing in 40% of PBP data and is not simple to correct
    //   -- no way to identify kicked balls reset to 20 secs :(
    // with_reset20_identifiers as (
    //   select
    //     * except(resetTo20, resetFrom)
    //     -- originally thought "rows between current row and unbounded following" for "this row and all rows afterwards in partition", but that is apparently not the case...
    //     ,max(resetTo20) over (partition by gameId, possNum order by actionNumber asc rows between unbounded preceding and current row) as resetTo20
    //     ,max(resetFrom) over (partition by gameId, possNum order by actionNumber asc rows between unbounded preceding and current row) as resetFrom
    //   from pbp_with_shot_clock
    // ),
    // with_shot_clock_reset_to_20 as (
    //   select
    //     * except (shotClock)
    //     ,case
    //       when resetTo20 = 1 then least(20, 21 - (secsIntoGame - resetFrom)) -- eveything in video takes place ~1 sec earlier than what "clock" shows
    //       else shotClock
    //     end as shotClock
    //   from with_reset20_identifiers
    // ),

    // -- M3) adjust shot clock for:
    //   -- foul-related actions at exact same time and in same chance
    //   -- previousAction assist, rebounds
    // grouped_by_secs_in as (
    //   select
    //     gameId
    //     ,chncNum
    //     ,secsIntoGame
    //     ,string_agg(actionType, ',') as allTypes
    //     ,string_agg(qualifiers, ',') as allQualifiers
    //     ,max(shotClock) as maxShotClock
    //   from with_shot_clock_reset_to_20
    //   group by 1,2,3
    // ),
    // with_shot_clock_fixed_for_actiontypes as (
    //   select
    //     a.* except(shotClock)
    //     ,case
    //       -- when shockClock > secsLeft then
    //       when a.actionType in ('foulon', 'timeout', 'freethrow') and regexp_contains(b.allTypes, 'foul') and regexp_contains(b.allQualifiers, 'shooting') then maxShotClock
    //       when a.actionType = 'rebound' and c.actionType in ('2pt', '3pt', 'freethrow') then c.shotClock
    //       when a.actionType = 'assist' and c.actionType in ('2pt', '3pt') then c.shotClock
    //       when a.actionType = 'block' and c.actionType in ('2pt', '3pt') then c.shotClock
    //       else a.shotClock
    //     end as shotClock
    //   from with_shot_clock_reset_to_20 as a -- _fixed_for_20s_reset
    //   left join grouped_by_secs_in as b
    //     on a.gameId = b.gameId and a.chncNum = b.chncNum and a.secsIntoGame = b.secsIntoGame
    //   left join with_shot_clock_reset_to_20 as c
    //     on a.gameId = c.gameId and a.previousAction = c.actionNumber
    // ),


    // Sort And Return
    enhPbp = tidy(enhPbp, arrange(['secsIntoGame', 'actionNumber']));
    // console.log('enhPbp output: ', enhPbp);
    return enhPbp;
}

export function enhRawGames(rawGames, gameInfo) {
    // console.log('enhRawGames params:', { rawGames, gameInfo });
    //      rawGames: array with raw game infos fetched from GS API
    //      gameInfo: gs__game_info for the game from CBB DB, used for win/loss records (UPDATE TO ARRAY OF GAMES)

    const mapToHome = (d, teamStat) => {
        if (d.numCompetitors === 0) { return 'TBD'; } // handle no competitors [] array
        return d.isHomeCompetitor1 === 1 ? d.competitors[0][teamStat] : d.competitors[1][teamStat];
    };
    const mapLiveScores = (d, homeAway) => {
        return homeAway === 'home'
            ? (d.live && d.live.scores ? d.live.scores['1'] : 0)
            : (d.live && d.live.scores ? d.live.scores['2'] : 0);
    };

    const mapToAway = (d, teamStat) => {
        if (d.numCompetitors === 0) { return 'TBD'; } // handle no competitors [] array
        return d.isHomeCompetitor1 === 0 ? d.competitors[0][teamStat] : d.competitors[1][teamStat];
    };
    const mapToHomeAnd01Tf = (d, teamStat) => {
        if (d.numCompetitors === 0) { return true; } // handle no competitors [] array
        if (d.isHomeCompetitor1 === 1 && d.competitors[0][teamStat] === 1) { return true; }
        if (d.isHomeCompetitor1 === 1 && d.competitors[0][teamStat] === 0) { return false; }
        if (d.isHomeCompetitor2 === 1 && d.competitors[1][teamStat] === 1) { return true; }
        if (d.isHomeCompetitor2 === 1 && d.competitors[1][teamStat] === 0) { return false; }
        return null;
    };
    const mapToAwayAnd01Tf = (d, teamStat) => {
        if (d.numCompetitors === 0) { return false; } // handle no competitors [] array
        if (d.isHomeCompetitor1 === 0 && d.competitors[0][teamStat] === 1) { return true; }
        if (d.isHomeCompetitor1 === 0 && d.competitors[0][teamStat] === 0) { return false; }
        if (d.isHomeCompetitor2 === 0 && d.competitors[1][teamStat] === 1) { return true; }
        if (d.isHomeCompetitor2 === 0 && d.competitors[1][teamStat] === 0) { return false; }
        return null;
    };

    // console.log('getTeamGameStats params: ', { rawTps });
    //      rawPps: the raw player-period summaries from Genius Sports API
    //      This function doubles to handle gs__enh_team_period_summaries + gs__team_period_stats (except at game-level, not period-level)

    // Handle missing data + hardcopy (return [] if no data)
    if (typeof rawGames === 'undefined' || rawGames === null) { return []; }
    if (rawGames.length === 0) { return []; }
    let enhGames = JSON.parse(JSON.stringify(rawGames));

    // Handle GS matchesLive endpoint (Data has "live" object with live game info. Not Streaming, this is REST.)
    let isFromMatchesLive = enhGames.filter(row => row.live).length >= 0;

    // // // NO, we keep these and we handle missing competitors array (add TBDs) ===
    // // Filter games missing "competitors" array (return [] if no competitors in any games?)
    // enhGames = tidy(enhGames, filter(d => d.competitors.length === 2));
    // if (enhGames.length === 0) { return []; }

    // Filter, Keep & Rename Columns
    enhGames = tidy(enhGames,
        // for handling missing competitors [] array
        mutate({ numCompetitors: d => d.competitors.length }),
        // key fields
        mutate({ _id: 'matchId' }),
        rename({ matchId: 'gameId' }),
        rename({ matchStatus: 'gameStatus' }),
        mutate({ gameDate: d => d.matchTime.slice(0, 10) }),
        rename({ matchTime: 'gameTime' }),
        rename({ matchTimeUtc: 'gameTimeUtc' }), // or UTC, instead of Utc?
        rename({ matchDataUpdated: 'gameDataUpdated' }),
        rename({ matchType: 'gameType' }),
        mutate({ isLiveStream: d => d.liveStream === 1 ? true : false }),
        mutate({ isNeutral: d => d.atNeutralValue === 1 ? true : false }),
        mutate({ isHomeCompetitor1: d => d.competitors[0] ? d.competitors[0].isHomeCompetitor : null }),
        mutate({ isHomeCompetitor2: d => d.competitors[1] ? d.competitors[1].isHomeCompetitor : null }),
        // home team columns
        mutate({ homeMarket: d => mapToHome(d, 'teamName') }),
        mutate({ homeScore: d => mapToHome(d, 'scoreString') }),
        mutate({ didHomeWin: d => mapToHomeAnd01Tf(d, 'resultPlacing') }),
        mutate({ didHomeDraw: d => mapToHomeAnd01Tf(d, 'isDrawn') }),
        mutate({ homeId: d => mapToHome(d, 'teamId') }),
        mutate({ homeName: d => mapToHome(d, 'teamNickname') }),
        mutate({ homeConferenceId: d => mapToHome(d, 'conferenceId') }),
        mutate({ homeDivisionId: d => mapToHome(d, 'divisionId') }),
        mutate({ homeIsExhib: d => mapToHomeAnd01Tf(d, 'isExhibition') }),
        // away team columns
        mutate({ awayMarket: d => mapToAway(d, 'teamName') }),
        mutate({ awayScore: d => mapToAway(d, 'scoreString') }),
        mutate({ didAwayWin: d => mapToAwayAnd01Tf(d, 'resultPlacing') }),
        mutate({ didAwayDraw: d => mapToAwayAnd01Tf(d, 'isDrawn') }),
        mutate({ awayId: d => mapToAway(d, 'teamId') }),
        mutate({ awayName: d => mapToAway(d, 'teamNickname') }),
        mutate({ awayConferenceId: d => mapToAway(d, 'conferenceId') }),
        mutate({ awayDivisionId: d => mapToAway(d, 'divisionId') }),
        mutate({ awayIsExhib: d => mapToAwayAnd01Tf(d, 'isExhibition') }),
        // grab LIVE columns from "live" object
        mutate({ livePeriod: d => d.live ? d.live.period : null }),
        mutate({ livePeriodType: d => d.live ? d.live.periodType : null }),
        mutate({ liveClock: d => d.live ? d.live.clock : null }),
        mutate({ liveClockRunning: d => d.live ? d.live.clockRunning : null }),
        mutate({ liveShotClock: d => d.live ? d.live.shotClock : null }),
        mutate({ liveStatus: d => d.live ? d.live.status : null }),
        mutate({ livePeriodStatus: d => d.live ? d.live.periodStatus : null }),
        // mutate({ liveHomeScore: d => mapLiveScoresToHome(d) }),
        // mutate({ liveAwayScore: d => mapLiveScoresToAway(d) }),
        mutate({ liveHomeScore: d => mapLiveScores(d, 'home') }),
        mutate({ liveAwayScore: d => mapLiveScores(d, 'away') }),
        // keep keeper cols
        select([
            '_id', 'gameId', 'leagueId', 'competitionId', 'gameStatus', 'isNeutral', 'extraPeriodsUsed', 'timezone',
            'gameDate', 'gameTime', 'gametimeUtc', 'timeActual', 'durationActual', 'attendance', 'duration', 'isLiveStream', 'gameType',
            'updated', 'tournamentId', 'gameDataUpdated', 'hostingTeamId', 'fulltimePlayed', 'hasFlaggedActions', 'timeEndActual', 'statsSource',
            'teamId1', 'teamId2',
            'homeMarket', 'homeScore', 'didHomeWin', 'didHomeDraw', 'homeId', 'homeName', 'homeConferenceId', 'homeDivisionId', 'homeIsExhib',
            'awayMarket', 'awayScore', 'didAwayWin', 'didAwayDraw', 'awayId', 'awayName', 'awayConferenceId', 'awayDivisionId', 'awayIsExhib',
            'livePeriod', 'livePeriodType', 'liveClock', 'liveClockRunning', 'liveShotClock', 'liveStatus', 'livePeriodStatus', 'liveHomeScore', 'liveAwayScore'
        ])
    );

    // Derive W/L Records from gameInfo
    //      If gameStatus of rawGames === gameStatus of gameInfo, take records exactly as is.
    //      If COMPLETE on rawGames, incomplete on gameInfo (addScore === true), need to add this game's result to the records from gameInfo.
    let doneInCbb = gameInfo.gameStatus === 'COMPLETE';
    let doneInGs = enhGames[0].gameStatus === 'COMPLETE';
    let addScore = doneInGs && !doneInCbb;

    enhGames = tidy(enhGames, // adding +1 when needed for wins, losses
        mutate({ homeOverallWins: d => gameInfo.homeOverallWins + (addScore ? (d.homeScore > d.awayScore ? 1 : 0) : 0) }),
        mutate({ homeOverallLosses: d => gameInfo.homeOverallLosses + (addScore ? (d.homeScore < d.awayScore ? 1 : 0) : 0) }),
        mutate({ homeConfWins: d => gameInfo.homeConfWins + (addScore ? (d.inConference && (d.homeScore > d.awayScore) ? 1 : 0) : 0) }),
        mutate({ homeConfLosses: d => gameInfo.homeConfLosses + (addScore ? (d.inConference && (d.homeScore < d.awayScore) ? 1 : 0) : 0) }),
        mutate({ awayOverallWins: d => gameInfo.awayOverallWins + (addScore ? (d.awayScore > d.homeScore ? 1 : 0) : 0) }),
        mutate({ awayOverallLosses: d => gameInfo.awayOverallLosses + (addScore ? (d.awayScore < d.homeScore ? 1 : 0) : 0) }),
        mutate({ awayConfWins: d => gameInfo.awayConfWins + (addScore ? (d.inConference && (d.awayScore > d.homeScore) ? 1 : 0) : 0) }),
        mutate({ awayConfLosses: d => gameInfo.awayConfLosses + (addScore ? (d.inConference && (d.awayScore < d.homeScore) ? 1 : 0) : 0) }));

    // console.log('enhGames: ', enhGames);
    return enhGames;
}

export function getTeamGameStats(rawTps) {
    // console.log('getTeamGameStats params: ', { rawTps });
    //      rawPps: the raw player-period summaries from Genius Sports API
    //      This function doubles to handle gs__enh_team_period_summaries + gs__team_period_stats (except at game-level, not period-level)

    // Handle missing data + hardcopy
    if (typeof rawTps === 'undefined' || rawTps === null) { return []; }
    if (rawTps.length === 0) { return []; }
    let enhTps = JSON.parse(JSON.stringify(rawTps));

    // handle streaming data
    let isStreamingApi = rawTps.map(row => row.teamNumber).filter(row => typeof row !== 'undefined').length >= 1;
    if (isStreamingApi === true) {
        enhTps = tidy(enhTps, mutate({ periodNumber: 0 })); // add periodNumber, 0
    }

    // Filter, Keep & Rename Columns
    // note: don't need any of the "period" columns, full game stats (pgs) only, also dropping ortg, drtg, eff
    enhTps = tidy(enhTps,
        filter(d => d.periodNumber === 0),
        mutate({ sMinutes: d => d.sMinutes / 5 }),
        rename({ matchId: 'gameId' }),
        // rename({ sPointsInThePaintMade: 'pitpFgm' }),
        // rename({ sPointsInThePaintAttempted: 'pitpFga' }),
        rename({ sPointsSecondChance: 'scp' }),
        rename({ sReboundsDefensive: 'drb' }),
        rename({ sReboundsOffensive: 'orb' }),
        rename({ sPointsInThePaint: 'pitp' }),
        rename({ sPointsFromTurnovers: 'potov' }),
        rename({ sFreeThrowsAttempted: 'fta' }),
        rename({ sFreeThrowsMade: 'ftm' }),
        rename({ sPointsFastBreak: 'fbpts' }),
        rename({ sReboundsTeamDefensive: 'tmDrb' }),
        rename({ sReboundsTeamOffensive: 'tmOrb' }),
        rename({ sTurnovers: 'tov' }),
        rename({ sThreePointersMade: 'fgm3' }),
        rename({ sTurnoversTeam: 'tmTov' }),
        rename({ sTwoPointersAttempted: 'fga2' }),
        rename({ sTwoPointersMade: 'fgm2' }),
        rename({ sThreePointersAttempted: 'fga3' }),
        rename({ sSteals: 'stl' }),
        rename({ sReboundsDefensiveDeadball: 'deadDrb' }),
        rename({ sReboundsOffensiveDeadball: 'deadOrb' }),
        // rename({ sSecondChancePointsAttempted: 'scpFga' }),
        // rename({ sSecondChancePointsMade: 'scpFgm' }),
        rename({ sFoulsTechnical: 'tf' }),
        rename({ sBiggestScoringRunScore: 'biggestRunScore' }),
        rename({ sBiggestScoringRun: 'biggestRun' }),
        rename({ sAssists: 'ast' }),
        rename({ sBenchPoints: 'benchPts' }),
        rename({ sBiggestLeadScore: 'biggestLeadScore' }),
        rename({ sBiggestLead: 'biggestLead' }),
        rename({ sMinutes: 'mins' }),
        rename({ sTimesScoresLevel: 'timesTied' }),
        rename({ sLeadChanges: 'leadChanges' }),
        rename({ sTimeLeading: 'timeLeading' }),
        rename({ sBlocks: 'blk' }),
        rename({ sBlocksReceived: 'blkd' }),
        rename({ sFoulsOn: 'pfd' }),
        rename({ sFoulsOffensive: 'opf' }),
        rename({ sFoulsPersonal: 'pf' }),
        // rename({ sFastBreakPointsAttempted: 'fbptsFga' }),
        // rename({ sFastBreakPointsMade: 'fbptsFgm' }),
        rename({ sFieldGoalsAttempted: 'fga' }),
        rename({ sFieldGoalsMade: 'fgm' }),
        rename({ sReboundsTotal: 'reb' }),
        rename({ sReboundsTeam: 'tmReb' }),
        rename({ sThreePointersPercentage: 'fg3Pct' }),
        rename({ sTwoPointersPercentage: 'fg2Pct' }),
        rename({ sFieldGoalsPercentage: 'fgPct' }),
        rename({ sPoints: 'ptsScored' }),
        rename({ sFreeThrowsPercentage: 'ftPct' }),
        rename({ sAssistsTurnoverRatio: 'astTov' }),
        rename({ sPointsAgainst: 'ptsAgst' }),
        rename({ teamName: 'teamMarket' }),
        rename({ teamNickname: 'teamName' }),
        select([
            'gameId', 'teamId', 'competitionId', 'leagueId', 'updated', 'scp', 'drb', 'orb', 'pitp', 'potov', 'fta', 'ftm', 'fbpts',
            'tmDrb', 'tmOrb', 'tov', 'fgm3', 'tmTov', 'fga2', 'fgm2', 'fga3', 'stl', 'deadDrb', 'deadOrb', 'tf', 'biggestRunScore',
            'biggestRun', 'benchPts', 'biggestLeadScore', 'biggestLead', 'mins', 'timesTied', 'leadChanges', 'timeLeading', 'blk', 'blkd',
            'pfd', 'opf', 'pf', 'fga', 'fgm', 'reb', 'ast', 'tmReb', 'fg3Pct', 'fg2Pct', 'fgPct', 'ptsScored', 'ftPct', 'astTov', 'ptsAgst',
            'teamMarket', 'teamName'
        ])
    );

    // Not Adding Most Extra Team, Game, Etc. Columns
    let teamIds = [...new Set(enhTps.map(row => row.teamId))];
    enhTps = tidy(enhTps,
        mutate({ teamIdAgst: d => d.teamId === teamIds[0] ? teamIds[1] : teamIds[0] })
    );

    // Not Adding Net Ranking

    // Not Handling Problematic "mins"

    // Replace 0 with null for null stats (scp, pitp, fbpts for livestream == 0)
    enhTps = tidy(enhTps,
        mutate({ biggestRunScore: d => d.isLiveStream === false ? null : d.biggestRunScore }),
        mutate({ biggestRun: d => d.isLiveStream === false ? null : d.biggestRun })
    );

    // Not Handling Team-Record Through Each Game

    // Add ptsP1 - ptsP4 and ptsOt
    let p1Score = tidy(rawTps, filter(d => d.periodType === 'REGULAR' && d.periodNumber === 1), rename({ matchId: 'gameId', sPoints: 'ptsP1' }), select(['gameId', 'teamId', 'ptsP1']));
    let p2Score = tidy(rawTps, filter(d => d.periodType === 'REGULAR' && d.periodNumber === 2), rename({ matchId: 'gameId', sPoints: 'ptsP2' }), select(['gameId', 'teamId', 'ptsP2']));
    let p3Score = tidy(rawTps, filter(d => d.periodType === 'REGULAR' && d.periodNumber === 3), rename({ matchId: 'gameId', sPoints: 'ptsP3' }), select(['gameId', 'teamId', 'ptsP3']));
    let p4Score = tidy(rawTps, filter(d => d.periodType === 'REGULAR' && d.periodNumber === 4), rename({ matchId: 'gameId', sPoints: 'ptsP4' }), select(['gameId', 'teamId', 'ptsP4']));
    let otScore = tidy(rawTps,
        filter(d => d.periodType === 'OVERTIME'),
        rename({ matchId: 'gameId' }),
        groupBy(['gameId', 'teamId'], [
            summarize({ ptsOt: sum('sPoints') })
        ]));

    enhTps = tidy(enhTps,
        leftJoin(p1Score, { by: ['gameId', 'teamId'] }),
        leftJoin(p2Score, { by: ['gameId', 'teamId'] }),
        leftJoin(p3Score, { by: ['gameId', 'teamId'] }),
        leftJoin(p4Score, { by: ['gameId', 'teamId'] }),
        leftJoin(otScore, { by: ['gameId', 'teamId'] }));


    // Grab Opponent Stats
    let enhTpsOppo = tidy(enhTps,
        select(['gameId', 'teamIdAgst', 'orb', 'drb', 'reb', 'fgm', 'fga', 'fga3', 'fta', 'tov']),
        rename({ teamIdAgst: 'teamId' }),
        rename({ orb: 'orbAgst' }),
        rename({ drb: 'drbAgst' }),
        rename({ reb: 'rebAgst' }),
        rename({ fgm: 'fgmAgst' }),
        rename({ fga: 'fgaAgst' }),
        rename({ fga3: 'fga3Agst' }),
        rename({ fta: 'ftaAgst' }),
        rename({ tov: 'tovAgst' })
    );

    enhTps = tidy(enhTps,
        leftJoin(enhTpsOppo, { by: ['gameId', 'teamId'] })
    );

    // Stacking Offense & Defensive Stats
    let teamGameStats = enhTps;
    let teamMap = tidy(teamGameStats, select(['teamId', 'teamName', 'teamMarket']), distinct());

    let oStats = tidy(teamGameStats,
        mutate({ isOffense: true }),
        select(['-conferenceId', '-divisionId', '-isExhib', '-isHome', '-quadAgst', '-netRankAgst'])
    );
    let dStats = tidy(teamGameStats, // for D stats, have to handle flipping teamId/teamIdAgst, and re-assigning teamMarket/teamName
        mutate({ isOffense: false }),
        select(['-conferenceId', '-divisionId', '-isExhib', '-isHome', '-teamMarket', '-teamName', '-quadAgst', '-netRankAgst']),
        mutate({ zed: d => d.teamId }),
        mutate({ teamId: d => d.teamIdAgst }),
        mutate({ teamIdAgst: d => d.zed }),
        select(['-zed']),
        leftJoin(teamMap, { by: { teamId: 'teamId' } })
    );
    teamGameStats = [...oStats, ...dStats];

    // keep these for troubleshooting score-is-flipped issue on the Streaming page...
    // console.log('check stats full: ', { enhTps, enhTpsOppo, oStats, dStats });
    // console.log('check stats short: ', {
    //     enhTps: enhTps.map(row => ({ teamId: row.teamId, teamMarket: row.teamMarket, ptsScored: row.ptsScored, ptsAgst: row.ptsAgst, isOffense: row.isOffense })),
    //     enhTpsOppo: enhTpsOppo.map(row => ({ teamId: row.teamId, teamMarket: row.teamMarket, ptsScored: row.ptsScored, ptsAgst: row.ptsAgst, isOffense: row.isOffense })),
    //     oStats: oStats.map(row => ({ teamId: row.teamId, teamMarket: row.teamMarket, ptsScored: row.ptsScored, ptsAgst: row.ptsAgst, isOffense: row.isOffense })),
    //     dStats: dStats.map(row => ({ teamId: row.teamId, teamMarket: row.teamMarket, ptsScored: row.ptsScored, ptsAgst: row.ptsAgst, isOffense: row.isOffense }))
    // });

    // Compute Ratio Stats
    teamGameStats = tidy(teamGameStats,
        mutate({ oPoss: d => d.fga + (0.4 * d.fta) - 1.07 * ((d.orb / (d.orb + d.drbAgst)) || 0) * (d.fga - d.fgm) + d.tov }),
        mutate({ dPoss: d => d.fgaAgst + (0.4 * d.ftaAgst) - 1.07 * ((d.orbAgst / (d.orbAgst + d.drb)) || 0) * (d.fgaAgst - d.fgmAgst) + d.tovAgst }),
        mutate({ poss: d => 0.5 * (d.oPoss + d.dPoss) }),
        mutate({ pace: d => 40 * (d.poss / d.mins) }),
        mutate({ tsa: d => d.fga + (0.44 * d.fta) }),
        mutate({ fga3Rate: d => d.fga3 / d.fga }),
        mutate({ efgPct: d => (d.fgm2 + (1.5 * d.fgm3)) / d.fga }),
        mutate({ tsPct: d => d.ptsScored / (2 * d.tsa) }),
        mutate({ ftaRate: d => d.fta / d.fga }),
        mutate({ ftmRate: d => d.ftm / d.fga }),
        mutate({ astPct: d => d.ast / d.fgm }),
        mutate({ astRatio: d => d.ast / (d.fga + (0.44 * d.fta) + d.ast + d.tov) }),
        mutate({ blkPct: d => d.blk / (d.fgaAgst - d.fga3Agst) }),
        mutate({ stlPct: d => d.stl / d.dPoss }),
        mutate({ rebPct: d => (d.orb + d.drb) / (d.orb + d.drb + d.orbAgst + d.drbAgst) }),
        mutate({ drbPct: d => d.drb / (d.drb + d.orbAgst) }),
        mutate({ orbPct: d => d.orb / (d.orb + d.drbAgst) }),
        mutate({ tovPct: d => d.tov / (d.fga + (0.44 * d.fta) + d.tov) }),
        mutate({ pfEff: d => (d.stl + d.blk) / d.pf }),
        mutate({ stlPerPf: d => d.stl / d.pf }),
        mutate({ blkPerPf: d => d.blk / d.pf }),
        mutate({ fbptsPctPts: d => d.fbpts / d.ptsScored }),
        mutate({ scpPctPts: d => d.scp / d.ptsScored }),
        mutate({ pitpPctPts: d => d.pitp / d.ptsScored }),
        mutate({ ortg: d => 100 * (d.ptsScored / d.poss) }),
        mutate({ drtg: d => 100 * (d.ptsAgst / d.poss) }),
        mutate({ eff: d => (d.ptsScored + d.ast + d.blk + d.stl + d.pfd + d.reb) - (d.tov + d.blkd + d.pf + d.tf + (d.fga2 - d.fgm2) + (d.fga3 - d.fgm3) + (d.fga - d.fgm)) }),
        mutate({ hkmPct: d => d.stlPct + d.blkPct }),
        mutate({ netRtg: d => d.ortg - d.drtg })
    );


    // Pass on Adding More Stuff

    // And Return
    return teamGameStats;
}
export function getEnhPps(rawPps, enhTps) {
    // console.log('getEnhPps params: ', { rawPps, enhTps });

    // Get Enhanced Player Period Stats
    //      rawPps: the raw player-period summaries from Genius Sports API
    //      enhTps: team period summaries

    // Handle missing data + hardcopy
    if (typeof rawPps === 'undefined' || rawPps === null) { return []; }
    if (rawPps.length === 0) { return []; }
    let enhPps = JSON.parse(JSON.stringify(rawPps));

    // Filter, Keep & Rename Columns
    // note: don't need any of the "period" columns, full game stats (pgs) only, also dropping ortg, drtg, eff
    enhPps = tidy(enhPps,
        filter(d => d.periodNumber === 0),
        rename({
            matchId: 'gameId',
            personId: 'playerId',
            shirtNumber: 'jerseyNum',
            playingPosition: 'position',
            sMinutes: 'mins',
            sPointsSecondChance: 'scp',
            sReboundsDefensive: 'drb',
            sPointsInThePaint: 'pitp',
            sPointsFastBreak: 'fbpts',
            sPoints: 'ptsScored',
            sReboundsOffensive: 'orb',
            sTwoPointersMade: 'fgm2',
            sTwoPointersAttempted: 'fga2',
            sTurnovers: 'tov',
            sThreePointersMade: 'fgm3',
            sSteals: 'stl',
            sThreePointersAttempted: 'fga3',
            sFieldGoalsAttempted: 'fga',
            sFieldGoalsMade: 'fgm',
            sBlocksReceived: 'blkd',
            sBlocks: 'blk',
            sAssists: 'ast',
            sFreeThrowsAttempted: 'fta',
            sFreeThrowsMade: 'ftm',
            sFoulsTechnical: 'tf',
            sFoulsPersonal: 'pf',
            sFoulsOffensive: 'opf',
            sFoulsOn: 'pfd',
            sReboundsTotal: 'reb',
            sPlusMinusPoints: 'plusMinus',
            personName: 'fullName'
        }),
        mutate({ participated: d => d.participated === 1 ? true : false }),
        mutate({ isPlayer: d => d.isPlayer === 1 ? true : false }),
        mutate({ isTeamOfficial: d => d.isTeamOfficial === 1 ? true : false }),
        mutate({ isStarter: d => d.isStarter === 1 ? true : false }),
        select([
            'gameId', 'playerId', 'teamId', 'competitionId', 'leagueId', 'jerseyNum', 'position', 'participated', 'isPlayer', 'isTeamOfficial', 'isStarter',
            'mins', 'scp', 'drb', 'pitp', 'fbpts', 'ptsScored', 'orb', 'fgm2', 'fga2', 'tov', 'fgm3', 'stl', 'fga3',
            'fga', 'fgm', 'blkd', 'blk', 'ast', 'fta', 'ftm', 'tf', 'pf', 'opf', 'pfd', 'reb', 'plusMinus', 'fullName'
        ])
    );

    // Not Fixing Missing Player Names

    // Fix Positions
    enhPps = tidy(enhPps,
        mutate({ position: d => {
            if (['G', 'GRD', 'g', 'PG', 'SG', 'PG/SG', 'GG', 'Gg'].includes(d.position)) { return 'G'; }
            if (['F', 'F`', 'FWD', 'FRD', 'f', 'SF', 'PF', 'G-F', 'FWS', 'W', 'w', 'G/W', 'F/P', 'F/G', 'G/F'].includes(d.position)) { return 'F'; }
            if (['C', 'C ', 'CEN', 'CEn', 'c', 'F/C', 'f/c', 'PF/C', 'C/F'].includes(d.position)) { return 'F'; }
            if (['', '/', '-', '*', '10', 'P', 'p', ' ', 's'].includes(d.position)) { return null; }
            return d.position;
        } })
    );

    // Not Cleaning Jersey Numbers

    // Not Joining Game, Team, Player information

    // Not Joining Net Rankings

    // Not Fixing IsParticipated

    // Grab Team and Team-Oppo Stats
    let skinnyTps = tidy(enhTps,
        filter(d => d.isOffense === true),
        // select(['-tmTov', '-tmDrb', '-tmOrb', '-tmReb']), // not needed after switching tovTm to tmTov
        mutate({ minsTm: d => 5 * d.mins }),
        rename({ ptsScored: 'ptsScoredTm' }),
        rename({ poss: 'possTm' }),
        rename({ fgm: 'fgmTm' }),
        rename({ fga: 'fgaTm' }),
        rename({ fta: 'ftaTm' }),
        rename({ orb: 'orbTm' }),
        rename({ drb: 'drbTm' }),
        rename({ reb: 'rebTm' }),
        rename({ tov: 'tovTm' }),
        rename({ dPoss: 'dPossTm' }),
        rename({ drbAgst: 'drbAgst' }),
        select([
            'gameId', 'teamId', 'minsTm', 'ptsScoredTm', 'possTm', 'fgmTm', 'fgaTm', 'ftaTm', 'orbTm', 'drbTm', 'rebTm', 'tovTm',
            'ptsAgst', 'dPossTm', 'drbAgst', 'orbAgst', 'rebAgst', 'fgaAgst', 'fga3Agst'
        ])
    );
    enhPps = tidy(enhPps,
        leftJoin(skinnyTps, { by: ['gameId', 'teamId'] })
    );

    // Add Ratio Stats
    enhPps = tidy(enhPps,
        mutate({ minsTmBy5: d => d.minsTm / 5 }),
        mutate({ poss: d => d.possTm * (d.mins / d.minsTmBy5) }),
        mutate({ tsa: d => d.fga + (0.44 * d.fta) }),
        mutate({ fgPct: d => d.fgm / d.fga }),
        mutate({ fg2Pct: d => d.fgm2 / d.fga2 }),
        mutate({ fg3Pct: d => d.fgm3 / d.fga3 }),
        mutate({ efgPct: d => (d.fgm2 + (1.5 * d.fgm3)) / d.fga }),
        mutate({ tsPct: d => d.ptsScored / (2 * d.tsa) }),
        mutate({ fga3Rate: d => d.fga3 / d.fga }),
        mutate({ ftaRate: d => d.fta / d.fga }),
        mutate({ ftmRate: d => d.ftm / d.fga }),
        mutate({ ftPct: d => d.fta > 0 ? d.ftm / d.fta : null }),
        mutate({ blkPct: d => (d.blk * d.minsTmBy5) / (d.mins * (d.fgaAgst - d.fga3Agst)) }),
        mutate({ astPct: d => d.ast / (((d.mins / d.minsTmBy5) * d.fgmTm) - d.fgm) }),
        mutate({ astRatio: d => d.ast / (d.fga + (0.44 * d.fta) + d.ast + d.tov) }),
        mutate({ astTov: d => (d.ast / d.tov) }),
        mutate({ orbPct: d => (d.orb * d.minsTmBy5) / (d.mins * (d.orbTm + d.drbAgst)) }),
        mutate({ drbPct: d => (d.drb * d.minsTmBy5) / (d.mins * (d.drbTm + d.orbAgst)) }),
        mutate({ rebPct: d => (d.reb * d.minsTmBy5) / (d.mins * (d.rebTm + d.rebAgst)) }),
        mutate({ stlPct: d => (d.stl * d.minsTmBy5) / (d.mins * d.dPossTm) }),
        mutate({ tovPct: d => d.tov / (d.fga + (0.44 * d.fta) + d.tov) }),
        mutate({ usagePct: d => ((d.fga + (0.44 * d.fta) + d.tov) * d.minsTmBy5) / (d.mins * (d.fgaTm + (0.44 * d.ftaTm) + d.tovTm)) }),
        mutate({ pfEff: d => (d.stl + d.blk) / d.pf }),
        mutate({ stlPerPf: d => d.stl / d.pf }),
        mutate({ blkPerPf: d => d.blk / d.pf }),
        mutate({ pitpPctPts: d => d.pitp / d.ptsScored }),
        mutate({ scpPctPts: d => d.scp / d.ptsScored }),
        mutate({ fbptsPctPts: d => d.fbpts / d.ptsScored }),
        mutate({ hkmPct: d => d.stlPct + d.blkPct }),
        mutate({ astUsage: d => d.astPct / d.usagePct })
    );

    // Not Adding Extra Stuff

    // And Return
    return enhPps;
}
export function getGameFlowLineups(enhPbp, gameInfo) {
    // console.log('getGameFlowLineups props: ', { enhPbp, gameInfo });
    // enhPbp: Genius Sports raw data transformed into enhPbp via enhRawPbp()
    // For Game Flow Lineups Table

    // A: Copy & Sort
    if (typeof enhPbp === 'undefined') { return []; }
    if (enhPbp.length === 0) { return []; }
    let enhPbpCopy = JSON.parse(JSON.stringify(enhPbp));
    enhPbpCopy = tidy(enhPbpCopy, arrange(['secsIntoGame', 'actionNumber']));

    // Add homeStint, awayStint
    enhPbpCopy = tidy(enhPbpCopy,
        mutateWithSummary({
            homeStint: cumsum(d => d.didHomeChange === true ? 1 : 0),
            awayStint: cumsum(d => d.didAwayChange === true ? 1 : 0),
            prevClock: lag('clock')
        })
    );

    // Unique period number (not 1,2,1,2,3 for 3OT, instead 1,2,3,4,5)
    enhPbpCopy = tidy(enhPbpCopy,
        mutate({ gameFlowPeriod: d => d.periodtype !== 'OVERTIME'
            ? d.periodNumber
            : (allCompetitionIds.male.includes(d.competitionId) ? d.periodNumber + 2 : d.periodNumber + 4)
        })
    );

    // Home GroupBy
    let homeGroupBy = tidy(enhPbpCopy,
        mutate({ sStart: d => d.prevSecsIntoGame === null ? d.secsIntoGame : d.prevSecsIntoGame }),
        mutate({ cStart: d => !d.prevClock ? d.clock.substr(0, 5) : d.prevClock.substr(0, 5) }),
        mutate({ cEnd: d => d.clock.substr(0, 5) }),
        groupBy(['gameId', 'homeId', 'homeStint', 'homeLineupId', 'periodNumber', 'gameFlowPeriod'], [
            summarize({
                sStart: min('sStart'),
                sEnd: max('secsIntoGame'),
                cStart: max('cStart'),
                cEnd: min('cEnd'),
                secs: sum('actionDuration'),
                ptsScored: sum('homePts'),
                ptsAgst: sum('awayPts'),
                fgm: n({ predicate: d => d.teamId === d.homeId && ['2pt', '3pt'].includes(d.actionType) && d.success === true }),
                fga: n({ predicate: d => d.teamId === d.homeId && ['2pt', '3pt'].includes(d.actionType) }),
                fgm2: n({ predicate: d => d.teamId === d.homeId && ['2pt'].includes(d.actionType) && d.success === true }),
                fga2: n({ predicate: d => d.teamId === d.homeId && ['2pt'].includes(d.actionType) }),
                fgm3: n({ predicate: d => d.teamId === d.homeId && ['3pt'].includes(d.actionType) && d.success === true }),
                fga3: n({ predicate: d => d.teamId === d.homeId && ['3pt'].includes(d.actionType) }),
                ftm: n({ predicate: d => d.teamId === d.homeId && ['freethrow'].includes(d.actionType) && d.success === true }),
                fta: n({ predicate: d => d.teamId === d.homeId && ['freethrow'].includes(d.actionType) }),
                ast: n({ predicate: d => d.teamId === d.homeId && ['assist'].includes(d.actionType) }),
                stl: n({ predicate: d => d.teamId === d.homeId && ['steal'].includes(d.actionType) }),
                tov: n({ predicate: d => d.teamId === d.homeId && ['turnover'].includes(d.actionType) }),
                drb: n({ predicate: d => d.teamId === d.homeId && ['rebound'].includes(d.actionType) && d.subType === 'defensive' }),
                orb: n({ predicate: d => d.teamId === d.homeId && ['rebound'].includes(d.actionType) && d.subType === 'offensive' }),
                fgmAgst: n({ predicate: d => d.teamId === d.awayId && ['2pt', '3pt'].includes(d.actionType) && d.success === true }),
                fgaAgst: n({ predicate: d => d.teamId === d.awayId && ['2pt', '3pt'].includes(d.actionType) }),
                fgm2Agst: n({ predicate: d => d.teamId === d.awayId && ['2pt'].includes(d.actionType) && d.success === true }),
                fga2Agst: n({ predicate: d => d.teamId === d.awayId && ['2pt'].includes(d.actionType) }),
                fgm3Agst: n({ predicate: d => d.teamId === d.awayId && ['3pt'].includes(d.actionType) && d.success === true }),
                fga3Agst: n({ predicate: d => d.teamId === d.awayId && ['3pt'].includes(d.actionType) }),
                ftaAgst: n({ predicate: d => d.teamId === d.awayId && ['freethrow'].includes(d.actionType) }),
                astAgst: n({ predicate: d => d.teamId === d.awayId && ['assist'].includes(d.actionType) }),
                tovAgst: n({ predicate: d => d.teamId === d.awayId && ['turnover'].includes(d.actionType) }),
                stlAgst: n({ predicate: d => d.teamId === d.awayId && ['steal'].includes(d.actionType) }),
                drbAgst: n({ predicate: d => d.teamId === d.awayId && ['rebound'].includes(d.actionType) && d.subType === 'defensive' }),
                orbAgst: n({ predicate: d => d.teamId === d.awayId && ['rebound'].includes(d.actionType) && d.subType === 'offensive' })
            })
        ]),
        mutate({ netPts: d => d.ptsScored - d.ptsAgst }),
        mutateWithSummary({ flowStint: rowNumber({ startAt: 0 }) }),
        rename({ homeId: 'teamId' }),
        rename({ homeStint: 'stint' }),
        rename({ homeLineupId: 'lineupId' })
    );

    // Home GroupBy
    let awayGroupBy = tidy(enhPbpCopy,
        mutate({ sStart: d => d.prevSecsIntoGame === null ? d.secsIntoGame : d.prevSecsIntoGame }),
        mutate({ cStart: d => !d.prevClock ? d.clock.substr(0, 5) : d.prevClock.substr(0, 5) }),
        mutate({ cEnd: d => d.clock.substr(0, 5) }),
        groupBy(['gameId', 'awayId', 'awayStint', 'awayLineupId', 'periodNumber', 'gameFlowPeriod'], [
            summarize({
                sStart: min('sStart'),
                sEnd: max('secsIntoGame'),
                cStart: max('cStart'),
                cEnd: min('cEnd'),
                secs: sum('actionDuration'),
                ptsScored: sum('awayPts'),
                ptsAgst: sum('homePts'),
                fgm: n({ predicate: d => d.teamId === d.awayId && ['2pt', '3pt'].includes(d.actionType) && d.success === true }),
                fga: n({ predicate: d => d.teamId === d.awayId && ['2pt', '3pt'].includes(d.actionType) }),
                fgm2: n({ predicate: d => d.teamId === d.awayId && ['2pt'].includes(d.actionType) && d.success === true }),
                fga2: n({ predicate: d => d.teamId === d.awayId && ['2pt'].includes(d.actionType) }),
                fgm3: n({ predicate: d => d.teamId === d.awayId && ['3pt'].includes(d.actionType) && d.success === true }),
                fga3: n({ predicate: d => d.teamId === d.awayId && ['3pt'].includes(d.actionType) }),
                ftm: n({ predicate: d => d.teamId === d.awayId && ['freethrow'].includes(d.actionType) && d.success === true }),
                fta: n({ predicate: d => d.teamId === d.awayId && ['freethrow'].includes(d.actionType) }),
                ast: n({ predicate: d => d.teamId === d.awayId && ['assist'].includes(d.actionType) }),
                stl: n({ predicate: d => d.teamId === d.awayId && ['steal'].includes(d.actionType) }),
                tov: n({ predicate: d => d.teamId === d.awayId && ['turnover'].includes(d.actionType) }),
                drb: n({ predicate: d => d.teamId === d.awayId && ['rebound'].includes(d.actionType) && d.subType === 'defensive' }),
                orb: n({ predicate: d => d.teamId === d.awayId && ['rebound'].includes(d.actionType) && d.subType === 'offensive' }),
                fgmAgst: n({ predicate: d => d.teamId === d.homeId && ['2pt', '3pt'].includes(d.actionType) && d.success === true }),
                fgaAgst: n({ predicate: d => d.teamId === d.homeId && ['2pt', '3pt'].includes(d.actionType) }),
                fgm2Agst: n({ predicate: d => d.teamId === d.homeId && ['2pt'].includes(d.actionType) && d.success === true }),
                fga2Agst: n({ predicate: d => d.teamId === d.homeId && ['2pt'].includes(d.actionType) }),
                fgm3Agst: n({ predicate: d => d.teamId === d.homeId && ['3pt'].includes(d.actionType) && d.success === true }),
                fga3Agst: n({ predicate: d => d.teamId === d.homeId && ['3pt'].includes(d.actionType) }),
                ftaAgst: n({ predicate: d => d.teamId === d.homeId && ['freethrow'].includes(d.actionType) }),
                astAgst: n({ predicate: d => d.teamId === d.homeId && ['assist'].includes(d.actionType) }),
                tovAgst: n({ predicate: d => d.teamId === d.homeId && ['turnover'].includes(d.actionType) }),
                stlAgst: n({ predicate: d => d.teamId === d.homeId && ['steal'].includes(d.actionType) }),
                drbAgst: n({ predicate: d => d.teamId === d.homeId && ['rebound'].includes(d.actionType) && d.subType === 'defensive' }),
                orbAgst: n({ predicate: d => d.teamId === d.homeId && ['rebound'].includes(d.actionType) && d.subType === 'offensive' })
            })
        ]),
        mutate({ netPts: d => d.ptsScored - d.ptsAgst }),
        mutateWithSummary({ flowStint: rowNumber({ startAt: 0 }) }),
        rename({ awayId: 'teamId' }),
        rename({ awayStint: 'stint' }),
        rename({ awayLineupId: 'lineupId' })
    );

    // Stack Home & Away Lineup Flow
    let lineupFlow = [...homeGroupBy, ...awayGroupBy];

    // Add Ratio Stats
    lineupFlow = tidy(lineupFlow,
        mutate({ cDiff: d => `${String(Math.floor(d.secs / 60)).padStart(2, '0')}:${String(Math.round(d.secs % 60, 0)).padStart(2, '0')}` }),
        mutate({ reb: d => d.orb + d.drb }),
        mutate({ rebAgst: d => d.orbAgst + d.drbAgst }),
        mutate({ fg2Pct: d => d.fgm2 / d.fga2 }),
        mutate({ fg3Pct: d => d.fgm3 / d.fga3 }),
        mutate({ ftPct: d => d.ftm / d.fta }),
        mutate({ oPoss: d => d.fga + (0.4 * d.fta) - (1.07 * ((d.orb / (d.orb + d.drbAgst)) || 0) * (d.fga - d.fgm)) + d.tov }),
        mutate({ dPoss: d => d.fgaAgst + (0.4 * d.ftaAgst) - (1.07 * ((d.orbAgst / (d.orbAgst + d.drb)) || 0) * (d.fgaAgst - d.fgmAgst)) + d.tovAgst }),
        mutate({ avgPoss: d => (d.oPoss + d.dPoss) / 2 }),
        mutate({ oppp: d => d.ptsScored / d.oPoss }),
        mutate({ dppp: d => d.ptsAgst / d.dPoss }),
        mutate({ netppp: d => d.oppp - d.dppp }),
        mutate({ isQualified: d => d.oPoss + d.dPoss >= 5 ? true : false }),
        mutate({ rowId: d => `${d.gameId}-${d.teamId}-${d.flowStint}` })
    );

    // Filter Blank Rows
    lineupFlow = tidy(lineupFlow,
        filter(d => d.lineupId !== null && (d.ptsScored !== 0 || d.ptsAgst !== 0 || (d.secs !== 0 && d.secs !== null && d.sStart >= 0)))
    );


    // Not Joining Team/Game Info, or Percentiles, Yet

    // And Return
    // console.log('lineupFlow Final: ', lineupFlow);
    return lineupFlow;
}
export function getGameFlowPlayers(gameFlowLineups, gameInfo, enhPbp) {
    // console.log('getGameFlowLineups Params: ', { gameFlowLineups, gameInfo, enhPbp });
    // For Game Flow Lineups Table

    // Copy & Sort
    if (typeof gameFlowLineups === 'undefined') { return []; }
    if (gameFlowLineups.length === 0) { return []; }
    let gameFlowLineupsCopy = JSON.parse(JSON.stringify(gameFlowLineups));

    // Split Lineup ID
    gameFlowLineupsCopy = tidy(gameFlowLineupsCopy,
        mutate({ pId1: d => parseInt(d.lineupId.split('-')[0], 10) }),
        mutate({ pId2: d => parseInt(d.lineupId.split('-')[1], 10) }),
        mutate({ pId3: d => parseInt(d.lineupId.split('-')[2], 10) }),
        mutate({ pId4: d => parseInt(d.lineupId.split('-')[3], 10) }),
        mutate({ pId5: d => parseInt(d.lineupId.split('-')[4], 10) })
    );

    // Stack By 5 Players
    let stackedByPlayer = [
        ...tidy(gameFlowLineupsCopy, rename({ pId1: 'playerId' }), mutate({ pos: 1 }), select(['-pId1', '-pId2', '-pId3', '-pId4', '-pId5'])),
        ...tidy(gameFlowLineupsCopy, rename({ pId2: 'playerId' }), mutate({ pos: 2 }), select(['-pId1', '-pId2', '-pId3', '-pId4', '-pId5'])),
        ...tidy(gameFlowLineupsCopy, rename({ pId3: 'playerId' }), mutate({ pos: 3 }), select(['-pId1', '-pId2', '-pId3', '-pId4', '-pId5'])),
        ...tidy(gameFlowLineupsCopy, rename({ pId4: 'playerId' }), mutate({ pos: 4 }), select(['-pId1', '-pId2', '-pId3', '-pId4', '-pId5'])),
        ...tidy(gameFlowLineupsCopy, rename({ pId5: 'playerId' }), mutate({ pos: 5 }), select(['-pId1', '-pId2', '-pId3', '-pId4', '-pId5']))
    ];

    // Add Lagged Variables
    stackedByPlayer = tidy(stackedByPlayer, arrange((a, b) => a.teamId === b.teamId ? (a.pos === b.pos ? a.flowStint - b.flowStint : a.pos - b.pos) : a.teamId > b.teamId));
    stackedByPlayer = stackedByPlayer.map((row, idx, array) => {
        return {
            ...row,
            didPosChange: idx === 0 ? true : row.pos !== array[idx - 1].pos,
            didPeriodChange: idx === 0 ? true : row.gameFlowPeriod !== array[idx - 1].gameFlowPeriod,
            didPlayerIdChange: idx === 0 ? true : row.playerId !== array[idx - 1].playerId
        };
    });

    stackedByPlayer = tidy(stackedByPlayer,
        mutateWithSummary({ playerStint: cumsum(d => d.didPosChange || d.didPlayerIdChange || d.didPeriodChange ? 1 : 0) })
    );

    let groupedByStint = tidy(stackedByPlayer,
        groupBy(['gameId', 'playerStint', 'pos', 'playerId', 'teamId'], [
            summarize({
                flowStints: d => d.join('-'),
                // ,string_agg(cast(flowStint as string), '-') as flowStints
                cStart: max('cStart'),
                cEnd: min('cEnd'),
                sStart: min('sStart'),
                sEnd: max('sEnd'),
                gameFlowPeriod: min('gameFlowPeriod'),
                periodNumber: min('periodNumber'),
                secs: sum('secs'),
                ptsScored: sum('ptsScored'),
                ptsAgst: sum('ptsAgst')
            })
        ]),
        mutate({ netPts: d => d.ptsScored - d.ptsAgst }),
        mutate({ cDiff: d => `${String(Math.floor(d.secs / 60)).padStart(2, '0')}:${String(Math.round(d.secs % 60, 0)).padStart(2, '0')}` })
    );

    // Did Not Add Most team, player, game, divisionId info
    groupedByStint = tidy(groupedByStint,
        mutate({ competitionId: gameInfo.competitionId })
    );

    // Left Join FullNames (use enhPbp to grab + join)
    let playersArray = tidy(enhPbp,
        select(['playerId', 'fullName']),
        filter(d => d.playerId > 0),
        distinct(['playerId', 'fullName'])
    );

    // console.log('playersArray: ', { enhPbp, playersArray })
    groupedByStint = tidy(groupedByStint,
        leftJoin(playersArray, { by: 'playerId' })
    );

    // And Return
    // console.log('playerFlow output: ', { playersArray, groupedByStint, stackedByPlayer });
    return groupedByStint;
}
export function getStreaksMargins(enhPbp, homeInfosObj, awayInfosObj) {
    // console.log('getStreaksMargins params: ', { enhPbp, homeInfosObj, awayInfosObj });
    // enhPbp: Genius Sports raw data transformed into enhPbp via enhRawPbp()

    // A: Params Handling
    if (typeof enhPbp === 'undefined') { return []; }
    if (enhPbp.length === 0) { return []; }
    if (typeof homeInfosObj === 'undefined' || typeof awayInfosObj === 'undefined') { return []; }

    // Grab Columns Needed From PBP Data
    let pbpKeeperCols = ['actionNumber', 'actionType', 'subType', 'competitionId', 'gameId', 'gameDate', 'homeId', 'awayId', 'secsIntoGame', 'homeScore', 'awayScore'];
    let pbpSkinny = tidy(enhPbp, select(pbpKeeperCols));

    // Compute Added Metrics
    pbpSkinny = tidy(pbpSkinny,
        mutate({ homeMargin: d => d.homeScore - d.awayScore }),
        arrange(['secsIntoGame', 'actionNumber']),
        mutateWithSummary({ prevHomeMargin: lag('homeMargin') }),
        mutate({ homeMarginDelta: d => d.homeMargin - d.prevHomeMargin }),
        mutate({ streakDirection: d => d.homeMarginDelta === 0 ? 0 : (d.homeMarginDelta > 0 ? 1 : -1) }),
        filter(d => !(d.actionType === 'game' && d.subType === 'start')),
        filter(d => d.homeMarginDelta !== 0),
        mutateWithSummary({ prevStreakDirection: lag('streakDirection') }),
        mutate({ didStreakDirChange: d => d.streakDirection !== d.prevStreakDirection ? 1 : 0 }),
        mutateWithSummary({ streakNumber: cumsum('didStreakDirChange') })
    );

    // console.log('pbpSkinny: ', pbpSkinny);
    let streakMargins = tidy(pbpSkinny,
        groupBy(['streakNumber', 'gameId', 'competitionId', 'homeId', 'awayId'], [
            summarize({
                homeStreakSize: sum('homeMarginDelta'),
                tmpMinStreakDirection: min('streakDirection'),
                tmpMaxHomeMargin: max('homeMargin'),
                tmpMinHomeMargin: min('homeMargin')
            })
        ]),
        mutate({ homeMargin: d => d.tmpMinStreakDirection === 1 ? d.tmpMaxHomeMargin : d.tmpMinHomeMargin }),
        select(['-tmpMinStreakDirection', '-tmpMaxHomeMargin', '-tmpMinHomeMargin'])
    );

    // Skip adding PTGC Info

    // Add Primary Color for Each Team
    streakMargins = tidy(streakMargins,
        mutate({ homeHexColor1: homeInfosObj.hexColor1 }),
        mutate({ awayHexColor1: awayInfosObj.hexColor1 })
    );

    // And Return
    // console.log('streakMargins output: ', streakMargins);
    return streakMargins;
}


// ====== Shooting By Zone ======
function sbzWideSummarizeCounts() {
    let zones6 = ['atr2', 'paint2', 'mid2', 'c3', 'atb3', 'heave3'];
    let zones13 = ['lb2', 'rb2', 'le2', 're2', 'lc3', 'rc3', 'lw3', 'rw3', 'tok3'];
    let zones17 = ['behindHoop', 'slp2', 'srp2', 'flp2', 'frp2'];
    let dists7 = ['sht2', 'med2', 'lng2', 'sht3', 'lng3'];

    let summarizeCounts = {
        // gpPbp: n(),
        gpPbp: nDistinct('gameId'),
        // traditional shooting
        fga: n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) }),
        fgm: n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) && d.success === true }),
        fga2: n({ predicate: d => ['2pt'].includes(d.actionType) }),
        fgm2: n({ predicate: d => ['2pt'].includes(d.actionType) && d.success === true }),
        fga3: n({ predicate: d => ['3pt'].includes(d.actionType) }),
        fgm3: n({ predicate: d => ['3pt'].includes(d.actionType) && d.success === true }),
        // for good takes
        fta: n({ predicate: d => d.actionType === 'freethrow' }),
        layupDunks: n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) && d.success === true && (d.subType.includes('layup') || d.subType.includes('dunk')) }),
        // shotContext: half court, transition, putback shooting
        fgaHc: n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) && d.shotContext === 'hc' }),
        fgmHc: n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) && d.shotContext === 'hc' && d.success }),
        fgaTr: n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) && d.shotContext === 'tr' }),
        fgmTr: n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) && d.shotContext === 'tr' && d.success }),
        fgaPb: n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) && d.shotContext === 'pb' }),
        fgmPb: n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) && d.shotContext === 'pb' && d.success }),
        fga2Hc: n({ predicate: d => ['2pt'].includes(d.actionType) && d.shotContext === 'hc' }),
        fgm2Hc: n({ predicate: d => ['2pt'].includes(d.actionType) && d.shotContext === 'hc' && d.success }),
        fga2Tr: n({ predicate: d => ['2pt'].includes(d.actionType) && d.shotContext === 'tr' }),
        fgm2Tr: n({ predicate: d => ['2pt'].includes(d.actionType) && d.shotContext === 'tr' && d.success }),
        fga3Hc: n({ predicate: d => ['3pt'].includes(d.actionType) && d.shotContext === 'hc' }),
        fgm3Hc: n({ predicate: d => ['3pt'].includes(d.actionType) && d.shotContext === 'tr' && d.success }),
        fga3Tr: n({ predicate: d => ['3pt'].includes(d.actionType) && d.shotContext === 'hc' }),
        fgm3Tr: n({ predicate: d => ['3pt'].includes(d.actionType) && d.shotContext === 'tr' && d.success }),
        // assisted shooting
        fgm2A: n({ predicate: d => d.success && d.actionType === '2pt' && d.assisterId !== null }),
        fgm3A: n({ predicate: d => d.success && d.actionType === '3pt' && d.assisterId !== null })
    };

    // FG% and FGA% by zone, all zones
    zones6.forEach(zone => {
        summarizeCounts[`${zone}Fga`] = n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) && d.zones6 === zone });
        summarizeCounts[`${zone}Fgm`] = n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) && d.zones6 === zone && d.success === true });
    });
    zones13.forEach(zone => {
        summarizeCounts[`${zone}Fga`] = n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) && d.zones13 === zone });
        summarizeCounts[`${zone}Fgm`] = n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) && d.zones13 === zone && d.success === true });
    });
    zones17.forEach(zone => {
        summarizeCounts[`${zone}Fga`] = n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) && d.zones17 === zone });
        summarizeCounts[`${zone}Fgm`] = n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) && d.zones17 === zone && d.success === true });
    });
    dists7.forEach(zone => {
        summarizeCounts[`${zone}Fga`] = n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) && d.dists7 === zone });
        summarizeCounts[`${zone}Fgm`] = n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) && d.dists7 === zone && d.success === true });
    });

    // And Return
    // console.log('summarizeCounts: ', summarizeCounts);
    return (summarize(summarizeCounts));
}
function sbzWideMutateRatios(by = 'player-game') {
    // by: one of "player-game", "team-agg"

    let zoneNames = [
        'atr2', 'paint2', 'mid2', 'c3', 'atb3', 'heave3', // zones6
        'lb2', 'rb2', 'le2', 're2', 'lc3', 'rc3', 'lw3', 'rw3', 'tok3', // zones13
        'behindHoop', 'slp2', 'srp2', 'flp2', 'frp2', // zones17
        'sht2', 'med2', 'lng2', 'sht3', 'lng3' // dists7
    ];

    // metrics for all "by" groupings
    let mutateVariables = {
        // basic shooting stats
        fgPct: d => d.fgm / d.fga,
        fg2Pct: d => d.fgm2 / d.fga2,
        fg3Pct: d => d.fgm3 / d.fga3,
        efgPct: d => (d.fgm2 + 1.5 * d.fgm3) / d.fga,
        fga3Rate: d => d.fga3 / d.fga,
        goodTakeRate: d => (d.fga3 + d.atr2Fga + (0.44 * d.fta)) / (d.fga + (0.44 * d.fta)),
        // half court vs transition vs putback stats
        fgPctHc: d => d.fgmHc / d.fgaHc,
        fgPctTr: d => d.fgmTr / d.fgaTr,
        fgPctPb: d => d.fgmPb / d.fgaPb,
        fg2PctHc: d => d.fgm2Hc / d.fga2Hc,
        fg2PctTr: d => d.fgm2Tr / d.fga2Tr,
        fg3PctHc: d => d.fgm3Hc / d.fga3Hc,
        fg3PctTr: d => d.fgm3Tr / d.fga3Tr,
        fgaFreqHc: d => d.fgaHc / (d.fgaHc + d.fgaTr + d.fgaPb),
        fgaFreqTr: d => d.fgaTr / (d.fgaHc + d.fgaTr + d.fgaPb),
        fgaFreqPb: d => d.fgaPb / (d.fgaHc + d.fgaTr + d.fgaPb),
        // assisted shooting metrics
        fgm2AstdPct: d => d.fgm2A / d.fgm2,
        fgm3AstdPct: d => d.fgm3A / d.fgm3
    };

    // FG% and FGA% by zone
    zoneNames.forEach(zone => {
        mutateVariables[`${zone}FgPct`] = d => d[`${zone}Fgm`] / d[`${zone}Fga`];
        mutateVariables[`${zone}FgaFreq`] = d => d[`${zone}Fga`] / d.fga;
    });

    // Return if "player-game"
    if (by === 'player-game') { return (mutateVariables); }


    // Compute Metrics for Agg Stats
    // Basic Ratios
    mutateVariables = {
        ...mutateVariables,
        fgaPg: d => d.fga / d.gpPbp,
        fga2Pg: d => d.fga2 / d.gpPbp,
        fga3Pg: d => d.fga3 / d.gpPbp
    };

    // FGA/G and FGA/40 by Zone
    zoneNames.forEach(zone => {
        mutateVariables[`${zone}FgaPg`] = d => d[`${zone}Fga`] / d.gpPbp;
        mutateVariables[`${zone}FgaP40`] = d => 40 * (d[`${zone}Fga`] / d.minsPbp);
    });

    // And return
    return (mutateVariables);
}
export function computeTeamAggSbzWide({ array }) {
    // console.log('computeTeamAggSbzWide Params:', { array });

    // array:           array of shots from pbp data, fetched from CBB Database (not derived from raw Genius Sports API fetch).
    // notes:           array can be pre-stacked for multiple scopes (with "scope" key added), and pre stacked for pbpShotsAgst for
    //                  isOffense === true & false (with "isOffense" key added). for pbpShotsAgst, teamIdAgst is switched with
    //                  teamId so that all of the pbpShots param should have the same teamId.

    // Handle missing data
    if (typeof array === 'undefined' || array === null) { return []; }
    if (array.length === 0) { return []; }

    // Sum counting stats, and compute ratio stats
    let sbzWide = tidy(array,
        filter(d => d.teamId !== null && d.teamId !== 0),
        groupBy(['teamId', 'scope', 'isOffense'], [sbzWideSummarizeCounts()]), // counts counting stats
        mutate(sbzWideMutateRatios('team-agg')) // compute ratio stats
    );

    // Add additional metrics
    sbzWide = tidy(sbzWide,
        mutate({ isQualified: true })
        // mutate({ }) // if we wanted to add more stuff in here
    );

    // And Return
    return sbzWide;
}
export function computePlayerAggSbzWide({ array }) {
    // console.log('computePlayerAggSbzWide Params:', { array });

    // array:           array of shots from pbp data. fetched from CBB Database (not derived from a raw Genius Sports API fetch).
    // notes:           array can be pre-stacked for multiple scopes (with "scope" key added)

    // Handle missing data
    if (typeof array === 'undefined' || array === null) { return []; }
    if (array.length === 0) { return []; }

    // Sum counting stats, and compute ratio stats
    //      grouping by 'teamId' simply to keep teamId in output data
    let sbzWide = tidy(array,
        filter(d => d.playerId !== null && d.playerId !== 0),
        filter(d => d.teamId !== null && d.teamId !== 0),
        groupBy(['playerId', 'teamId', 'scope'], [sbzWideSummarizeCounts()]), // counts counting stats
        mutate(sbzWideMutateRatios('player-agg'))); // compute ratio stats

    // Add additional metrics
    sbzWide = tidy(sbzWide,
        mutate({ isQualified: true })
        // mutate({ }) // if we wanted to add more stuff in here
    );

    // And Return
    return sbzWide;
}


// ====== PBP Stats ======
function pbpStatsSummarizeCounts() {
    // define zones (no overlap in latter zones)
    let zones6 = ['atr2', 'paint2', 'mid2', 'c3', 'atb3', 'heave3'];
    let zones13 = ['lb2', 'rb2', 'le2', 're2', 'lc3', 'rc3', 'lw3', 'rw3', 'tok3'];
    let zones17 = ['behindHoop', 'slp2', 'srp2', 'flp2', 'frp2'];
    let dists7 = ['sht2', 'med2', 'lng2', 'sht3', 'lng3'];

    // define a few util functions, these help with readability below
    const isFga = (d) => { return ['2pt', '3pt'].includes(d.actionType); };
    const isFga2 = (d) => { return d.actionType === '2pt'; };
    const isFga3 = (d) => { return d.actionType === '3pt'; };
    const isDunk = (d) => { return d.subType === 'dunk'; };
    const isFt = (d) => { return d.actionType === 'freethrow'; };
    const isFoul = (d) => { return d.actionType === 'foul'; };
    const isFoulOn = (d) => { return d.actionType === 'foulon'; };
    const isReb = (d) => { return d.actionType === 'rebound'; };

    // (1) Shooting Summarize Object
    let shotSumObj = {
        // gpPbp: n(),
        gpPbp: nDistinct('gameId'),
        // traditional shooting
        fga: n({ predicate: d => isFga(d) }),
        fgm: n({ predicate: d => isFga(d) && d.success }),
        fga2: n({ predicate: d => isFga2(d) }),
        fgm2: n({ predicate: d => isFga2(d) && d.success }),
        fga3: n({ predicate: d => isFga3(d) }),
        fgm3: n({ predicate: d => isFga3(d) && d.success }),
        dunkFga: n({ predicate: d => isDunk(d) }),
        dunkFgm: n({ predicate: d => isDunk(d) && d.success }),
        // assisted, unassisted shooting
        fgmA: n({ predicate: d => d.success && isFga(d) && d.assisterId !== null }),
        fgm2A: n({ predicate: d => d.success && isFga2(d) && d.assisterId !== null }),
        fgm3A: n({ predicate: d => d.success && isFga3(d) && d.assisterId !== null }),
        dunkFgmA: n({ predicate: d => d.success && isDunk(d) && d.assisterId !== null }),
        fgmU: n({ predicate: d => isFga(d) && d.assisterId === null && d.success }),
        fgm2U: n({ predicate: d => isFga2(d) && d.assisterId === null && d.success }),
        fgm3U: n({ predicate: d => isFga3(d) && d.assisterId === null && d.success }),
        dunkFgmU: n({ predicate: d => isDunk(d) && d.assisterId === null && d.success }),
        // free throw counts
        fta: n({ predicate: d => isFt(d) }),
        fta1: n({ predicate: d => isFt(d) && d.subType.charAt(0) === '1' }),
        fta2: n({ predicate: d => isFt(d) && d.subType.charAt(0) === '2' }),
        fta3: n({ predicate: d => isFt(d) && d.subType.charAt(0) === '3' }),
        ftm: n({ predicate: d => isFt(d) && d.success }),
        ftm1: n({ predicate: d => isFt(d) && d.subType.charAt(0) === '1' && d.success }),
        ftm2: n({ predicate: d => isFt(d) && d.subType.charAt(0) === '2' && d.success }),
        ftm3: n({ predicate: d => isFt(d) && d.subType.charAt(0) === '3' && d.success }),
        layupDunks: n({ predicate: d => isFga(d) && d.success && (d.subType.includes('layup') || d.subType.includes('dunk')) }),
        // fouls committed metrics
        pf: n({ predicate: d => isFoul(d) }),
        opf: n({ predicate: d => isFoul(d) && d.subType === 'offensive' }),
        sfl: n({ predicate: d => isFoul(d) && d.qualifiers.includes('shooting') }),
        // fouls drawn metrics
        pfd: n({ predicate: d => isFoulOn(d) }),
        opfd: n({ predicate: d => isFoulOn(d) && d.foulSubType === 'offensive' }),
        sflDrawn: n({ predicate: d => isFoulOn(d) && d.isSfl }),
        sfl2Drawn: n({ predicate: d => isFoulOn(d) && d.isSfl && (d.is2Ft || (d.is1Ft && d.in2PZone)) }),
        sfl3Drawn: n({ predicate: d => isFoulOn(d) && d.isSfl && (d.is3Ft || (d.is1Ft && !d.in2PZone)) }),
        and1: n({ predicate: d => isFoulOn(d) && d.isSfl && d.is1Ft }),
        and1on2s: n({ predicate: d => isFoulOn(d) && d.isSfl && d.is1Ft && !(d.prev3P || !d.in2PZone) }),
        and1on3s: n({ predicate: d => isFoulOn(d) && d.isSfl && d.is1Ft && (d.prev3P || !d.in2PZone) }),
        fflDrawn: n({ predicate: d => isFoulOn(d) && d.foulSubType === 'personal' && !d.isSfl })
    };

    // FGA, FGM (basic, assisted vs unassisted, by shot clock)
    zones6.forEach(z => {
        shotSumObj[`${z}Fga`] = n({ predicate: d => isFga(d) && d.zones6 === z });
        shotSumObj[`${z}Fgm`] = n({ predicate: d => isFga(d) && d.zones6 === z && d.success });
        shotSumObj[`${z}FgmA`] = n({ predicate: d => isFga(d) && d.zones6 === z && d.success && d.assisterId !== null });
        shotSumObj[`${z}FgmU`] = n({ predicate: d => isFga(d) && d.zones6 === z && d.success && d.assisterId === null });
        shotSumObj[`${z}FgaS01`] = n({ predicate: d => isFga(d) && d.zones6 === z && d.shotClock <= 10 });
        shotSumObj[`${z}FgmS01`] = n({ predicate: d => isFga(d) && d.zones6 === z && d.shotClock <= 10 && d.success });
        shotSumObj[`${z}FgaS12`] = n({ predicate: d => isFga(d) && d.zones6 === z && d.shotClock > 10 && d.shotClock <= 20 });
        shotSumObj[`${z}FgmS12`] = n({ predicate: d => isFga(d) && d.zones6 === z && d.shotClock > 10 && d.shotClock <= 20 && d.success });
        shotSumObj[`${z}FgaS23`] = n({ predicate: d => isFga(d) && d.zones6 === z && d.shotClock > 20 && d.shotClock });
        shotSumObj[`${z}FgmS23`] = n({ predicate: d => isFga(d) && d.zones6 === z && d.shotClock > 20 && d.shotClock && d.success });
    });
    zones13.forEach(z => {
        shotSumObj[`${z}Fga`] = n({ predicate: d => isFga(d) && d.zones13 === z });
        shotSumObj[`${z}Fgm`] = n({ predicate: d => isFga(d) && d.zones13 === z && d.success });
    });
    zones17.forEach(z => {
        shotSumObj[`${z}Fga`] = n({ predicate: d => isFga(d) && d.zones17 === z });
        shotSumObj[`${z}Fgm`] = n({ predicate: d => isFga(d) && d.zones17 === z && d.success });
    });
    dists7.forEach(z => {
        shotSumObj[`${z}Fga`] = n({ predicate: d => isFga(d) && d.dists7 === z });
        shotSumObj[`${z}Fgm`] = n({ predicate: d => isFga(d) && d.dists7 === z && d.success });
    });

    // (2) Assister Summarize Object
    let astSumObj = {
        ast: n(),
        dunkAst: n({ predicate: d => d.subType === 'dunk' }),
        ast2: n({ predicate: d => d.actionType === '2pt' }),
        ast3: n({ predicate: d => d.actionType === '3pt' }),
        atr2Ast: n({ predicate: d => d.zones6 === 'atr2' }),
        paint2Ast: n({ predicate: d => d.zones6 === 'paint2' }),
        mid2Ast: n({ predicate: d => d.zones6 === 'mid2' }),
        atb3Ast: n({ predicate: d => d.zones6 === 'atb3' }),
        c3Ast: n({ predicate: d => d.zones6 === 'c3' })
    };


    // (3) Rebounder Summarize Object (add shotZone into rows where isReb === true)
    zones6.forEach(z => {
        shotSumObj[`${z}Reb`] = n({ predicate: d => isReb(d) && d.shotZone === z && ['offensive', 'defensive'].includes(d.subType) });
        shotSumObj[`${z}Orb`] = n({ predicate: d => isReb(d) && d.shotZone === z && ['offensive'].includes(d.subType) });
        shotSumObj[`${z}Drb`] = n({ predicate: d => isReb(d) && d.shotZone === z && ['defensive'].includes(d.subType) });
    });


    // And Return
    return ({
        shotSummarize: summarize(shotSumObj),
        astSummarize: summarize(astSumObj)
    });
}
function pbpStatsMutateRatios(by = 'player-game') {
    let isTeam = ['team-game', 'team-agg'].includes(by); // isTeam, or isPlayer
    let isPlayer = !isTeam;
    // here we go

    // define zones (non-overlapping)
    let zones6 = ['atr2', 'paint2', 'mid2', 'c3', 'atb3'];
    let zoneNames = [
        'atr2', 'paint2', 'mid2', 'c3', 'atb3', 'heave3', // zones6
        'lb2', 'rb2', 'le2', 're2', 'lc3', 'rc3', 'lw3', 'rw3', 'tok3', // zones13
        'behindHoop', 'slp2', 'srp2', 'flp2', 'frp2', // zones17
        'sht2', 'med2', 'lng2', 'sht3', 'lng3' // dists7
    ];

    // define variables to compute in mutate({ })
    let mutateVariables = {
        // basic shooting stats
        fgPct: d => d.fgm / d.fga,
        fg2Pct: d => d.fgm2 / d.fga2,
        fg3Pct: d => d.fgm3 / d.fga3,
        efgPct: d => (d.fgm2 + 1.5 * d.fgm3) / d.fga,
        fga3Rate: d => d.fga3 / d.fga,
        goodTakeRate: d => (d.fga3 + d.atr2Fga + (0.44 * d.fta)) / (d.fga + (0.44 * d.fta)),
        // % of assists to each zone
        pctAst2: d => isPlayer ? (d.ast2 / d.ast) : (d.fgm2A / d.fgmA),
        pctAst3: d => isPlayer ? (d.ast3 / d.ast) : (d.fgm3A / d.fgmA),
        pctAstDunk: d => isPlayer ? (d.dunkAst / d.ast) : (d.dunkFgma / d.fgmA),
        pctAstAtr2: d => isPlayer ? (d.atr2Ast / d.ast) : (d.atr2FgmA / d.fgmA),
        pctAstPaint2: d => isPlayer ? (d.paint2Ast / d.ast) : (d.paint2FgmA / d.fgmA),
        pctAstMid2: d => isPlayer ? (d.mid2Ast / d.ast) : (d.mid2FgmA / d.fgmA),
        pctAstAtb3: d => isPlayer ? (d.atb3Ast / d.ast) : (d.atb3FgmA / d.fgmA),
        pctAstC3: d => isPlayer ? (d.c3Ast / d.ast) : (d.c3FgmA / d.fgmA),
        // assists to each zone per 40 mins played
        atr2AstP40: d => 40 * (d.atr2Ast / d.minsPbp),
        paint2AstP40: d => 40 * (d.paint2Ast / d.minsPbp),
        mid2AstP40: d => 40 * (d.mid2Ast / d.minsPbp),
        atb3AstP40: d => 40 * (d.atb3Ast / d.minsPbp),
        c3AstP40: d => 40 * (d.c3Ast / d.minsPbp),
        // % of players fgm in each zone that were assisted
        fgmAstdPct: d => d.fgmA / d.fgm,
        fgm2AstdPct: d => d.fgm2A / d.fgm2,
        fgm3AstdPct: d => d.fgm3A / d.fgm3,
        atr2AstdPct: d => d.atr2FgmA / d.atr2Fgm,
        paint2AstdPct: d => d.paint2FgmA / d.paint2Fgm,
        mid2AstdPct: d => d.mid2FgmA / d.mid2Fgm,
        atb3AstdPct: d => d.atb3FgmA / d.atb3Fgm,
        c3AstdPct: d => d.c3FgmA / d.c3Fgm,
        ptsAstdPct: d => d.astdPts / d.ptsScored,
        // free throw percentages
        ftPct: d => d.ftm / d.fta,
        ft1Pct: d => d.ftm1 / d.fta1,
        ft2Pct: d => d.ftm2 / d.fta2,
        ft3Pct: d => d.ftm3 / d.fta3,
        // foul drawing metrics
        sflDrawnPct: d => d.sflDrawn / d.shotAtt,
        sfl2DrawnPct: d => d.sfl2Drawn / d.shotAtt2P,
        sfl3DrawnPct: d => d.sfl2Drawn / d.shotAtt3P,
        fflDrawnPct: d => d.fflDrawn / d.possPbp,
        opfdPct: d => d.opfd / d.possPbp,
        and1Pct: d => d.and1 / d.sflDrawn,
        and1Pct3P: d => d.and1on3s / d.sfl3Drawn
    };

    // FG% and FGA% by zone
    zoneNames.forEach(zone => {
        mutateVariables[`${zone}FgPct`] = d => d[`${zone}Fgm`] / d[`${zone}Fga`];
        mutateVariables[`${zone}FgaFreq`] = d => d[`${zone}Fga`] / d.fga;
    });

    // Return if "player-game"
    if (by === 'player-game') { return (mutateVariables); }


    // Add Rebound By Zone (to team-game, team-agg only)
    if (['team-game', 'team-agg'].includes(by)) {
        zones6.forEach(zone => {
            // convert undefined, NAN to 0
            mutateVariables[`${zone}Reb`] = d => !d[`${zone}Reb`] ? 0 : d[`${zone}Reb`];
            mutateVariables[`${zone}Orb`] = d => !d[`${zone}Orb`] ? 0 : d[`${zone}Orb`];
            mutateVariables[`${zone}Drb`] = d => !d[`${zone}Drb`] ? 0 : d[`${zone}Drb`];
            mutateVariables[`${zone}RebAgst`] = d => !d[`${zone}RebAgst`] ? 0 : d[`${zone}RebAgst`];
            mutateVariables[`${zone}OrbAgst`] = d => !d[`${zone}OrbAgst`] ? 0 : d[`${zone}OrbAgst`];
            mutateVariables[`${zone}DrbAgst`] = d => !d[`${zone}DrbAgst`] ? 0 : d[`${zone}DrbAgst`];
            // compute chances and percentages
            mutateVariables[`${zone}RebChnc`] = d => d[`${zone}Reb`] + d[`${zone}RebAgst`];
            mutateVariables[`${zone}OrbChnc`] = d => d[`${zone}Orb`] + d[`${zone}DrbAgst`];
            mutateVariables[`${zone}DrbChnc`] = d => d[`${zone}Drb`] + d[`${zone}OrbAgst`];
            mutateVariables[`${zone}RebPct`] = d => d[`${zone}Reb`] / d[`${zone}RebChnc`];
            mutateVariables[`${zone}OrbPct`] = d => d[`${zone}Orb`] / d[`${zone}OrbChnc`];
            mutateVariables[`${zone}DrbPct`] = d => d[`${zone}Drb`] / d[`${zone}DrbChnc`];
        });
    }


    // Compute Metrics for Agg Stats
    // Basic Ratios
    mutateVariables = {
        ...mutateVariables,
        fgaPg: d => d.fga / d.gpPbp,
        fga2Pg: d => d.fga2 / d.gpPbp,
        fga3Pg: d => d.fga3 / d.gpPbp
    };

    // FGA/G and FGA/40 by Zone (no poss, hmm...)
    zoneNames.forEach(zone => {
        mutateVariables[`${zone}FgaPg`] = d => d[`${zone}Fga`] / d.gpPbp;
        mutateVariables[`${zone}FgaP40`] = d => 40 * (d[`${zone}Fga`] / d.minsPbp);
    });

    return mutateVariables;
}


export function computePlayerGamePbpStats(enhPbp, enhPps) {
    // console.log('computePlayerGamePbpStats Params:', { enhPbp, enhPps });
    // console.log('shotClock: ', enhPbp.map(row => row.shotClock));

    //      enhPbp: from GS API, data was fetched and transformed into enhPbp via enhRawPbp()
    //      enhPps: from GS API, data was fetched and transformed into enhPps via

    // (0) handle missing data
    if (typeof enhPbp === 'undefined' || enhPbp === null) { return []; }
    if (enhPbp.length === 0) { return []; }

    // (0) load big summarizers, mutators
    const { shotSummarize, astSummarize } = pbpStatsSummarizeCounts();

    // (1) before summing & computing ratios, need to self-join a few metrics onto play-by-play from other actionTypes
    let pbpFouls = tidy(enhPbp,
        filter(d => d.actionType === 'foul'),
        mutate({
            foulSubType: d => d.subType,
            in2PZone: d => ['underbasket', 'inthepaint', 'insiderightwing', 'insideright', 'insidecenter', 'insideleft', 'insideleftwing'].includes(d.zoneGenius),
            prev3P: d => d.prevActionType === '3pt',
            isSfl: d => d.qualifiers.includes('shooting'),
            is1Ft: d => d.qualifiers.includes('1freethrow'),
            is2Ft: d => d.qualifiers.includes('2freethrow'),
            is3Ft: d => d.qualifiers.includes('3freethrow')
        }),
        select(['gameId', 'actionNumber', 'foulSubType', 'in2PZone', 'isSfl', 'is1Ft', 'is2Ft', 'is3Ft']));

    // joins ('foulSubType', 'in2PZone', 'isSfl', 'is1Ft', 'is2Ft', 'is3Ft') onto the data
    let enhEnhPbp = tidy(enhPbp,
        leftJoin(pbpFouls, { by: { gameId: 'gameId', actionNumber: 'previousAction' } }));


    // (2) summarizers, assister counts (where assisterId !== null or 0) and shooter counts
    let assisterCounts = tidy(enhEnhPbp,
        filter(d => d.assisterId !== null && d.assisterId !== 0),
        select(['-playerId']),
        rename({ assisterId: 'playerId' }), // grouping by assisterId
        groupBy(['gameId', 'teamId', 'playerId'], [astSummarize]));

    let shooterCounts = tidy(enhEnhPbp,
        filter(d => d.teamId !== null && d.teamId !== 0),
        groupBy(['playerId', 'teamId', 'gameId'], [shotSummarize]));


    // (3) join together (use PPS to get unique list of players)
    let allPbpPlayers = tidy(enhPps, distinct(['gameId', 'teamId', 'playerId']));
    let allPbpCounts = tidy(allPbpPlayers,
        leftJoin(shooterCounts, { by: ['gameId', 'teamId', 'playerId'] }),
        leftJoin(assisterCounts, { by: ['gameId', 'teamId', 'playerId'] }));


    // (4) replace undefined with 0s (is this the best approach?)
    allPbpCounts.forEach((value, idx, array) => {
        Object.keys(value).forEach(key => {
            array[idx][key] = typeof array[idx][key] === 'undefined' ? 0 : array[idx][key];
        });
    });


    // (5) join mins and other player info (via pps info on players)
    let playerInfos = tidy(enhPps, select(['playerId', 'jerseyNum', 'position', 'mins', 'poss', 'fullName']));
    let pbpStats = tidy(allPbpCounts,
        leftJoin(playerInfos, { by: 'playerId' }),
        rename({ mins: 'minsPbp' }),
        rename({ poss: 'possPbp' }),
        mutate({ isQualified: d => d.minsPbp >= 10 ? true : false })
    );


    // (6) compute all of the ratio stats
    pbpStats = tidy(pbpStats,
        mutate({
            shotAtt: d => d.fga + d.sflDrawn - d.and1,
            shotAtt2P: d => d.fga2 + d.sfl2Drawn - d.and1on2s,
            shotAtt3P: d => d.fga3 + d.sfl3Drawn - d.and1on3s
        }),
        mutate(pbpStatsMutateRatios('player-game')));


    // (7) filter for min minutes, non-zero playerId
    pbpStats = tidy(pbpStats,
        filter(d => d.minsPbp > 0),
        filter(d => d.playerId));

    // and return
    return pbpStats;
}
export function computeTeamGamePbpStats(enhPbp, enhPps) {
    // Handle missing data
    if (typeof enhPbp === 'undefined' || enhPbp === null) { return []; }
    if (enhPbp.length === 0) { return []; }

    // (0) load big summarizers, mutators
    const { shotSummarize, astSummarize } = pbpStatsSummarizeCounts();


    // Rebounding By Zone
    let pbpCopy = JSON.parse(JSON.stringify(enhPbp));
    pbpCopy = tidy(pbpCopy, select(['gameId', 'teamId', 'teamIdAgst', 'actionType', 'subType', 'x', 'y', 'zones6', 'actionNumber', 'previousAction']));

    // Grab Rebounds Only
    let reboundsPbp = tidy(pbpCopy, filter(d => d.actionType === 'rebound'));

    // Join shot coordinates onto reboundsPbp
    let pbpShotCoords = tidy(pbpCopy,
        select(['gameId', 'teamId', 'actionNumber', 'zones6', 'secsIntoGame']),
        rename({ teamId: 'shotTeamId' }),
        rename({ zones6: 'shotZone' }),
        rename({ secsIntoGame: 'shotSecsIn' }));

    // rebounds Pbp has shotTeamId, zoneName;
    reboundsPbp = tidy(reboundsPbp,
        leftJoin(pbpShotCoords, { by: { gameId: 'gameId', actionNumber: 'previousAction' } }));


    let enhPbpEnh = [...enhPbp.filter(row => row.actionType !== 'rebound'), ...reboundsPbp];


    // Compute Lots of MetricsenhPbpEhn
    let pbpStats = tidy(enhPbpEnh,
        filter(d => d.teamId !== null && d.teamId !== 0),
        groupBy(['teamId', 'teamIdAgst', 'gameId'], [shotSummarize]));

    let pbpStatsCopy = JSON.parse(JSON.stringify(pbpStats));
    console.log('pbpStatsCopy', pbpStatsCopy);
    let rebByZoneAgst = tidy(pbpStatsCopy,
        select(['-teamId']),
        rename({ teamIdAgst: 'teamId' }),
        rename({ atr2Reb: 'atr2RebAgst' }),
        rename({ atr2Orb: 'atr2OrbAgst' }),
        rename({ atr2Drb: 'atr2DrbAgst' }),
        rename({ paint2Reb: 'paint2RebAgst' }),
        rename({ paint2Orb: 'paint2OrbAgst' }),
        rename({ paint2Drb: 'paint2DrbAgst' }),
        rename({ mid2Reb: 'mid2RebAgst' }),
        rename({ mid2Orb: 'mid2OrbAgst' }),
        rename({ mid2Drb: 'mid2DrbAgst' }),
        rename({ atb3Reb: 'atb3RebAgst' }),
        rename({ atb3Orb: 'atb3OrbAgst' }),
        rename({ atb3Drb: 'atb3DrbAgst' }),
        rename({ c3Reb: 'c3RebAgst' }),
        rename({ c3Orb: 'c3OrbAgst' }),
        rename({ c3Drb: 'c3DrbAgst' }),
        select(['gameId', 'teamId', 'atr2RebAgst', 'atr2OrbAgst', 'atr2DrbAgst', 'paint2RebAgst', 'paint2OrbAgst', 'paint2DrbAgst', 'mid2RebAgst', 'mid2OrbAgst', 'mid2DrbAgst', 'atb3RebAgst', 'atb3OrbAgst', 'atb3DrbAgst', 'c3RebAgst', 'c3OrbAgst', 'c3DrbAgst']));

    pbpStats = tidy(pbpStats,
        leftJoin(rebByZoneAgst, { by: { gameId: 'gameId', teamId: 'teamId' } }));

    // console.log('rbz situation: ', { reboundsPbp, enhPbpEnh, pbpStats, pbpStatsCopy, rebByZoneAgst });

    let astStats = tidy(enhPbpEnh,
        groupBy(['teamId', 'gameId'], [astSummarize]));


    // (3) join together (use PPS to get unique list of players)
    let allPbpPlayers = tidy(enhPps, distinct(['gameId', 'teamId']));
    let allPbpCounts = tidy(allPbpPlayers,
        leftJoin(pbpStats, { by: ['gameId', 'teamId'] }),
        leftJoin(astStats, { by: ['gameId', 'teamId'] }));


    // (4) replace undefined with 0s (is this the best approach?)
    allPbpCounts.forEach((value, idx, array) => {
        Object.keys(value).forEach(key => {
            array[idx][key] = typeof array[idx][key] === 'undefined' ? 0 : array[idx][key];
        });
    });

    allPbpCounts = tidy(allPbpCounts,
        mutate(pbpStatsMutateRatios('team-game'))); // compute raito stats

    // Stacking Offense & Defensive Stats (Skipping this, need to follow the DBT Code...)
    allPbpCounts = tidy(allPbpCounts, mutate({ isOffense: true }));

    console.log('allPbpCounts: ', allPbpCounts);
    return allPbpCounts;
}


export function getPossessions(enhPbp) {
    // Only works on INDIVIDUAL GAME. would need to order by gameId, group by gameId in a few places to generalize this to 2+ games
    // console.log('enhPbp: ', enhPbp);
    // enhPbp: Genius Sports raw data transformed into enhPbp via enhRawPbp()

    // Handle missing data + hardcopy
    if (typeof enhPbp === 'undefined' || enhPbp === null) { return []; }
    if (enhPbp.length === 0) { return []; }
    let enhPbpEnh = JSON.parse(JSON.stringify(enhPbp));

    // Filter ActionTypes
    enhPbpEnh = tidy(enhPbpEnh,
        filter(d => !['clock', 'substitution'].includes(d.actionType)), // dupe, but fine. already done in enhRawPbp()
        filter(d => !['clock', 'game', 'assist', 'steal', 'foulon', 'block'].includes(d.actionType)),
        arrange(['gameId', 'actionNumber'])
    );

    let firstFgmTeam = tidy(enhPbpEnh,
        filter(d => ['2pt', '3pt'].includes(d.actionType) && d.success === true),
        groupBy(['possNum'], [
            summarize({ firstFgmTeamId: first('teamId') })
        ]));
    let firstFtmTeam = tidy(enhPbpEnh,
        filter(d => ['freethrow'].includes(d.actionType) && d.success === true),
        groupBy(['possNum'], [
            summarize({ firstFtmTeamId: first('teamId') })
        ]));
    let firstFgaTeam = tidy(enhPbpEnh,
        filter(d => ['2pt', '3pt'].includes(d.actionType)),
        groupBy(['possNum'], [
            summarize({ firstFgaTeamId: first('teamId') })
        ]));
    let firstFtaTeam = tidy(enhPbpEnh,
        filter(d => ['freethrow'].includes(d.actionType)),
        groupBy(['possNum'], [
            summarize({ firstFtaTeamId: first('teamId') })
        ]));

    // Enh Enh PBP with lags and with fields needed to derive the possession's teamId
    enhPbpEnh = tidy(enhPbpEnh,
        arrange(['actionNumber']), // would need to groupby gameId to handle 2+ games in enhPbp
        mutateWithSummary({ prevHomeScore: lag('homeScore') }),
        mutateWithSummary({ prevAwayScore: lag('awayScore') }),
        leftJoin(firstFgmTeam, { by: 'possNum' }), // joining firstFgmTeamId
        leftJoin(firstFtmTeam, { by: 'possNum' }), // ...
        leftJoin(firstFgaTeam, { by: 'possNum' }),
        leftJoin(firstFtaTeam, { by: 'possNum' })
    );

    // Group By Possession
    let groupedByPoss = tidy(enhPbpEnh,
        mutate({ ptsScored: d => d.homePts + d.awayPts }),
        mutate({ secsInFga1: d => ['2pt', '3pt'].includes(d.actionType) ? d.secsIntoGame : null }),
        mutate({ secsInFta1: d => ['freethrow'].includes(d.actionType) ? d.secsIntoGame : null }),
        groupBy(['competitionId', 'gameId', 'possNum'], [
            summarize({
                firstFgmTeamId: min('firstFgmTeamId'),
                firstFtmTeamId: min('firstFtmTeamId'),
                firstFgaTeamId: min('firstFgaTeamId'),
                firstFtaTeamId: min('firstFtaTeamId'),
                periodType: min('periodType'),
                periodNumber: min('periodNumber'),
                period: min('period'),
                possTeamId: min('possTeamId'),
                defTeamId: min('defTeamId'),
                homeId: min('homeId'),
                awayId: min('awayId'),
                secsInStart: min('secsIntoGame'),
                secsInEnd: max('secsIntoGame'),
                tmpPrevMinSecs: min('prevSecsIntoGame'),
                secsInFga1: min('secsInFga1'),
                secsInFta1: min('secsInFta1'),
                tmpMinHomeScore: min('homeScore'),
                tmpMaxPrevHomeScore: max('prevHomeScore'),
                homeScoreEnd: max('homeScore'),
                tmpMinAwayScore: min('awayScore'),
                tmpMaxPrevAwayScore: max('prevAwayScore'),
                awayScoreEnd: max('awayScore'),
                ptsScored: sum('ptsScored'),
                startType: min('possStartType'),
                fgm: n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) && d.success === true }),
                fga: n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) }),
                fgm2: n({ predicate: d => d.actionType === '2pt' && d.success === true }),
                fga2: n({ predicate: d => d.actionType === '2pt' }),
                fgm3: n({ predicate: d => d.actionType === '3pt' && d.success === true }),
                fga3: n({ predicate: d => d.actionType === '3pt' }),
                orb: n({ predicate: d => d.actionType === 'rebound' && d.subType === 'offensive' }),
                ftm: n({ predicate: d => d.actionType === 'freethrow' && d.success === true }),
                fta: n({ predicate: d => d.actionType === 'freethrow' })
                // actions: tough to compute, not sure if string_agg() function exists in tidy.js for groupby(). DM'd Beshai.
                // ,string_agg(actionType, ', ') as actions
            })
        ]),
        mutate({ teamId: d => { // our new (and improved!) way of identifying teamId in a possession
            if (d.firstFgmTeamId !== null && typeof d.firstFgmTeamId !== 'undefined') { return d.firstFgmTeamId;
            } else if (d.firstFtmTeamId !== null && typeof d.firstFgmTeamId !== 'undefined') { return d.firstFtmTeamId;
            } else if (d.firstFgaTeamId !== null && typeof d.firstFgmTeamId !== 'undefined') { return d.firstFgaTeamId;
            } else if (d.firstFtaTeamId !== null && typeof d.firstFgmTeamId !== 'undefined') { return d.firstFtaTeamId;
            } else { return d.possTeamId; }
        } }),
        mutate({ secs: d => d.secsInEnd - d.secsInStart }),
        mutate({ homeScoreStart: d => d.tmpMinHomeScore < d.tmpMaxPrevHomeScore ? d.tmpMinHomeScore : d.tmpMaxPrevHomeScore }),
        mutate({ awayScoreStart: d => d.tmpMinAwayScore < d.tmpMaxPrevAwayScore ? d.tmpMinAwayScore : d.tmpMaxPrevAwayScore }),
        select(['-tmpMinHomeScore', '-tmpMinAwayScore', '-tmpMaxPrevHomeScore', '-tmpMaxPrevAwayScore', '-tmpPrevMinSecs'])
    );

    // Grab Arrays with rows for first & last pbp action, last FGM, last SFL in a possession
    let pbpPossLastAction = tidy(enhPbpEnh,
        filter(d => d.rowNumInv === 1),
        select(['gameId', 'possNum', 'teamId', 'actionType', 'subType', 'success']),
        rename({ teamId: 'laTeamId' }),
        rename({ actionType: 'laActionType' }),
        rename({ subType: 'laSubType' }),
        rename({ success: 'laSuccess' })
    );
    let pbpPossFirstAction = tidy(enhPbpEnh,
        filter(d => d.rowNum === 1),
        select(['gameId', 'possNum', 'actionType', 'playerId', 'qualifiers']),
        rename({ actionType: 'faActionType' }),
        rename({ playerId: 'faPlayerId' }),
        rename({ qualifiers: 'faQualifiers' })
    );
    let pbpPossLastSfl = tidy(enhPbpEnh,
        filter(d => d.rowNumSflInv === 1),
        select(['gameId', 'possNum', 'teamId', 'homeId', 'awayId', 'homeLineupId', 'awayLineupId']),
        rename({ teamId: 'lsTeamId' }),
        rename({ homeId: 'lsHomeId' }),
        rename({ awayId: 'lsAwayId' }),
        rename({ homeLineupId: 'lsHomeLineupId' }),
        rename({ awayLineupId: 'lsAwayLineupId' })
    );

    // Join Arrays, add features (not joining various lineupIds just yet)
    let withFeaturesAdded = tidy(groupedByPoss,
        leftJoin(pbpPossLastAction, { by: ['gameId', 'possNum'] }),
        leftJoin(pbpPossFirstAction, { by: ['gameId', 'possNum'] }),
        leftJoin(pbpPossLastSfl, { by: ['gameId', 'possNum'] }),
        mutate({ result: d => {
            if (d.laActionType === 'period' && d.laSubType === 'end') { return 'periodEnd';
            } else if (d.laActionType === '2pt' && d.laSuccess) { return 'fgMade2';
            } else if (d.laActionType === '3pt' && d.laSuccess) { return 'fgMade3';
            } else if (d.laActionType === '2pt' && !d.laSuccess) { return 'fgMiss2';
            } else if (d.laActionType === '3pt' && !d.laSuccess) { return 'fgMiss3';
            } else if (d.laActionType === 'turnover' && ['ballhandling', 'badpass', 'lostball'].includes(d.laSubType)) { return 'tovLive';
            } else if (d.laActionType === 'turnover' && !['ballhandling', 'badpass', 'lostball'].includes(d.laSubType)) { return 'tovDead';
            } else if (d.laActionType === 'freethrow' && d.laSubtype.slice(-1) === '1' && d.laSuccess) { return 'and1Make';
            } else if (d.laActionType === 'freethrow' && d.laSubtype.slice(-1) === '1' && !d.laSuccess) { return 'and1Miss';
            } else if (d.laActionType === 'freethrow' && d.laSubtype.slice(-1) === '2') { return '2paSflFts';
            } else if (d.laActionType === 'freethrow' && d.laSubtype.slice(-1) === '3') { return '3paSflFts';
            } else { return null; }
        } }),
        mutate({ startRebounderId: d => {
            if (d.faActionType === 'rebound' && d.faPlayerId > 0) { return d.faPlayerId;
            } else if (d.faActionType === 'rebound' && d.faQualifiers.includes('team')) { return 0; // is this === dbt "qualifiers like %team%" ??
            } else { return null; }
        } }),
        rename({ laPlayerId: 'resultPlayerId' }),
        mutate({ homePoss: d => d.teamId === d.homeId }),
        mutate({ isFgm: d => (['2pt', '3pt'].includes(d.laActionType) && d.laSuccess) || (d.laActionType === 'freethrow' && d.laSubType.slice(-1) === '1') }),
        mutate({ isFga: d => ['2pt', '3pt'].includes(d.laActionType) || (d.laActionType === 'freethrow' && d.laSubType.slice(-1) === '1') }),
        mutate({ is2pa: d => d.laActionType === '2pt' || (d.laActionType === 'freethrow' && d.laSubType.slice(-1) === '1' && ((d.laSuccess && d.ptsScored === 3) || (!d.laSuccess && d.ptsScored === 2))) }),
        mutate({ is3pa: d => d.laActionType === '3pt' || (d.laActionType === 'freethrow' && d.laSubType.slice(-1) === '1' && ((d.laSuccess && d.ptsScored === 4) || (!d.laSuccess && d.ptsScored === 3))) }),
        mutate({ isTov: d => d.laActionType === 'turnover' }),
        mutate({ isSfl: d => d.lsTeamId === d.lsHomeId ? (d.lsAwayLineupId !== null) : (d.lsTeamId === d.lsAwayId ? (d.lsHomeLineupId !== null) : null) }),
        mutateWithSummary({ nextSecsInStart: lag('secsInStart') }), // is this ordered by possNum as needed?
        mutateWithSummary({ nextStartType: lag('startType') }) // is this ordered by possNum as needed?
    );

    // With secsInEnd fixed for possession ending at drb, not at missed shot
    // After secsInEnd fixed, now set secsInStart to the prior possessions end 'secsInEnd'
    // After both secsInEnd, secsInStart fixed, now correct seconds length 'secs'
    // After secs fixed, add 'secsBucket'
    // Add countPoss, a boolean to indicate if we should 'count the possession' (non-useless possessions check)
    withFeaturesAdded = tidy(withFeaturesAdded,
        mutate({ secsInEnd: d => ['drbFg', 'drbFt'].includes(d.nextStartType) ? d.nextSecsInStart : d.secsInEnd }),
        select(['-nextSecsInStart']),
        mutateWithSummary({ secsInStart: lag('secsInEnd') }), // is this ordered by possNum as needed?
        mutate({ secs: d => d.secsInEnd - d.secsInStart }),
        mutate({ secsBucket: d => d.secs <= 10 ? 'secs0to10' : (d.secs <= 20 ? 'secs10to20' : (d.secs <= 30 ? 'secs20to30' : 'secs30plus')) }),
        mutate({ countPoss: d => (d.result === 'periodEnd' && d.fga === 0) || (d.startType === 'prePeriodTech') ? false : true })
    );

    // Skip adding PTGC stuff

    // And Return!
    // console.log('possessions output: ', { withFeaturesAdded, groupedByPoss, enhPbp, enhPbpEnh });
    return withFeaturesAdded;
}
export function getChances(enhPbp) {
    // console.log('enhPbp: ', enhPbp);
    // enhPbp: Genius Sports raw data transformed into enhPbp via enhRawPbp()

    // Handle missing data + hardcopy
    if (typeof enhPbp === 'undefined' || enhPbp === null) { return []; }
    if (enhPbp.length === 0) { return []; }
    let enhPbpEnh = JSON.parse(JSON.stringify(enhPbp));

    // Filter ActionTypes
    enhPbpEnh = tidy(enhPbpEnh,
        filter(d => !(d.actionType === 'game' && d.subType === 'end')),
        filter(d => !(d.actionType === 'game' && d.subType === 'start')),
        filter(d => !(d.actionType === 'assist')),
        filter(d => !(d.actionType === 'rebound' && d.subType === 'offensivedeadball' && d.prevActionType === 'freethrow')),
        filter(d => !(d.actionType === 'timeout' && d.prevActionType === 'timeout'))
    );

    // Enh Enh PBP with lags
    enhPbpEnh = tidy(enhPbpEnh,
        mutateWithSummary({ rowNum: rowNumber({ startsAt: 1 }) }) // not right, need rowNum w/in chncNum. Not easy...
        // have a lot more to add
    );

    // Group By Chance
    let groupedByChnc = tidy(enhPbpEnh,
        mutate({ ptsScored: d => d.homePts + d.awayPts }),
        groupBy(['competitionId', 'gameId', 'chncNum'], [
            summarize({
                periodType: min('periodType'), // any_value()
                periodNumber: min('periodNumber'), // any_value()
                period: min('period'), // any_value()
                teamId: min('possTeamId'), // any_value()
                teamIdAgst: min('defTeamId'), // any_value()
                startType: min('chncStartType'), // any_value()
                ptsScored: sum('ptsScored'),
                tmpMaxSecsIntoGame: max('secsIntoGame'),
                tmpMinPrevSecsIntoGame: min('prevsecsIntoGame'),
                fgm: n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) && d.success === true }),
                fga: n({ predicate: d => ['2pt', '3pt'].includes(d.actionType) }),
                fgm2: n({ predicate: d => d.actionType === '2pt' && d.success === true }),
                fga2: n({ predicate: d => d.actionType === '2pt' }),
                fgm3: n({ predicate: d => d.actionType === '3pt' && d.success === true }),
                fga3: n({ predicate: d => d.actionType === '3pt' }),
                orb: n({ predicate: d => d.actionType === 'rebound' && d.subType === 'offensive' })
                // actions: tough to compute, not sure if string_agg() function exists in tidy.js for groupby(). DM'd Beshai.
                // ,string_agg(actionType, ', ') as actions
            })
        ]),
        mutateWithSummary({ nextChncNum: lead('chncNum') }), // is this ordered by chncNum as needed?
        mutate({ secs: d => d.tmpMaxSecsIntoGame - d.tmpMinPrevSecsIntoGame }),
        select(['-tmpMaxSecsIntoGame', '-tmpMinPrevSecsIntoGame'])
    );

    // Grab Arrays with rows for first & last pbp action, last FGM, last SFL in a possession
    let pbpChncLastAction = tidy(enhPbpEnh,
        filter(d => d.rowNumInv === 1),
        select(['gameId', 'chncNum', 'actionType', 'subType', 'success', 'playerId']),
        rename({ playerId: 'laPlayerId' }),
        rename({ actionType: 'laActionType' }),
        rename({ subType: 'laSubType' }),
        rename({ success: 'laSuccess' })
    );
    let pbpChnc2ndLastAction = tidy(enhPbpEnh,
        filter(d => d.rowNumInv === 2),
        select(['gameId', 'chncNum', 'actionType', 'subType']),
        rename({ actionType: 'la2ActionType' }),
        rename({ subType: 'la2SubType' })
    );
    let pbpChncFirstAction = tidy(enhPbpEnh,
        filter(d => d.rowNum === 1),
        select(['gameId', 'chncNum', 'actionType', 'playerId', 'qualifiers']),
        rename({ actionType: 'faActionType' }),
        rename({ playerId: 'faPlayerId' }),
        rename({ qualifiers: 'faQualifiers' })
    );
    let pbpChncFirstAction2 = tidy(enhPbpEnh,
        filter(d => d.rowNum === 1),
        select(['gameId', 'chncNum', 'actionType']),
        rename({ actionType: 'nfaActionType' }) // nextFirstActionActionType
    );

    // -- rows for first & last pbp action, last FGM, last SFL in a possession
    // pbp__chnc_first_action as (select * from gs__enh_pbp_enh where rowNum = 1),
    // pbp__chnc_last_action as (select * from gs__enh_pbp_enh where rowNumInv = 1),
    // pbp__chnc_2ndtolast_action as (select * from gs__enh_pbp_enh where rowNumInv = 2),
    // pbp__chnc_3rdtolast_action as (select * from gs__enh_pbp_enh where rowNumInv = 3),

    let withFeaturesAdded = tidy(groupedByChnc,
        leftJoin(pbpChncLastAction, { by: ['gameId', 'chncNum'] }),
        leftJoin(pbpChnc2ndLastAction, { by: ['gameId', 'chncNum'] }),
        leftJoin(pbpChncFirstAction, { by: ['gameId', 'chncNum'] }),
        leftJoin(pbpChncFirstAction2, { by: { gameId: 'gameId', chncNum: 'nextChncNum' } }), // or is it flipped?
        mutate({ secsBucket: d => d.secs <= 10 ? 'secs0to10' : (d.secs <= 20 ? 'secs10to20' : (d.secs <= 30 ? 'secs20to30' : 'secs30plus')) }),
        mutate({ result: d => {
            let liveTovTypes = ['ballhandling', 'badpass', 'lostball'];
            if (d.laActionType === 'period' && d.laSubType === 'end') { return 'periodEnd';
            } else if (d.nfaActionType === 'timeout') { return 'timeout';
            } else if (d.laActionType === '2pt' && d.laSuccess) { return 'fgMade2';
            } else if (d.laActionType === '3pt' && d.laSuccess) { return 'fgMade3';
            } else if (d.laActionType === '2pt' && !d.laSuccess) { return 'fgMiss2';
            } else if (d.laActionType === '3pt' && !d.laSuccess) { return 'fgMiss3';
            } else if (d.laActionType === 'turnover' && liveTovTypes.includes(d.laSubType)) { return 'tovLive';
            } else if (d.laActionType === 'steal' && d.la2ActionType === 'turnover' && liveTovTypes.includes(d.la2SubType)) { return 'tovLive';
            } else if (d.laActionType === 'turnover' && !liveTovTypes.includes(d.laSubType)) { return 'tovDead';
            } else if (d.laActionType === 'steal' && d.la2ActionType === 'turnover' && !liveTovTypes.includes(d.laSubType)) { return 'tovDead';
            } else if (d.laActionType === 'foulon' && d.la2ActionType === 'turnover' && d.la2SubType === 'offensive') { return 'tovDead';
            } else if (d.laActionType === 'block') { return 'fgaBlkd';
            } else if (d.laActionType === 'freethrow' && d.laSubType.slice(-1) === '1' && d.laSuccess) { return 'and1Make';
            } else if (d.laActionType === 'freethrow' && d.laSubType.slice(-1) === '1' && !d.laSuccess) { return 'and1Miss';
            } else if (d.laActionType === 'freethrow' && d.laSubType.slice(-1) === '2') { return '2paSflFts';
            } else if (d.laActionType === 'freethrow' && d.laSubType.slice(-1) === '3') { return '3paSflFts';
            } else if (d.laActionType === 'jumpball' && ['heldball', 'lodgedball'].includes(d.laSubtype)) { return 'jumpBall';
            } else { return null; }
        } }),
        mutate({ startRebounderId: d => {
            if (d.faActionType === 'rebound' && d.faPlayerId > 0) { return d.faPlayerId;
            } else if (d.faActionType === 'rebound' && d.faQualifiers.includes('team')) { return 0; // is this === dbt "qualifiers like %team%" ??
            } else { return null; }
        } }),
        rename({ laPlayerId: 'resultPlayerId' }),
        mutate({ isFgm: d => (['2pt', '3pt'].includes(d.laActionType) && d.laSuccess) || (d.laActionType === 'freethrow' && d.laSubType.slice(-1) === '1') }),
        mutate({ isFga: d => ['2pt', '3pt'].includes(d.laActionType) || (d.laActionType === 'freethrow' && d.laSubType.slice(-1) === '1') }),
        mutate({ is2pa: d => d.laActionType === '2pt' || (d.laActionType === 'freethrow' && d.laSubType.slice(-1) === '1' && ((d.laSuccess && d.ptsScored === 3) || (!d.laSuccess && d.ptsScored === 2))) }),
        mutate({ is3pa: d => d.laActionType === '3pt' || (d.laActionType === 'freethrow' && d.laSubType.slice(-1) === '1' && ((d.laSuccess && d.ptsScored === 4) || (!d.laSuccess && d.ptsScored === 3))) }),
        mutate({ isTov: d => d.laActionType === 'turnover' })
    );

    // Add countChnc, a boolean to indicate if we should 'count the chance' (non-useless chance check)
    withFeaturesAdded = tidy(withFeaturesAdded,
        mutate({ countChnc: d => (d.result !== null && d.result === 'periodEnd' && d.fga === 0) || (d.startType === 'prePeriodTech') || (d.result === 'timeout') ? false : true })
    );

    // Skip Adding PTGC Stuff

    // And Return!
    return withFeaturesAdded;
}
export function computePppcByQualifier(chances, possessions) {
    // console.log('chances & possessions: ', { chances, possessions });
    // chances & possessions: gs__chances & gs__possessions, created on the fly

    // Handle missing data + hardcopy
    if (typeof chances === 'undefined' || chances === null || chances.length === 0) { return []; }
    if (typeof possessions === 'undefined' || possessions === null || possessions.length === 0) { return []; }

    // Stack Possessions & Chances 2x for startType vs time
    let possStacked = [
        ...possessions.map(row => { return { ...row, qualifierType: 'startType', qualifier: row.startType, metricType: 'poss' }; }),
        ...possessions.map(row => { return { ...row, qualifierType: 'time', qualifier: row.secsBucket, metricType: 'poss' }; })
    ].filter(row => row.countPoss === true);
    let chncStacked = [
        ...chances.map(row => { return { ...row, qualifierType: 'startType', qualifier: row.startType, metricType: 'chnc' }; }),
        ...chances.map(row => { return { ...row, qualifierType: 'time', qualifier: row.secsBucket, metricType: 'chnc' }; })
    ].filter(row => row.countChnc === true);

    // Group by team-game times & start types, for both chances & possessions
    let possGrouped = tidy(possStacked,
        filter(d => d.startType !== null && d.teamId !== null),
        groupBy(['teamId', 'gameId', 'metricType', 'qualifierType', 'qualifier'], [
            summarize({
                teamIdAgst: min('teamIdAgst'), // any_value
                ct: n(),
                ptsScored: sum('ptsScored'),
                fgm: sum('fgm'),
                fga: sum('fga'),
                fgm2: sum('fgm2'),
                fga2: sum('fga2'),
                fgm3: sum('fgm3'),
                fga3: sum('fga3')
            })
        ])
    );
    let chncGrouped = tidy(chncStacked,
        filter(d => d.startType !== null && d.teamId !== null),
        groupBy(['teamId', 'gameId', 'metricType', 'qualifierType', 'qualifier'], [
            summarize({
                teamIdAgst: min('teamIdAgst'), // any_value
                ct: n(),
                ptsScored: sum('ptsScored'),
                fgm: sum('fgm'),
                fga: sum('fga'),
                fgm2: sum('fgm2'),
                fga2: sum('fga2'),
                fgm3: sum('fgm3'),
                fga3: sum('fga3')
            })
        ])
    );

    let groupedStats = [...possGrouped, ...chncGrouped];

    // Stack for isOffense, this code right here is magic
    let stackedForIsOffense = [
        ...tidy(groupedStats, mutate({ isOffense: true })),
        ...tidy(groupedStats, mutate({ isOffense: false }), mutate({ zed: d => d.teamId }), rename({ teamIdAgst: 'teamId' }), rename({ zed: 'teamIdAgst' }), select(['-zed']))
    ];

    // Add totalCt, needed for downstream qualifierFreq
    let totalCt = tidy(stackedForIsOffense,
        groupBy(['teamId', 'gameId', 'isOffense', 'metricType', 'qualifierType'], [
            summarize({
                totalCt: sum('ct')
            })
        ])
    );
    let withTotalCtAdded = tidy(stackedForIsOffense,
        leftJoin(totalCt, { by: ['teamId', 'gameId', 'isOffense', 'metricType', 'qualifierType'] })
    );

    // Add ratios
    let withRatiosAdded = tidy(withTotalCtAdded,
        mutate({ pppc: d => d.ptsScored / d.ct }),
        mutate({ qualifierFreq: d => d.ct / d.totalCt }),
        mutate({ fg2Pct: d => d.fgm2 / d.fga2 }),
        mutate({ fg3Pct: d => d.fgm3 / d.fga3 }),
        mutate({ fgPct: d => d.fgm / d.fga }),
        mutate({ efgPct: d => (d.fgm2 + (1.5 * d.fgm3)) / d.fga }),
        mutate({ fga3Rate: d => d.fga3 / d.fga })
    );

    // Skip Adding PTGC Info
    // console.log('tables: ', { withRatiosAdded, withTotalCtAdded, groupedStats, possGrouped, chncGrouped });
    // And Return
    return withRatiosAdded;
}
export function computePlayerAggStats({ enhPbp, playersArray, teamId, playerId = null }) {
    // console.log('computePlayerAggStats params: ', { enhPbp, playersArray, teamId, playerId });

    // Overview: mainly for teammate stats with selected players on vs off the court
    // Parameters: enhPbp:              from "gs__enh_pbp" collection
    // Parameters: playersArray:        players to compute stats for

    // Returns: outputArray: player aggregated stats derived from the enhPbp, for players on teamId

    // Notes: metrics that were previously box-score approximations (ORB%, DRB%, USAGE%, AST%) are now more exact, given that
    // we can compute exact number of team ORBs, team DRBs, oppo ORBs, etc with each player on vs. off the court.

    // grab relevant playerIds for this team
    let playerIds = playersArray.map(row => row.playerId);

    // approach 1
    // Stack 10x: Add a new entry for each player on the court, both home and away
    // let enhPbpStacked = enhPbp.reduce((acc, row) => {
    //     return acc.concat(
    //         ['hId1', 'hId2', 'hId3', 'hId4', 'hId5', 'aId1', 'aId2', 'aId3', 'aId4', 'aId5'].map(id => {
    //             return { playerOnCourt: row[id], ...row };
    //         })
    //     );
    // }, []);

    // approach 2
    // // compute seconds on court for each player (sum of actionDurations, see similar calc in gs__enh_lineup_game_summaries)
    // let enhPbpStacked = [
    //     ...enhPbp.map(row => { return { playerOnCourt: row.hId1, ...row }; }),
    //     ...enhPbp.map(row => { return { playerOnCourt: row.hId2, ...row }; }),
    //     ...enhPbp.map(row => { return { playerOnCourt: row.hId3, ...row }; }),
    //     ...enhPbp.map(row => { return { playerOnCourt: row.hId4, ...row }; }),
    //     ...enhPbp.map(row => { return { playerOnCourt: row.hId5, ...row }; }),
    //     ...enhPbp.map(row => { return { playerOnCourt: row.aId1, ...row }; }),
    //     ...enhPbp.map(row => { return { playerOnCourt: row.aId2, ...row }; }),
    //     ...enhPbp.map(row => { return { playerOnCourt: row.aId3, ...row }; }),
    //     ...enhPbp.map(row => { return { playerOnCourt: row.aId4, ...row }; }),
    //     ...enhPbp.map(row => { return { playerOnCourt: row.aId5, ...row }; })
    // ];

    // approach 3 (if not the  fastest, this is sufficiently fast)
    let enhPbpStacked = enhPbp.flatMap(row => [
        { playerOnCourt: row.hId1, ...row },
        { playerOnCourt: row.hId2, ...row },
        { playerOnCourt: row.hId3, ...row },
        { playerOnCourt: row.hId4, ...row },
        { playerOnCourt: row.hId5, ...row },
        { playerOnCourt: row.aId1, ...row },
        { playerOnCourt: row.aId2, ...row },
        { playerOnCourt: row.aId3, ...row },
        { playerOnCourt: row.aId4, ...row },
        { playerOnCourt: row.aId5, ...row }
    ]);

    // compute Team stats, Agst stats, and key player fields (mins, dPoss, team mins)
    let onCourtSecs = tidy(enhPbpStacked,
        groupBy(['playerOnCourt'], [
            summarize({
                secsPbp: sum('aDur'),
                fgmTm: n({ predicate: d => ['2pt', '3pt'].includes(d.aType) && d.success === true && d.teamId === teamId }),
                fgaTm: n({ predicate: d => ['2pt', '3pt'].includes(d.aType) && d.teamId === teamId }),
                fgm2Tm: n({ predicate: d => d.aType === '2pt' && d.success === true && d.teamId === teamId }),
                fgm3Tm: n({ predicate: d => d.aType === '3pt' && d.success === true && d.teamId === teamId }),
                ftmTm: n({ predicate: d => d.aType === 'freethrow' && d.success === true && d.teamId === teamId }),
                ftaTm: n({ predicate: d => d.aType === 'freethrow' && d.teamId === teamId }),
                orbTm: n({ predicate: d => d.aType === 'rebound' && d.subType === 'offensive' && d.teamId === teamId }),
                drbTm: n({ predicate: d => d.aType === 'rebound' && d.subType === 'defensive' && d.teamId === teamId }),
                tovTm: n({ predicate: d => d.aType === 'turnover' && d.teamId === teamId }),
                ftmAgst: n({ predicate: d => d.aType === 'freethrow' && d.success === true && d.teamId !== teamId }),
                ftaAgst: n({ predicate: d => d.aType === 'freethrow' && d.teamId !== teamId }),
                fgmAgst: n({ predicate: d => ['2pt', '3pt'].includes(d.aType) && d.success === true && d.teamId !== teamId }),
                fgaAgst: n({ predicate: d => ['2pt', '3pt'].includes(d.aType) && d.teamId !== teamId }),
                fgm2Agst: n({ predicate: d => d.aType === '2pt' && d.success === true && d.teamId !== teamId }),
                fga3Agst: n({ predicate: d => d.aType === '3pt' && d.teamId !== teamId }),
                fgm3Agst: n({ predicate: d => d.aType === '3pt' && d.success === true && d.teamId !== teamId }),
                orbAgst: n({ predicate: d => d.aType === 'rebound' && d.subType === 'offensive' && d.teamId !== teamId }),
                drbAgst: n({ predicate: d => d.aType === 'rebound' && d.subType === 'defensive' && d.teamId !== teamId }),
                tovAgst: n({ predicate: d => d.aType === 'turnover' && d.teamId !== teamId })
            })
        ]),
        // ,round(safe_divide(orb * minsTmBy5, mins * (orbTm + drbAgst)), 5) as orbPct
        // ,round(safe_divide(drb * minsTmBy5, mins * (drbTm + orbAgst)), 5) as drbPct
        mutate({
            minsPbp: d => d.secsPbp / 60,
            minsPbpTm: d => 5 * (d.secsPbp / 60), // sum of all teammate minutes with the player on-court is simply 5x the players minutes
            minsPbpTmBy5: d => d.secsPbp / 60, // same as minsPbp given player on-court
            dPossTm: d => d.fgaAgst + (0.4 * d.ftaAgst) - (1.07 * (d.orbAgst / (d.orbAgst + d.drbTm)) * (d.fgaAgst - d.fgmAgst)) + d.tovAgst
            // ,round((fgaAgst + (0.4*ftaAgst) - 1.07*ifnull(safe_divide(orbAgst, orbAgst + drb), 0) * (fgaAgst-fgmAgst) + tovAgst), 5) as dPoss
        }),
        filter(d => playerIds.includes(d.playerOnCourt)));


    // sum counting stats (preparation)
    let summarizeCounts = {
        // gpPbp: n(),
        gpPbp: nDistinct('gameId'),

        // traditional boxscore
        ast: n({ predicate: d => d.aType === 'assist' }),
        reb: n({ predicate: d => d.aType === 'rebound' }),
        orb: n({ predicate: d => d.aType === 'rebound' && d.subType === 'offensive' }),
        drb: n({ predicate: d => d.aType === 'rebound' && d.subType === 'defensive' }),
        stl: n({ predicate: d => d.aType === 'steal' }),
        blk: n({ predicate: d => d.aType === 'block' }),
        tov: n({ predicate: d => d.aType === 'turnover' }),
        pf: n({ predicate: d => d.aType === 'foul' }),
        pfd: n({ predicate: d => d.aType === 'foulon' }),
        opf: n({ predicate: d => d.aType === 'foul' && d.subType === 'offensive' }),
        dpf: n({ predicate: d => d.aType === 'foul' && d.subType !== 'offensive' }),

        // traditional shooting
        fgm: n({ predicate: d => ['2pt', '3pt'].includes(d.aType) && d.success === true }),
        fga: n({ predicate: d => ['2pt', '3pt'].includes(d.aType) }),
        fgm2: n({ predicate: d => ['2pt'].includes(d.aType) && d.success === true }),
        fga2: n({ predicate: d => ['2pt'].includes(d.aType) }),
        fgm3: n({ predicate: d => ['3pt'].includes(d.aType) && d.success === true }),
        fga3: n({ predicate: d => ['3pt'].includes(d.aType) }),
        ftm: n({ predicate: d => d.aType === 'freethrow' && d.success === true }),
        fta: n({ predicate: d => d.aType === 'freethrow' }),

        // assisted & unassisted shooting (Jake Fox)
        fgmA: n({ predicate: d => ['2pt', '3pt'].includes(d.aType) && d.success === true && d.astId !== null }),
        fgmU: n({ predicate: d => ['2pt', '3pt'].includes(d.aType) && d.success === true && d.astId === null }),
        fgm2A: n({ predicate: d => ['2pt'].includes(d.aType) && d.success === true && d.astId !== null }),
        fgm2U: n({ predicate: d => ['2pt'].includes(d.aType) && d.success === true && d.astId === null }),
        fgm3A: n({ predicate: d => ['3pt'].includes(d.aType) && d.success === true && d.astId !== null }),
        fgm3U: n({ predicate: d => ['3pt'].includes(d.aType) && d.success === true && d.astId === null }),
        dunkFgmA: n({ predicate: d => d.subType === 'dunk' && d.success === true && d.astId !== null }),
        dunkFgmU: n({ predicate: d => d.subType === 'dunk' && d.success === true && d.astId === null }),
        dunkFgm: n({ predicate: d => d.subType === 'dunk' && d.success === true }),
        atr2FgmA: n({ predicate: d => ['2pt', '3pt'].includes(d.aType) && d.zones6 === 'atr2' && d.success === true && d.astId !== null }),
        atr2FgmU: n({ predicate: d => ['2pt', '3pt'].includes(d.aType) && d.zones6 === 'atr2' && d.success === true && d.astId === null }),
        atr2Fgm: n({ predicate: d => ['2pt', '3pt'].includes(d.aType) && d.zones6 === 'atr2' && d.success === true }),

        // scoring context
        pitp2: n({ predicate: d => d.quals.toLowerCase().includes('pointsinthepaint') && d.aType === '2pt' && d.success === true }),
        scp1: n({ predicate: d => d.quals.toLowerCase().includes('2ndchance') && d.aType === 'freethrow' && d.success === true }),
        scp2: n({ predicate: d => d.quals.toLowerCase().includes('2ndchance') && d.aType === '2pt' && d.success === true }),
        scp3: n({ predicate: d => d.quals.toLowerCase().includes('2ndchance') && d.aType === '3pt' && d.success === true }),
        fbpts1: n({ predicate: d => d.quals.toLowerCase().includes('fastbreak') && d.aType === 'freethrow' && d.success === true }),
        fbpts2: n({ predicate: d => d.quals.toLowerCase().includes('fastbreak') && d.aType === '2pt' && d.success === true }),
        fbpts3: n({ predicate: d => d.quals.toLowerCase().includes('fastbreak') && d.aType === '3pt' && d.success === true })
    };

    // compute ratio stats (preparation)
    let mutateRatioStats = {
        // points scored
        ptsScored: d => d.ftm + (2 * d.fgm2) + (3 * d.fgm3),
        pitp: d => (2 * d.pitp2),
        scp: d => (1 * d.scp1) + (2 * d.scp2) + (3 * d.scp3),
        fbpts: d => (1 * d.fbpts1) + (2 * d.fbpts2) + (3 * d.fbpts3),

        // basic shooting stats
        fgPct: d => d.fga === 0 ? null : d.fgm / d.fga,
        fg2Pct: d => d.fga2 === 0 ? null : d.fgm2 / d.fga2,
        fg3Pct: d => d.fga3 === 0 ? null : d.fgm3 / d.fga3,
        ftPct: d => d.fta === 0 ? null : d.ftm / d.fta,
        efgPct: d => d.fga === 0 ? null : (d.fgm2 + 1.5 * d.fgm3) / d.fga,
        fga3Rate: d => d.fga === 0 ? null : d.fga3 / d.fga,
        ftaRate: d => d.fga === 0 ? null : d.fta / d.fga,
        ftmRate: d => d.fga === 0 ? null : d.ftm / d.fga,
        tsa: d => d.fga + (0.44 * d.fta),
        tsPct: d => d.tsa === 0 ? null : d.ptsScored / (2 * d.tsa),
        // shooting per 40
        fgaP40: d => d.minsPbp === 0 ? null : 40 * (d.fga / d.minsPbp),
        fga2P40: d => d.minsPbp === 0 ? null : 40 * (d.fga2 / d.minsPbp),
        fga3P40: d => d.minsPbp === 0 ? null : 40 * (d.fga3 / d.minsPbp),
        ftaP40: d => d.minsPbp === 0 ? null : 40 * (d.fta / d.minsPbp),
        // basic box per 40
        ptsScoredP40: d => d.minsPbp === 0 ? null : 40 * (d.ptsScored / d.minsPbp),
        astP40: d => d.minsPbp === 0 ? null : 40 * (d.ast / d.minsPbp),
        orbP40: d => d.minsPbp === 0 ? null : 40 * (d.orb / d.minsPbp),
        drbP40: d => d.minsPbp === 0 ? null : 40 * (d.drb / d.minsPbp),
        stlP40: d => d.minsPbp === 0 ? null : 40 * (d.stl / d.minsPbp),
        blkP40: d => d.minsPbp === 0 ? null : 40 * (d.blk / d.minsPbp),
        tovP40: d => d.minsPbp === 0 ? null : 40 * (d.tov / d.minsPbp),
        pfP40: d => d.minsPbp === 0 ? null : 40 * (d.pf / d.minsPbp),
        pfdP40: d => d.minsPbp === 0 ? null : 40 * (d.pfd / d.minsPbp),
        opfP40: d => d.minsPbp === 0 ? null : 40 * (d.opf / d.minsPbp),
        dpfP40: d => d.minsPbp === 0 ? null : 40 * (d.dpf / d.minsPbp),
        // advanced offensive, defensive stats
        astPct: d => d.ast / (((d.minsPbp / d.minsPbpTmBy5) * d.fgmTm) - d.fgm),
        astRatio: d => d.ast / (d.fga + (0.44 * d.fta) + d.ast + d.tov),
        tovPct: d => d.tov / (d.fga + (0.44 * d.fta) + d.tov),
        astTov: d => d.tov === 0 ? null : (d.ast / d.tov),
        usagePct: d => ((d.fga + (0.44 * d.fta) + d.tov) * d.minsPbpTmBy5) / (d.minsPbp * (d.fgaTm + (0.44 * d.ftaTm) + d.tovTm)),
        orbPct: d => (d.orb * d.minsPbpTmBy5) / (d.minsPbp * (d.orbTm + d.drbAgst)),
        drbPct: d => (d.drb * d.minsPbpTmBy5) / (d.minsPbp * (d.drbTm + d.orbAgst)),
        blkPct: d => (d.blk * d.minsPbpTmBy5) / (d.minsPbp * (d.fgaAgst - d.fga3Agst)),
        stlPct: d => (d.stl * d.minsPbpTmBy5) / (d.minsPbp * d.dPossTm),
        stlPerPf: d => d.pf === 0 ? null : d.stl / d.pf,
        blkPerPf: d => d.pf === 0 ? null : d.blk / d.pf,
        hkmPct: d => d.stlPct + d.blkPct,
        pfEff: d => d.pf === 0 ? null : (d.stl + d.blk) / d.pf,
        // scoring context stats
        pitpP40: d => d.minsPbp === 0 ? null : 40 * (d.pitp / d.minsPbp),
        scpP40: d => d.minsPbp === 0 ? null : 40 * (d.scp / d.minsPbp),
        fbptsP40: d => d.minsPbp === 0 ? null : 40 * (d.fbpts / d.minsPbp),
        pitpPctPts: d => d.ptsScored === 0 ? null : (d.pitp / d.ptsScored),
        scpPctPts: d => d.ptsScored === 0 ? null : (d.scp / d.ptsScored),
        fbptsPctPts: d => d.ptsScored === 0 ? null : (d.fbpts / d.ptsScored),
        // assisted and unassisted shooting
        fgmAstdPct: d => d.fgm === 0 ? null : d.fgmA / d.fgm,
        fgm2AstdPct: d => d.fgm2 === 0 ? null : d.fgm2A / d.fgm2,
        fgm3AstdPct: d => d.fgm3 === 0 ? null : d.fgm3A / d.fgm3,
        dunkAstdPct: d => d.dunkFgm === 0 ? null : d.dunkFgmA / d.dunkFgm,
        atr2AstdPct: d => d.atr2Fgm === 0 ? null : d.atr2FgmA / d.atr2Fgm,
        fgm2AP40: d => d.minsPbp === 0 ? null : 40 * (d.fgm2A / d.minsPbp),
        fgm2UP40: d => d.minsPbp === 0 ? null : 40 * (d.fgm2U / d.minsPbp),
        fgm3AP40: d => d.minsPbp === 0 ? null : 40 * (d.fgm3A / d.minsPbp),
        fgm3UP40: d => d.minsPbp === 0 ? null : 40 * (d.fgm3U / d.minsPbp),
        atr2FgmAP40: d => d.minsPbp === 0 ? null : 40 * (d.atr2FgmA / d.minsPbp),
        atr2FgmUP40: d => d.minsPbp === 0 ? null : 40 * (d.atr2FgmU / d.minsPbp),
        dunkFgmAP40: d => d.minsPbp === 0 ? null : 40 * (d.dunkFgmA / d.minsPbp),
        dunkFgmUP40: d => d.minsPbp === 0 ? null : 40 * (d.dunkFgmU / d.minsPbp),
        // other stats
        plusMinus: d => (d.ftmTm + 2 * d.fgm2Tm + 3 * d.fgm3Tm) - (d.ftmAgst + 2 * d.fgm2Agst + 3 * d.fgm3Agst)
    };

    // main orchestration, do the summarise, the left join (of team/agst stats), and the mutate
    let outputArray = tidy(enhPbp,
        filter(d => playerId === null ? true : d.playerId === playerId),
        filter(d => d.playerId !== null && d.playerId !== 0),
        filter(d => d.teamId !== null && d.teamId !== 0),
        groupBy(['playerId'], [summarize(summarizeCounts)]), //             counts counting stats
        leftJoin(onCourtSecs, { by: { playerOnCourt: 'playerId' } }), //    join minsPbp, statsTm, statsAgst
        mutate(mutateRatioStats)); //                                       ratio stats

    // keep relevant team players only
    outputArray = tidy(outputArray,
        filter(d => playerIds.includes(d.playerId)));

    // join descriptive fields
    outputArray = tidy(outputArray,
        leftJoin(playersArray, { by: 'playerId' }));

    // add isQualified: true
    outputArray = tidy(outputArray,
        mutate({ isQualified: true }));

    // and return
    // console.log('computed playerAggStats: ', outputArray);
    return outputArray;
};
// 2878 >> 3477 >> 3390 >>
