
import React, { useMemo } from 'react';
import { dDict } from '../../../harddata/DataDictionary';

// (1) Define a default UI for filtering
// ======================================
// Define a default UI for filtering
export function TextSearchFilter({
    column: { filterValue, preFilteredRows, setFilter }
}) {
    // console.log('TextSearchFilter props: ', { filterValue, preFilteredRows, setFilter });
    const count = preFilteredRows.length;
    return (
        <div className='cbb-filter-input-wrapper'>
            <span className={`cbb-table-text-filter`}>
                <input
                    value={filterValue || ''}
                    onChange={e => { setFilter(e.target.value || undefined); }}
                    placeholder={`Search ${count} rows...`}
                    style={{ fontSize: '0.7em', borderRadius: 5 }}
                />
            </span>
        </div>
    );
}

export function NoFilter() {
    return (
        <div />
    );
}
// ======


// (2) Default UI for Global Filtering
// ====================================
export function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter
}) {
    const count = preGlobalFilteredRows.length;

    return (
        <span>
            Search:{' '}
            <input
                value={globalFilter || ''}
                onChange={e => {
                    setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
                placeholder={`${count} records...`}
                style={{
                    fontSize: '1.1rem',
                    border: '0'
                }}
            />
        </span>
    );
}
// ======


// (3) Number Range Filter
// =========================
export function NumberMinimumColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
    const unit = dDict && dDict[id] ? dDict[id].unit : 'num';
    const step = unit === 'int' ? '1' : '0.1';
    const showPctSymbol = unit === 'pct' && filterValue[0];

    // Go Right Into the Return
    return (
        <div className='cbb-filter-input-wrapper'>
            <span className={`cbb-table-filter ${showPctSymbol ? 'show-pct' : ''}`}>
                <input
                    type='number'
                    step={step}
                    value={filterValue[0] || ''}
                    onChange={e => {
                        const val = e.target.value;
                        setFilter((old = []) => [parseFloat(val) ? parseFloat(val) : undefined, old[1]]);
                    }}
                    placeholder={'Min'}
                />
            </span>
        </div>
    );
}

export function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
    // Set Step Type (0.01, 1)
    const unit = dDict && dDict[id] ? dDict[id].unit : 'num';
    const step = ['int'].includes(unit) ? '1' : '0.1';

    // Symbol
    const minSymbolClass = unit === 'pct' && filterValue[0] ? 'show-pct'
        : (unit === 'ratio' && filterValue[0] ? 'show-x' : '');
    const displayMinValue = filterValue[0]
        ? (unit === 'pct' ? Math.round(filterValue[0] * 1000) / 10 : filterValue[0])
        : '';

    const maxSymbolClass = unit === 'pct' && filterValue[1] ? 'show-pct'
        : (unit === 'ratio' && filterValue[1] ? 'show-x' : '');
    const displayMaxValue = filterValue[1]
        ? (unit === 'pct' ? Math.round(filterValue[1] * 1000) / 10 : filterValue[1])
        : '';

    // And Return
    return (
        <div className='cbb-filter-input-wrapper'>
            <span className={`cbb-table-filter ${minSymbolClass ? 'show-pct' : ''}`}>
                <input
                    type='number'
                    step={step}
                    value={displayMinValue}
                    onChange={e => {
                        let val = e.target.value;
                        let cleanVal = parseFloat(val)
                            ? (unit === 'pct' ? parseFloat(val) / 100 : parseFloat(val))
                            : undefined;
                        setFilter((old = []) => [cleanVal, old[1]]);
                    }}
                    placeholder={'Min'}
                />
            </span>
            <span className={`cbb-table-filter ${maxSymbolClass}`}>
                <input
                    type='number'
                    step={step}
                    value={displayMaxValue}
                    onChange={e => {
                        const val = e.target.value;
                        const cleanVal = parseFloat(val)
                            ? (unit === 'pct' ? parseFloat(val) / 100 : parseFloat(val))
                            : undefined;
                        setFilter((old = []) => [old[0], cleanVal]);
                    }}
                    placeholder={`Max`}
                />
            </span>
        </div>
    );
}
// ======


// Select Column Filtering (select option from list)
export function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id }
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach(row => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined);
            }}
        >
            <option value=''>All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
}
// ======


// Slider Column Filtering
// =========================
export function SliderColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id }
}) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <>
            <input
                type='range'
                min={min}
                max={max}
                value={filterValue || min}
                onChange={e => {
                    setFilter(parseInt(e.target.value, 10));
                }}
            />
            <button onClick={() => setFilter(undefined)}>Off</button>
        </>
    );
}
// ======
