
import React, { lazy } from 'react';

const HelmetComponent = lazy(() => import('../../../utils/HelmetUtils'));
const HomePageVideoPlayer = lazy(() => import('./HomePageVideoPlayer'));
const HomePageDataVis = lazy(() => import('./dataVis/HomePageDataVis'));
const HomePageMetricsReport = lazy(() => import('./HomePageMetricsReport'));
const HomePageTestimonials = lazy(() => import('./testimonials/HomePageTestimonials'));
const HomePageFirstImageSection = lazy(() => import('./HomePageFirstImageSection'));
const HomePageLetsGetStarted = lazy(() => import('./letsGetStarted/HomePageLetsGetStarted'));
function HomePage() {
    return (
        <React.Fragment>
            <HelmetComponent page='homePage' />
            <div style={{ fontFamily: 'Roboto Slab' }}>
                <HomePageFirstImageSection />
                <HomePageVideoPlayer />
                <HomePageMetricsReport />
                <HomePageDataVis />
                <HomePageTestimonials />
                <HomePageLetsGetStarted />
            </div>
        </React.Fragment>
    );
}

export default HomePage;
