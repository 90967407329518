
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import GlobalContext from '../../context/GlobalContext';
import Logo from '../../components/uiUxItems/Logo';
import imageUrls from '../../images/gcsImages';

// Create The Component
function LandingPageCard({ src, path, header, subHeader, disabled, config = {} }) {
    const navigate = useNavigate();

    const { teamInfosObj, conferenceInfosObj } = useContext(GlobalContext);
    const teamIds = Object.values(teamInfosObj).flat().filter(row => [89, 74, 53, 60, 73, 29, 17, 4, 87].includes(row.conferenceId) && row.gender === 'MALE').map(row => row.teamId).slice(0, 104);

    // team logos
    const teamLogos = teamIds.map((id, idx) => {
        const extraLeft = (idx % 13 === 0 && idx % 26 !== 0) ? '4%' : '0.2%';
        return (
            <Logo
                key={`${id}-${idx}`}
                ptgc={header === 'Division Leaderboards' ? 'team' : 'conference'}
                teamId={id}
                style={{ width: '6.8%', display: 'inline-flex', margin: '0.25%', marginLeft: extraLeft }}
                imgStyle={{ borderRadius: 0 }}
            />
        );
    });

    // conference logos
    const conferenceIds = Object.values(conferenceInfosObj)
        .flat()
        .filter(row => !['DI Independent', 'DII Independent', 'DIII Independent'].includes(row.conferenceShortName))
        .filter(row => [1, 2].includes(row.divisionId))
        .map(row => row.conferenceId);
    const confLogos = conferenceIds.map((id, idx) => {
        const extraLeft = (idx % 10 === 0 && idx % 20 !== 0) ? '4%' : '0.2%';
        return (
            <Logo
                key={`${id}-${idx}`}
                ptgc='conference'
                conferenceId={id}
                style={{ width: '8.6%', display: 'inline-flex', margin: '0.25%', marginLeft: extraLeft }}
                imgStyle={{ borderRadius: 0 }}
            />
        );
    });

    // tournament logos
    const tourneyLinks = ['statsTourneyLegendsClassic', 'statsTourneyVegasShowdown', 'statsTourneyEmeraldCoast', 'statsTourneyContinentalTire', 'statsTourneyBattle4Atl', 'statsTourneyMauiInv', 'statsTourneyJimmyV', 'statsTourneyArmedForces', 'statsTourneyPuertoRico', 'statsTourneyEspnInvtl', 'statsTourneyBahaMar2'];
    const tourneyLogos = tourneyLinks.map((logoName, idx) => {
        const extraLeft = idx === 4 ? '13.5%' : '3%';
        return (
            <img
                alt=''
                key={`${logoName}-${idx}`}
                src={imageUrls[logoName]}
                style={{ width: '18.5%', display: 'inline-flex', margin: `0.5% 3% 0.5% ${extraLeft}` }}
            />
        );
    });

    // const tourneyLinks = ['statsTourneyBahaMar1', 'statsTourneyBahaMar2', 'statsTourneyJimmyV', 'statsTourneyArmedForces', 'statsTourneyPuertoRico', 'statsTourneyBattle4Atl', 'statsTourneyMauiInv'];
    // const tourneyLogos = tourneyLinks.map((logoName, idx) => {
    //     const extraLeft = idx === 4 ? '11.5%' : '1%';
    //     return (
    //         <img
    //             alt=''
    //             key={`${logoName}-${idx}`}
    //             src={imageUrls[logoName]}
    //             style={{ width: '23%', display: 'inline-flex', margin: '1%', marginLeft: extraLeft }}
    //         />
    //     );
    // });


    // and return
    return (
        <div className={`tools-card ${disabled ? 'disabled' : ''}`} onClick={() => navigate(path)}>
            <div>
                <span className='tools-card-header no-border'>{header}</span>
            </div>

            {header === 'Conference Stats' &&
                <div className='tools-card-image-wrapper' style={{ padding: 8, paddingLeft: 12 }}>
                    {confLogos}
                </div>
            }
            {header === 'Division Leaderboards' &&
                <div className='tools-card-image-wrapper' style={{ padding: 8, paddingLeft: 12 }}>
                    {teamLogos}
                </div>
            }
            {header === 'Tournament Stats' &&
                <div className='tools-card-image-wrapper' style={{ padding: 8, paddingLeft: 12 }}>
                    {tourneyLogos}
                </div>
            }

            {!['Conference Stats', 'Division Leaderboards', 'Tournament Stats'].includes(header) &&
                <div className='tools-card-image-wrapper'>
                    <img alt='' src={src} style={config?.imgStyle || {}} />
                </div>
            }

            <div>
                {disabled && <span className='tools-card-sub-header'>Pro Tier subscriptions are required to access the {header} section on our platform.</span>}
                {!disabled && <span className='tools-card-sub-header'>{subHeader}</span>}
            </div>
        </div>
    );
}

export default LandingPageCard;
